import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { TabContent, TabLink, Tabs } from 'react-tabs-redux';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Link, Redirect } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet';
import styles from './ProfilePage.module.scss';
import * as Button from '../../common/components/Buttons';
import {
  getImageURL,
  IMAGE_FORMAT_TYPE_MOBILE_FULL,
} from '../../utils/urlHelpers';
import { getCSSColor } from '../../utils/converters';
import Container from '../../common/components/Containers/Container';
import { USER_FOLLOWING } from '../../utils/constants/usersConstants';
import * as Badge from '../../common/components/Badges';
import FollowersModal from '../../common/components/Modals/Followers/FollowersModal';
import FollowedModal from '../../common/components/Modals/Followers/FollowedModal';
import StampsModal from '../../common/components/Modals/Stamps';
import MyTrips from './MyTrips';
import FollowingTrips from './FollowingTrips';
import { hideLoadingScreen, showLoadingScreen } from '../../redux/loading';
import * as journiAPI from '../../network/journiAPI';
import UserProfileHeader from '../../common/components/Misc/UserProfileHeader';
import FollowerHelper from '../../helpers/Follower';
import AuthHelper from '../../helpers/Auth';
import StringHelper from '../../helpers/TranslationsQuantity';

/* Display own profile page or another user's profile page */
class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.followingTripsTabName = 'followingTrips';
    this.myTripsTabName = 'myTrips';

    this.state = {
      userProfile: '',
      isFetching: true,
      isFollowersModalVisible: false,
      isFollowedModalVisible: false,
      isStampsModalVisible: false,
      notFound: false,
      selectedTab: props.openTripsIFollowTab
        ? this.followingTripsTabName
        : this.myTripsTabName,
    };

    this.changeStampsModalVisibility = this.changeStampsModalVisibility.bind(
      this
    );
    this.changeFollowersModalVisibility = this.changeFollowersModalVisibility.bind(
      this
    );
    this.changeFollowedModalVisibility = this.changeFollowedModalVisibility.bind(
      this
    );
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
  }

  onSuccess = (response) => {
    this.setState({
      userProfile: response,
      isFetching: false,
      notFound: false,
    });
  };

  onError = (error) => {
    this.setState({
      error,
      isFetching: false,
      notFound: true,
    });
  };

  changeStampsModalVisibility(isVisible) {
    this.setState({
      isStampsModalVisible: isVisible,
    });
  }

  changeFollowersModalVisibility(isVisible) {
    this.setState({
      isFollowersModalVisible: isVisible,
    });
  }

  changeFollowedModalVisibility(isVisible) {
    this.setState({
      isFollowedModalVisible: isVisible,
    });
  }

  componentDidMount() {
    const { selectedTab } = this.state;
    const { match, openTripsIFollowTab } = this.props;
    const { params } = match;
    if (params.userUrl) {
      journiAPI
        .fetchUserProfile(params.userUrl)
        .then(this.onSuccess, this.onError);
    } else {
      journiAPI.fetchUserProfile('').then(this.onSuccess, this.onError);
    }

    // Check if user is coming to a specific fragment of the page
    if (this.props.location.state) {
      const { openTripsIFollow, openMyTrips } = this.props.location.state;
      if (openTripsIFollow || openMyTrips) {
        window.location.href = '#trips';
      }
    }

    // The user loaded this page by using tripsifollow route
    if (
      selectedTab === this.followingTripsTabName ||
      openTripsIFollowTab ||
      this.props.openMyTripsTab
    ) {
      window.location.href = '#trips';
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { match, setLoading } = this.props;
    const { params } = match;

    // If a new profile page is loaded, then new profile data is fetched
    if (params.userUrl !== prevProps.match.params.userUrl) {
      setLoading(true);
      this.setState({
        isFetching: true,
        isFollowersModalVisible: false,
        isFollowedModalVisible: false,
        isStampsModalVisible: false,
      });
      let isOwnProfile = false;
      // Checks if there is any user url
      if (params.userUrl) {
        // Checks if not requesting user's user url
        if (params.userUrl === this.state.userProfile.url) {
          isOwnProfile = true;
        }
      } else {
        isOwnProfile = true;
      }

      // Check if the requested profile is not the user's profile.
      if (isOwnProfile) {
        journiAPI.fetchUserProfile('').then((response) => {
          this.onSuccess(response);
          setLoading(false);
        }, this.onError);
      } else {
        journiAPI.fetchUserProfile(params.userUrl).then((response) => {
          this.onSuccess(response);
          setLoading(false);
        }, this.onError);
      }
    }

    // Check if user is coming to a specific fragment of the page
    if (this.props.location.state !== prevProps.location.state) {
      if (this.props.location.state) {
        const { openTripsIFollow, openMyTrips } = this.props.location.state;
        if (openTripsIFollow) {
          this.setState({ selectedTab: this.followingTripsTabName });
          window.location.href = '#trips';
        } else if (openMyTrips) {
          this.setState({ selectedTab: this.myTripsTabName });
          window.location.href = '#trips';
        } else {
          window.scrollTo(0, 0);
        }
        this.props.location.state.openTripsIFollow = false;
        this.props.location.state.openMyTrips = false;
      }
    }

    if (prevProps.openTripsIFollowTab !== this.props.openTripsIFollowTab) {
      if (this.props.openTripsIFollowTab) {
        this.setState({ selectedTab: this.followingTripsTabName });
        window.location.href = '#trips';
      }
    }
    if (prevProps.openMyTripsTab !== this.props.openMyTripsTab) {
      if (this.props.openMyTripsTab) {
        this.setState({ selectedTab: this.myTripsTabName });
        window.location.href = '#trips';
      }
    }
  }

  render() {
    const {
      isFetching,
      isFollowersModalVisible,
      isFollowedModalVisible,
      isStampsModalVisible,
      notFound,
      selectedTab,
    } = this.state;
    if (isFetching) {
      return '';
    }
    if (notFound) {
      return <Redirect to={{ pathname: '/notFound' }} />;
    }
    const { match, paths, translatedMessages } = this.props;
    const { params } = match;
    let userProfile = {};

    // Check if the route contains an user's url, if not, requesting user profile is loaded from the global app state (avoiding useless requests).
    const isOwnProfile =
      !params.userUrl || this.state.userProfile.url === params.userUrl;
    if (isOwnProfile) {
      userProfile = this.props.userProfile;
    } else {
      userProfile = this.state.userProfile;
    }

    // Setting profile variables
    const {
      id,
      coverColor,
      pictureGuid,
      firstName,
      lastName,
      followers,
      followed,
      location,
      stamps,
      status,
      tagline,
      coverGuid,
      isPremium,
      trips,
    } = userProfile;

    const coverPictureUrl = getImageURL(
      paths.picture,
      coverGuid,
      IMAGE_FORMAT_TYPE_MOBILE_FULL
    );
    const userProfilePictureUrl = getImageURL(
      paths.picture,
      pictureGuid,
      IMAGE_FORMAT_TYPE_MOBILE_FULL
    );
    const coverCSSColor = getCSSColor(coverColor);
    const fullName = `${firstName} ${lastName}`;
    const isTaglineEmpty = tagline === '';
    let shouldDisplayFollowUser = false;

    // Translation messages
    const followersString = StringHelper.getQuantityPhrase(
      followers,
      translatedMessages('main.follower'),
      translatedMessages('main.followers'),
      ''
    );
    const followingString = `${followed} ${translatedMessages(
      'main.following'
    )}`;
    const stampsString = `${stamps.length} ${translatedMessages(
      'main.countries'
    )}`;
    let journisFollowingTabTitle = translatedMessages('main.following');
    let myJournisTabTitle = translatedMessages('main.trips');

    // Dropdown options
    const dropdownOptions = [];
    const unfollowOption = {
      text: translatedMessages('main.unfollow'),
      onClick: () =>
        FollowerHelper.unfollowUser(id).then(() => {
          journiAPI
            .fetchUserProfile(params.userUrl)
            .then(this.onSuccess, this.onError);
        }),
    };
    const settingsOption = {
      text: translatedMessages('main.settings'),
      href: '/settings',
    };

    // Fetching profile function used when user close one of the modals.
    let fetchProfile = () =>
      journiAPI
        .fetchUserProfile(params.userUrl)
        .then(this.onSuccess, this.onError);
    const followUserWithCallback = () => {
      FollowerHelper.followUser(id).then(() => {
        journiAPI
          .fetchUserProfile(params.userUrl)
          .then(this.onSuccess, this.onError);
      });
    };

    if (isOwnProfile) {
      journisFollowingTabTitle = translatedMessages('main.following');
      myJournisTabTitle = translatedMessages('main.my_trips');
      dropdownOptions.push(settingsOption);
      // If profile is own user's profile, then provide empty userUrl parameter
      fetchProfile = () => journiAPI.fetchUserProfile();
    } else if (status === USER_FOLLOWING) {
      dropdownOptions.push(unfollowOption);
    } else {
      shouldDisplayFollowUser = true;
    }

    const shouldDisplayMoreButton = dropdownOptions.length > 0;
    const MoreButton = Button.moreDropdownButton(dropdownOptions);

    return (
      <React.Fragment>
        <Helmet>
          <title>Profile - Journi</title>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        {isFollowersModalVisible && (
          <FollowersModal
            userId={id}
            onClose={() => {
              this.changeFollowersModalVisibility(false);
              fetchProfile();
            }}
          />
        )}
        {isFollowedModalVisible && (
          <FollowedModal
            userId={id}
            onClose={() => {
              this.changeFollowedModalVisibility(false);
              fetchProfile();
            }}
          />
        )}
        {isStampsModalVisible && (
          <StampsModal
            stamps={stamps}
            onClose={() => this.changeStampsModalVisibility(false)}
          />
        )}

        <UserProfileHeader
          coverColor={coverCSSColor}
          coverPictureUrl={coverPictureUrl}
          userPictureColor={coverCSSColor}
          userPictureUrl={userProfilePictureUrl}
          isOwnProfile={isOwnProfile}
        />

        <Container className="p2">
          <div className={[styles.User, 'mt2'].join(' ')}>
            <Row>
              <Col xs={3}>
                {isPremium ? (
                  <div className={styles.PremiumBadge}>
                    <Badge.PremiumBadge
                      mainColor={coverCSSColor}
                      title={translatedMessages('main.premium')}
                    />
                  </div>
                ) : (
                  <div className={styles.PremiumBadge}>
                    <Badge.BasicBadge
                      mainColor={coverCSSColor}
                      title={translatedMessages('main.basic')}
                    />
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <div className={styles.UserName}>
                  <h1>{fullName}</h1>
                </div>
              </Col>
              <Col xs={3}>
                {shouldDisplayMoreButton ? (
                  <div className={styles.ProfileDropdownButton}>
                    <MoreButton buttonSize={Button.BUTTON_SIZES.FIT} />
                  </div>
                ) : !isOwnProfile ? (
                  <div className={styles.FollowButton}>
                    {shouldDisplayFollowUser ? (
                      <Button.Follow
                        buttonSize={Button.BUTTON_SIZES.MEDIUM}
                        message={translatedMessages('main.follow')}
                        mainColor={coverCSSColor}
                        onClick={() =>
                          AuthHelper.authenticateAndRun(followUserWithCallback)
                        }
                      />
                    ) : null}
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>

          <div className={styles.Description}>
            {location && <div className="tPlaceholder mt1">{location}</div>}
            {!isTaglineEmpty ? (
              <div className="tLabel mt2 mx3 mb2">{tagline}</div>
            ) : (
              isOwnProfile && (
                <div className="tLabel mt2 mx3 mb2">
                  {tagline}
                  <div className="tLabel mt1">
                    <Link to="/settings">
                      {translatedMessages('settings.aboutme_placeholder')}
                    </Link>
                  </div>
                </div>
              )
            )}

            <div className={[styles.Badges, 'mt2'].join(' ')}>
              <Button.Secondary
                className={styles.BadgesButton}
                onClick={() =>
                  AuthHelper.authenticateAndRun(() =>
                    this.changeFollowersModalVisibility(true)
                  )
                }
              >
                {followersString}
              </Button.Secondary>
              <Button.Secondary
                className={styles.BadgesButton}
                onClick={() =>
                  AuthHelper.authenticateAndRun(() =>
                    this.changeFollowedModalVisibility(true)
                  )
                }
              >
                {followingString}
              </Button.Secondary>
              <Button.Secondary
                className={styles.BadgesButton}
                onClick={() =>
                  AuthHelper.authenticateAndRun(() =>
                    this.changeStampsModalVisibility(true)
                  )
                }
              >
                {stampsString}
              </Button.Secondary>
            </div>

            <ScrollableAnchor id="trips">
              <div className={[styles.Journis, 'mt5 mb12'].join(' ')}>
                <div className={styles.JournisTitle}>
                  <a href="#trips">
                    <h1>{translatedMessages('main.trips')}</h1>
                  </a>
                </div>
                <Tabs
                  style={{ width: '100%' }}
                  selectedTab={selectedTab}
                  renderActiveTabContentOnly
                >
                  <TabLink
                    className={[styles.JournisTabItem, 'tLabel'].join(' ')}
                    component="a"
                    activeClassName={styles.JournisActiveTabItem}
                    to={this.myTripsTabName}
                  >
                    {myJournisTabTitle}
                  </TabLink>

                  <TabLink
                    className={[styles.JournisTabItem, 'tLabel'].join(' ')}
                    component="a"
                    activeClassName={styles.JournisActiveTabItem}
                    to={this.followingTripsTabName}
                  >
                    {journisFollowingTabTitle}
                  </TabLink>

                  <TabContent for={this.myTripsTabName}>
                    <MyTrips
                      userId={id}
                      trips={trips}
                      fullUserName={fullName}
                      isOwnProfile={isOwnProfile}
                    />
                  </TabContent>
                  <TabContent for={this.followingTripsTabName}>
                    <FollowingTrips
                      userId={id}
                      fullUserName={fullName}
                      isOwnProfile={isOwnProfile}
                    />
                  </TabContent>
                </Tabs>
              </div>
            </ScrollableAnchor>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfile: state.user.profile,
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => {
    if (isLoading) {
      dispatch(showLoadingScreen());
    } else {
      dispatch(hideLoadingScreen());
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
