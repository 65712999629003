/*
    Middleware for reducing boilerplate code on async requests. It dispatches provided actions before starting the
    request, on request success, and on request failure (following this order), and also checks (if shouldCallApi
    function is provided) if the request will be executed or not (note that the redux state will be available for this).
    @param {Array}    types             Array containing 3 action types for dispatching in request, success and failure (in order).
    @param {function} callAPI           function which will make the HTTP request.
    @param {function} shouldCallAPI     function that will determine if it is necessary to make the request (by checking, e.g. cached values in the store).
    @param {Object}   payload           Object containing the parameters that will be used to make the request.
    @param {function} onSuccessCallback function called on success (response from request is passed by default as parameter).
    @param {function} onFailureCallback function called on failure (error from request is passed by default as parameter).
    @param {function} defaultCallback   function called by default.
 */
import createAction from '../utils/createAction';

export function callAPIMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    const {
      types,
      callAPI,
      shouldCallAPI = () => true,
      payload = {},
      onSuccessCallback = () => true,
      onFailureCallback = () => true,
      defaultCallback = () => true,
    } = action;
    const payloadKeys = Object.keys(payload);
    const payloadValues = Object.values(payload);
    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every((type) => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      // redux state is provided as argument for the function
      return;
    }

    const [requestType, successType, failureType] = types;
    const requestAction = createAction(requestType, ...payloadKeys);
    const onSuccessAction = createAction(
      successType,
      ...payloadKeys,
      'response'
    );
    const onFailureAction = createAction(failureType, ...payloadKeys, 'error');
    dispatch(requestAction(...payloadValues));
    return callAPI()
      .then((response) => {
        dispatch(onSuccessAction(...payloadValues, response));
        onSuccessCallback(response);
        defaultCallback();
      })
      .catch((error) => {
        dispatch(onFailureAction(...payloadValues, error));
        onFailureCallback(error);
        defaultCallback();
      });
  };
}
