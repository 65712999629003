import update from 'immutability-helper';
import { BOX_TYPES } from '../../common/components/Boxes/ValidationBox';

// Sing up actions
export const REQUEST_USER_SIGNUP = 'REQUEST_USER_SIGNUP';
export const RECEIVE_USER_SIGNUP = 'RECEIVE_USER_SIGNUP';
export const HANDLE_USER_SIGNUP_ERROR = 'HANDLE_USER_SIGNUP_ERROR';

const initialState = {
  callbackMessage: '',
  callbackBoxType: BOX_TYPES.INFORMATION,
  isRequesting: false,
  wasRequestSuccessful: false,
};

export default (state = initialState, action) => {
  const { type, error } = action;
  switch (type) {
    case REQUEST_USER_SIGNUP:
      return update(state, {
        isRequesting: { $set: true },
      });
    case RECEIVE_USER_SIGNUP:
      return update(state, {
        isRequesting: { $set: false },
        wasRequestSuccessful: { $set: true },
      });
    case HANDLE_USER_SIGNUP_ERROR:
      return update(state, {
        callbackMessage: { $set: error },
        callbackBoxType: { $set: BOX_TYPES.FAILURE },
        isRequesting: { $set: false },
      });
    default:
      return state;
  }
};
