import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-flexbox-grid';
import connect from 'react-redux/es/connect/connect';
import classnames from 'classnames';
import {
  CategoryCardFooter,
  FeaturedJourniCardFooter,
  JourniCardFooter,
} from './CardFooter';
import styles from './Card.module.scss';
import StringHelper from '../../../helpers/TranslationsQuantity';
import Anchor from '../Misc/Anchor';

/**
 * Cards component are used for displaying Featured Journis, Collections, and Journis in a Card format.
 */
const Card = ({
  backgroundImageUrl,
  backgroundColor,
  url,
  children,
  cardTypeClass,
  noZooming,
  hasFixedWidth,
}) => {
  const cardStyle = {
    background: `url(${backgroundImageUrl}) center center / cover`,
    backgroundColor,
  };

  const containerStyles = classnames(
    styles.Container,
    cardTypeClass,
    { [styles.NoZooming]: noZooming },
    { [styles.HasFixedWidth]: hasFixedWidth }
  );

  return (
    <div className={styles.Wrapper}>
      <Anchor href={url}>
        <div className={containerStyles}>
          <div className={styles.Overlay} style={cardStyle} />
          {children}
        </div>
      </Anchor>
    </div>
  );
};

Card.propTypes = {
  /**
   * backgroundImageUrl: string. URL of the picture that will be displayed in the background of the card.
   */
  backgroundImageUrl: PropTypes.string,
  /**
   * backgroundColor: string. Defines the background color of the card.
   */
  backgroundColor: PropTypes.string,
  /**
   *  url: string. URL that will be open once the user clicks the card.
   */
  url: PropTypes.string,
  /**
   *  alt: string. Content of the card's anchor. It is not displayed but it is useful for screen readers.
   */
  alt: PropTypes.string,
  /**
   *  cardTypeClass: CSS class. It is used to style the card according the different available types (Featured Journi, Category, Journi).
   */
  cardTypeClass: PropTypes.oneOf([
    styles.FeaturedJourniCard,
    styles.CategoryCard,
    styles.JourniCard,
  ]),
  /**
   * noZooming: boolean. If true, disables zooming effect on cards when hovering on them.
   */
  noZooming: PropTypes.bool,
  /**
   *  hasFixedWidth: boolean. If true, card will have fixed width, otherwise it will fill all available width.
   */
  hasFixedWidth: PropTypes.bool,
};

Card.defaultProps = {
  backgroundImageUrl: '',
  url: '',
  alt: 'card',
  backgroundColor: 'transparent',
  cardTypeClass: styles.JourniCard,
  noZooming: false,
  hasFixedWidth: false,
};

export const FeaturedJourniCard = (props) => {
  const cardComponent = (
    <Card
      cardTypeClass={styles.FeaturedJourniCard}
      backgroundImageUrl={props.backgroundImageUrl}
      backgroundColor={props.backgroundColor}
      url={props.url}
      alt={props.title}
      hasFixedWidth={!props.useGridColumn}
    >
      <FeaturedJourniCardFooter
        title={props.title}
        places={props.places}
        backgroundColor={props.backgroundColor}
        textColor={props.titleColor}
      />
    </Card>
  );

  if (props.useGridColumn) {
    return (
      <Col xs={12} sm={6} xl={6}>
        {cardComponent}
      </Col>
    );
  }
  return cardComponent;
};

export const JourniCardContainer = connect(
  (state) => ({
    paths: state.configuration.paths,
    translatedMessages: state.configuration.translatedMessages,
  }),
  null
)((props) => {
  const momentsPhrase = StringHelper.getQuantityPhrase(
    props.entryCount,
    props.translatedMessages('main.moment'),
    props.translatedMessages('main.moments')
  );
  return <JourniCard momentsPhrase={momentsPhrase} {...props} />;
});

export const CategoryCard = (props) => {
  const cardComponent = (
    <Card
      cardTypeClass={styles.CategoryCard}
      backgroundImageUrl={props.backgroundImageUrl}
      backgroundColor={props.backgroundColor}
      url={props.url}
      alt={props.title}
      textColor={props.titleColor}
      hasFixedWidth={!props.useGridColumn}
      noZooming
    >
      <CategoryCardFooter
        title={props.title}
        backgroundColor={props.backgroundColor}
      />
    </Card>
  );

  if (props.useGridColumn) {
    return (
      <Col xs={6} sm={4} xl={3}>
        {cardComponent}
      </Col>
    );
  }
  return cardComponent;
};

export const JourniCard = (props) => {
  const cardComponent = (
    <Card
      cardTypeClass={styles.JourniCard}
      backgroundImageUrl={props.backgroundImageUrl}
      backgroundColor={props.backgroundColor}
      url={props.url}
      alt={props.title}
      hasFixedWidth={!props.useGridColumn}
    >
      <JourniCardFooter
        title={props.title}
        places={props.places}
        entryCount={props.entryCount}
        backgroundColor={props.backgroundColor}
        textColor={props.titleColor}
        momentsPhrase={props.momentsPhrase}
      />
    </Card>
  );

  if (props.useGridColumn) {
    return (
      <Col xs={6} sm={4} xl={3}>
        {cardComponent}
      </Col>
    );
  }
  return cardComponent;
};
