import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Form from '../../../common/components/Form';
import Input from '../../../common/components/Input/Input';
import * as Button from '../../../common/components/Buttons';
import Translate from '../../../common/components/Misc/Translate';
import SignPage, { PAGE_TYPE_SIGN_IN } from '../SignPage';
import AuthHelper from '../../../helpers/Auth';

/*
    Form for sending the password recovery email.
 */
class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // holds the content of the email input.
      email: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    AuthHelper.forgotPassword(this.state.email);
  }

  render() {
    const {
      onPageChange,
      isRequesting,
      wasRequestSuccessful,
      callbackMessage,
      callbackBoxType,
      userIsAuthenticated,
    } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Forgot Password - Journi</title>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <Translate>
          {(translate) => (
            <Form onSubmit={this.handleSubmit}>
              <h2 className="mb3">{translate('recover_password.title')}</h2>
              <hr className="mb4" />
              <Row>
                <Col xs={12} className="mb4">
                  <Input
                    title={translate('recover_password.enter_email')}
                    type="email"
                    name="email"
                    placeholder="email@example.com"
                    onChange={this.handleInputChange}
                    required
                  />
                </Col>
              </Row>
              {SignPage.renderCallbackMessage(callbackBoxType, callbackMessage)}
              <Row>
                <Col xs={12} className="mb3">
                  <Button.Primary
                    buttonSize="Block"
                    type="submit"
                    display="d-block"
                    required
                    disabled={isRequesting || wasRequestSuccessful}
                  >
                    {translate('login.recover_password')}
                  </Button.Primary>
                </Col>
              </Row>
              {userIsAuthenticated ? null : (
                <Row>
                  <div className="mx-auto mb2 tPlaceholder">
                    <a
                      href="#/"
                      onClick={onPageChange.bind(this, PAGE_TYPE_SIGN_IN)}
                    >
                      {translate('main.back')}
                    </a>
                  </div>
                </Row>
              )}
            </Form>
          )}
        </Translate>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  callbackMessage: state.forgotPassword.callbackMessage,
  callbackBoxType: state.forgotPassword.callbackBoxType,
  userIsAuthenticated: state.user.isAuthenticated,
  wasRequestSuccessful: state.forgotPassword.wasRequestSuccessful,
  isRequesting: state.forgotPassword.isRequesting,
});

const connectedForgotPasswordForm = connect(
  mapStateToProps,
  null
)(ForgotPasswordForm);

export default withRouter(connectedForgotPasswordForm);
