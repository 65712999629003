import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './CopyBox.module.scss';

/*
    Presentational component. Textfield that can be copied by clicking directly selecting it or by clicking the copy button.
    Please note that it makes use of the Clipboard API. https://caniuse.com/#feat=clipboard.

 */
class CopyBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      status: this.props.copyPhrase,
    };
    this.copyContent = this.copyContent.bind(this);
  }

  copyContent(e) {
    const { copiedPhrase, copyPhrase } = this.props;
    this.input.select();
    document.execCommand('copy');
    // Select text after copying
    e.target.focus();
    // Display message warning the user about the copied contents
    this.setState({
      status: copiedPhrase,
    });
    // Restore original copy message
    setTimeout(
      () =>
        this.setState({
          status: copyPhrase,
        }),
      1000
    );
  }

  render() {
    const { text } = this.props;
    const { status } = this.state;

    return (
      <div className={styles.Container}>
        <input
          ref={(textarea) => (this.input = textarea)}
          className={[styles.Content, 'tLabel'].join(' ')}
          onClick={this.copyContent}
          value={text}
          readOnly
        />
        <div
          className={[styles.CopyButton, 'tLabel'].join(' ')}
          onClick={this.copyContent}
        >
          {status}
        </div>
      </div>
    );
  }
}

CopyBox.propTypes = {
  /*
        Text that will be displayed to be copied
     */
  text: PropTypes.string.isRequired,
  /*
        Phrase that will be displayed in the button to trigger the "Copy" action
     */
  copyPhrase: PropTypes.string,
  /*
        Phrase that will be temporarily displayed in the button after having clicked it to announce that the content was copied
     */
  copiedPhrase: PropTypes.string,
};

CopyBox.defaultProps = {
  copyPhrase: 'Copy',
  copiedPhrase: 'Copied!',
};

export default CopyBox;
