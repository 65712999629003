/*
    Redux Action generator.
    Returns an object with attributes type and ...argnames.
    Useful for avoiding boilerplate code.
 */
export default function createAction(type, ...argNames) {
  return function (...args) {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}
