import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMapboxGl from 'react-mapbox-gl';
import { debounce } from 'throttle-debounce';
import MapMarker from './Markers/MapMarker';
import * as Button from '../Buttons';
import styles from './Map.module.scss';
import { MapEntry } from '../../../network/models';
import { groupBy } from '../../../utils/arrays';
import EntryHelper from '../../../screens/TripTimelinePage/helpers/Entry';
import { getMarkerImgURL } from '../../../utils/urlHelpers';
import IconHelper from '../../../helpers/IconsEntries';
import MapPopup from './Popup/MapPopup';
import screenHelper from '../../../utils/screenHelper';

/**
 * Map presentational component. It uses react-mapbox-gl library for rendering a MapBoxGL instance.
 *
 * Taken from library's documentation (https://alex3165.github.io/react-mapbox-gl/documentation):
 * To use the original Mapbox API use the onStyleLoad property.
 * The callback function will receive the map object as a first argument,
 * then you can add your own logic alongside react-mapbox-gl. mapbox gl API.
 */
class Map extends Component {
  constructor(props) {
    super(props);
    const [swCoordinate, neCoordinate] = this.getOptimalCameraPosition();
    const [
      swCoordinatePadded,
      neCoordinatePadded,
    ] = this.getPaddedCameraPosition(
      swCoordinate,
      neCoordinate,
      props.entries ? props.entries.length : 0
    );

    let entriesWithoutCoordinates = true;

    const richEntries = props.entries
      ? props.entries.map((entry) => {
          entry.onClick = (event) => {
            if (event) {
              if (event.stopPropagation) event.stopPropagation();
              debounce(500, props.onPinClick(null, entry.id, true));
            }
          };
          if (entry.coordinates) {
            entriesWithoutCoordinates = false;
          }
          return entry;
        })
      : null;

    const groupedMarkers = props.entries
      ? groupBy(richEntries, (entry) => entry.categoryId)
      : null;

    this.state = {
      entriesWithoutCoordinates,
      groupedMarkers,
      noPaddedBounds: [swCoordinate, neCoordinate],
      fitBounds: [swCoordinatePadded, neCoordinatePadded],
      originalBounds: [swCoordinatePadded, neCoordinatePadded],
      initialZoom: null,
      initialCenter: null,
      areImagesLoaded: false,
    };

    this.centerMap = this.centerMap.bind(this);
    this.resetMap = this.resetMap.bind(this);
    this.notifyLoadedImages = this.notifyLoadedImages.bind(this);
    this.setOriginalPosition = this.setOriginalPosition.bind(this);
    this.toggleZoomIn = this.toggleZoomIn.bind(this);
    this.toggleZoomOut = this.toggleZoomOut.bind(this);
    this.setFitBounds = this.setFitBounds.bind(this);
    this.setFitBoundsDecounced = debounce(500, this.setFitBounds);
    this.saveInitialCamera = this.saveInitialCamera.bind(this);
    this.mapBoxComponent = ReactMapboxGl({
      accessToken: props.accessToken,
      interactive: props.interactive,
      keyboard: false,
    });
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener('resize', this.setFitBoundsDecounced);
  }

  componentDidUpdate(prevProps) {
    const { entries, activeEntry } = this.props;
    if (this.mapReference && entries && entries.length) {
      if (
        (activeEntry === null || activeEntry === undefined) &&
        prevProps.activeEntry
      ) {
        this.setOriginalPosition();
      } else if (prevProps.activeEntry !== activeEntry) {
        this.centerMap(prevProps.entries[activeEntry].coordinates);
      } else if (
        prevProps.entries[0].id !== entries[0].id ||
        prevProps.entries.length !== entries.length
      ) {
        this.centerMap(entries[0].coordinates);
      }
    } else if (!(!activeEntry && !prevProps.activeEntry)) {
      this.resetMap();
    }
  }

  /**
   * Remove event listener to prevent memory leaks.
   */
  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener('resize', this.setFitBoundsDecounced);
  }

  /**
   * Sets the map's camera to its initial position, zoom and fit coordinates.
   */
  setOriginalPosition() {
    const { initialCenter, initialZoom, originalBounds } = this.state;
    if (this.isComponentMounted) {
      this.setState({
        fitBounds: originalBounds,
      });
    }
    this.centerMap(initialCenter, initialZoom);
  }

  /**
   * Sets the map's bounds coordinates.
   */
  setFitBounds() {
    const { noPaddedBounds } = this.state;
    const { entries } = this.props;
    if (entries && entries.length > 0) {
      const fitBounds = this.getPaddedCameraPosition(
        noPaddedBounds[0],
        noPaddedBounds[1],
        entries.length
      );
      if (this.isComponentMounted) {
        this.setState({
          fitBounds,
        });
      }
    }
  }

  /**
   * Returns the map boundary coordinates with fixed padding. It is used to provide better covered area
   * by using the device's viewport size.
   * @param {array} swCoordinate array containing SW coordinates: [southernmostPoint, westernmostPoint]
   * @param {array} neCoordinate containing NE coordinates: [northernmostPoint, easternmostPoint]
   * @param {number} entriesLength number of entries provided for the map
   * @return {array} two-elements array ([SouthWestern, NorthEast]) otherwise.
   */
  getPaddedCameraPosition(swCoordinate, neCoordinate, entriesLength) {
    const [southernmostPoint, westernmostPoint] = swCoordinate;
    const [northernmostPoint, easternmostPoint] = neCoordinate;

    // Padding corrections
    let factor = 0.02;
    if (entriesLength > 5) {
      factor = 0.01;
    } else if (
      Math.abs(southernmostPoint - northernmostPoint) > 0.2 &&
      Math.abs(westernmostPoint - easternmostPoint) > 0.2
    ) {
      const screenWidth = screenHelper.getScreenWidth();
      factor = 1500 / screenWidth;
    }

    return [
      [southernmostPoint - factor, westernmostPoint - factor],
      [northernmostPoint + factor, easternmostPoint + factor],
    ];
  }

  /**
   * Obtains the best possible coordinates for centering the map. The following cases are considered:
   * If no entries are present, the map will be centered in [0,0].
   * One entry will center the map in the coordinates of the entry.
   * For multiple entries a boundary region is calculated by using a MBR (minimum bounding rectangle)
   * @return {array} null if boundary region is not defined and a two-elements array ([SouthWestern, NorthEast])
   *                 otherwise.
   */
  getOptimalCameraPosition() {
    const { entries } = this.props;

    if (!entries || entries.length < 1) {
      return [
        [0, 0],
        [0, 0],
      ];
    }

    // Set the starting point
    const startIndex = parseInt((entries.length / 3) * 2, 10);
    const startMarker = entries[startIndex];
    const startPosition = startMarker.coordinates;

    let southernmostPoint = startMarker.coordinates[0];
    let northernmostPoint = startMarker.coordinates[0];
    let westernmostPoint = startMarker.coordinates[1];
    let easternmostPoint = startMarker.coordinates[1];

    // Decide which entries will be used to set the map boundaries
    entries.forEach((entry) => {
      const dx = entry.coordinates[0] - startPosition[0]; // Longitudes
      const dy = entry.coordinates[1] - startPosition[1]; // Latitudes
      const distance = dx * dx + dy * dy;

      // Define a maximum distance criteria
      if (distance <= 1000.0) {
        // Check and set southernmost point
        if (entry.coordinates[0] < southernmostPoint) {
          southernmostPoint = entry.coordinates[0]; // eslint-disable-line prefer-destructuring
        }
        // Check and set northernmost point
        if (entry.coordinates[0] >= northernmostPoint) {
          northernmostPoint = entry.coordinates[0]; // eslint-disable-line prefer-destructuring
        }
        // Check and set westernmost point
        if (entry.coordinates[1] < westernmostPoint) {
          westernmostPoint = entry.coordinates[1]; // eslint-disable-line prefer-destructuring
        }
        // Check and set easternmost point
        if (entry.coordinates[1] >= easternmostPoint) {
          easternmostPoint = entry.coordinates[1]; // eslint-disable-line prefer-destructuring
        }
      }
    });

    return [
      [southernmostPoint, westernmostPoint],
      [northernmostPoint, easternmostPoint],
    ];
  }

  notifyLoadedImages() {
    this.setState({
      areImagesLoaded: true,
    });
  }

  saveInitialCamera() {
    const mapboxCenter = this.mapReference.state.map.getCenter();
    const initialCenter = [mapboxCenter.lng, mapboxCenter.lat];
    const initialZoom = this.mapReference.state.map.getZoom();
    this.setState({
      initialZoom,
      initialCenter,
    });
  }

  /**
   * Centers the map to the provided coordinates.
   * @param {array} coordinates - format [lng, lat]
   * @param {number} [zoom] - possible values: [0, 24]
   */
  centerMap(coordinates, zoom = 15) {
    if (this.mapReference && this.mapReference.state.map) {
      this.mapReference.state.map.flyTo({
        center: coordinates,
        zoom,
      });
    }
  }

  /**
   * Moves the map's camera to 0 lat 0 lng coordinates and sets the zoom to 0
   */
  resetMap() {
    // See more about the jumpTo method in https://docs.mapbox.com/mapbox-gl-js/api/#map#jumpTo
    if (this.mapReference && this.mapReference.state.map) {
      this.mapReference.state.map.jumpTo({
        center: [0, 0],
        zoom: 0,
      });
    }
  }

  /**
   * Increases the map's zoom level by 1. Using mapbox-gl-js API.
   * See more in https://docs.mapbox.com/mapbox-gl-js/api/#map#zoomIn
   */
  toggleZoomIn() {
    this.mapReference.state.map.zoomIn();
  }

  /**
   * Decreases the map's zoom level by 1. Using mapbox-gl-js API.
   * See more in https://docs.mapbox.com/mapbox-gl-js/api/#map#zoomOut
   */
  toggleZoomOut() {
    this.mapReference.state.map.zoomOut();
  }

  render() {
    const MapBoxComponent = this.mapBoxComponent;
    const {
      style,
      containerStyle,
      onFullScreenClick,
      interactive,
      smallMarkers,
      activeEntry,
      entries,
      visiblePopup,
    } = this.props;
    const {
      fitBounds,
      groupedMarkers,
      entriesWithoutCoordinates,
      areImagesLoaded,
    } = this.state;
    // Get a record of the entry's available categories.
    const availableCategories = groupedMarkers
      ? Object.keys(EntryHelper.ENTRY_TYPES)
          .map((key) => {
            if (groupedMarkers.get(EntryHelper.ENTRY_TYPES[key].id)) {
              return EntryHelper.ENTRY_TYPES[key].id;
            }
            return null;
          })
          .filter((categoryId) => categoryId !== null)
      : null;

    const onMapStyleLoad = (map) => {
      // Save initial zoom and position for restoring if needed.
      this.saveInitialCamera();

      // If no coordinates are found in entries, then set the camera to default values.
      if (entriesWithoutCoordinates) this.resetMap();

      // Load all category icons
      if (availableCategories) {
        const loadImages = async () => {
          const loadingPromises = [];
          for (let i = 0; i < availableCategories.length; i += 1) {
            const categoryId = availableCategories[i];
            const iconName = IconHelper.getCategoryIcon(categoryId).name;
            const imageURL = getMarkerImgURL(iconName);

            if (!map.hasImage(iconName)) {
              loadingPromises.push(
                new Promise((resolve, reject) => {
                  map.loadImage(imageURL, (error, image) => {
                    if (!map.hasImage(iconName)) {
                      map.addImage(iconName, image);
                    }
                    resolve();
                  });
                })
              );
            }
          }
          Promise.all(loadingPromises).then(this.notifyLoadedImages);
        };
        loadImages();
      }
    };

    return (
      <div
        role="presentation"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <MapBoxComponent
          ref={(event) => {
            this.mapReference = event;
          }}
          style={style}
          containerStyle={containerStyle}
          fitBounds={fitBounds}
          onStyleLoad={onMapStyleLoad}
          // Padding values are based on the markers size. It avoids half displayed markers.
          fitBoundsOptions={{
            padding: {
              top: smallMarkers ? 38 : 76,
              bottom: smallMarkers ? 38 : 76,
              left: smallMarkers ? 15 : 30,
              right: smallMarkers ? 15 : 30,
            },
          }}
        >
          {/* Render all entries as markers in the map */}
          {availableCategories && areImagesLoaded
            ? availableCategories.map((key) => (
                <MapMarker
                  smallSize={smallMarkers}
                  key={key}
                  id={key}
                  entries={groupedMarkers.get(key)}
                  activeEntry={
                    activeEntry && entries[activeEntry]
                      ? entries[activeEntry]
                      : null
                  }
                />
              ))
            : null}
          {/* Render controls */}
          {interactive && (
            <div className={styles.Controls}>
              <div className={styles.FullScreen}>
                <Button.FullScreen
                  onClick={() => {
                    onFullScreenClick(() =>
                      this.mapReference.state.map.resize()
                    );
                  }}
                />
              </div>
              <div className={styles.Zooming}>
                <Button.ZoomIn onClick={this.toggleZoomIn} />
                <Button.ZoomOut onClick={this.toggleZoomOut} />
              </div>
            </div>
          )}
          {activeEntry !== null && activeEntry !== undefined && visiblePopup ? (
            <div className={styles.Popup}>
              <MapPopup
                pictureURL={entries[activeEntry].pictureURL}
                date={entries[activeEntry].date}
                time={entries[activeEntry].time}
                text={entries[activeEntry].comment}
              />
            </div>
          ) : null}
        </MapBoxComponent>
      </div>
    );
  }
}

Map.propTypes = {
  accessToken: PropTypes.string.isRequired, // Public token used to initialize the MapBox library
  style: PropTypes.string, // Style provided for MapBox maps
  containerStyle: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }), // CSS styles provided through object for the map container
  center: PropTypes.arrayOf(PropTypes.number), // Coordinates in which the map will be centered
  entries: PropTypes.arrayOf(MapEntry), // Array of objects containing entry properties
  activeEntry: PropTypes.number,
  onFullScreenClick: PropTypes.func.isRequired,
  onPinClick: PropTypes.func,
  interactive: PropTypes.bool,
  smallMarkers: PropTypes.bool,
  visiblePopup: PropTypes.bool, // If true, shows a popup with data of the current active entry.
};

Map.defaultProps = {
  style: 'mapbox://styles/bbusetti/ciz76qq8t002x2sqzm4qfa2y8', // Journi Map style
  containerStyle: {
    height: '100%',
    width: '100%',
  },
  onPinClick: () => true,
  center: [0, 0],
  activeEntry: null,
  interactive: true,
  smallMarkers: false,
  entries: null,
  visiblePopup: true,
};

export default Map;
