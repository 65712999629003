import update from 'immutability-helper/index';
import { store } from '../index';
import createAction from './utils/createAction';

const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
const ENQUEUE_ACTION = 'ENQUEUE_ACTION';
const CLEAN_REDIRECT_URL = 'CLEAN_REDIRECT_URL';
const CLEAN_EVENTS_QUEUE = 'CLEAN_EVENTS_QUEUE';

export const cleanRedirectUrlAction = createAction(CLEAN_REDIRECT_URL);
export const cleanEventsQueueAction = createAction(CLEAN_EVENTS_QUEUE);

export function setRedirectUrl(redirectUrl) {
  redirectUrl = getRedirect(redirectUrl);
  return {
    type: SET_REDIRECT_URL,
    redirectUrl,
  };
}

export function enqueueAction(event) {
  return {
    type: ENQUEUE_ACTION,
    event,
  };
}

export function getRedirect(url) {
  const isURLAbsoluteExp = new RegExp('^(?:[a-z]+:)?//', 'i');
  const isURLAbsolute = isURLAbsoluteExp.test(url);
  const state = store.getState();
  if (!isURLAbsolute) {
    return url;
  }
  return state.configuration.paths.landingPage + url.charAt(0) === '/'
    ? url.substr(1)
    : url;
}

const initialState = {
  eventsQueue: [],
  redirectUrl: null,
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SET_REDIRECT_URL:
      return update(state, { redirectUrl: { $set: action.redirectUrl } });
    case ENQUEUE_ACTION:
      return update(state, { eventsQueue: { $push: [action.event] } });
    case CLEAN_REDIRECT_URL:
      return update(state, { redirectUrl: { $set: null } });
    case CLEAN_EVENTS_QUEUE:
      return update(state, { eventsQueue: { $set: [] } });
    default:
      return state;
  }
};
