import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import PublicNavigationBar from './PublicNavigationBar';
import journiLogo from '../../../../assets/images/logos/journi-logo@2x.png';
import AuthHelper from '../../../../helpers/Auth';

/*
  Container component. Renders public navigation bar.
 */
class PublicNavigationBarContainer extends Component {
  /*
    Returns an array of objects which contain links' data.
  */
  getNavigationItems() {
    const { translatedMessages } = this.props;
    const currentPath = this.props.match.path;
    return [
      {
        title: translatedMessages('navigation.trips_i_follow'),
        to: {
          pathname: '/tripsifollow',
        },
        icon: 'icon icon-star',
        isActive: currentPath.startsWith('/tripsifollow'),
        onlyTopNavigation: false,
        hideOnTopNavigationMobileView: true,
      },
      {
        title: translatedMessages('main.trips'),
        to: {
          pathname: '/mytrips',
        },
        icon: 'icon icon-journis',
        isActive: currentPath.startsWith('/profile'),
        onlyTopNavigation: false,
        hideOnTopNavigationMobileView: true,
      },
      {
        title: `${translatedMessages('main.sign_in')} / ${translatedMessages(
          'main.sign_up'
        )}`,
        onClick: AuthHelper.authenticateAndRun,
        icon: 'icon icon-icon',
        onlyTopNavigation: true,
        hideOnTopNavigationMobileView: false,
      },
    ];
  }

  render() {
    const { paths } = this.props;

    const navigationItems = this.getNavigationItems();

    return (
      <PublicNavigationBar
        logoImgURL={journiLogo}
        logoHref={paths.landingPage}
        logoAnimation="makeItShake"
        navigationItems={navigationItems}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  /*
    url paths required for links.
  */
  paths: state.configuration.paths,
  translatedMessages: state.configuration.translatedMessages,
});

export default withRouter(
  connect(mapStateToProps, null)(PublicNavigationBarContainer)
);
