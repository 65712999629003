import { toast } from 'react-toastify';
import styles from './styles.module.scss';

/* Toasts should be called within children components of the Toast Container */
export const toastSuccess = (message) =>
  toast.info(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: [styles.Toast, styles.ToastSuccess, 'tButton'].join(' '),
  });

export const toastFail = (message) =>
  toast.info(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: [styles.Toast, styles.ToastFail, 'tButton'].join(' '),
  });
