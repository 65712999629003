import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import connect from 'react-redux/es/connect/connect';
import { Redirect, Route } from 'react-router-dom';
import queryString from 'query-string';
import { hideLoadingScreen, showLoadingScreen } from '../../redux/loading';
import { translate } from '../../common/components/Misc/Translate';
import styles from '../../common/components/Navigation/Blog/NavigationBar.module.scss';
import NavigationBarContainer from '../../common/components/Navigation/Blog/NavigationBarContainer';
import FooterContainer from '../../common/components/Navigation/Blog/FooterContainer';

/*
  PrivateRoute is a wrapper for Routes which renders the component only if the user is Authenticated, otherwise
  it redirects to the Sign In page.
*/
const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isConfigurationReady,
  setLoading,
  paths,
  translatedMessages,
  ...rest
}) => {
  function render(props) {
    if (isConfigurationReady) {
      if (isAuthenticated) {
        return (
          <React.Fragment>
            <div className={styles.NavigationBarLayout}>
              <NavigationBarContainer />
            </div>
            <Component
              setLoading={setLoading}
              paths={paths}
              translate={translate}
              {...props}
              {...rest}
            />
            <div className="mt12">
              <FooterContainer />
            </div>
          </React.Fragment>
        );
      }
      let hasRedirectParameter = false;
      let searchParameters = queryString.parse(props.location.search);

      // If no search parameters are found, then initialize
      if (!searchParameters) {
        searchParameters = {};
      }

      if (
        (searchParameters && searchParameters.redirect) ||
        props.location.pathname !== '/'
      ) {
        hasRedirectParameter = true;
      }

      // Save the route from where the user was coming from.
      if (props.location.pathname !== '/') {
        searchParameters.redirect = props.location.pathname;
        props.location.search = queryString.stringify(searchParameters);
      }

      const redirectOptions = {
        pathname: '/login',
        search: hasRedirectParameter ? props.location.search : undefined,
        state: { from: props.location },
      };

      return <Redirect to={redirectOptions} />;
    }
    return null;
  }

  return <Route {...rest} render={render} />;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  paths: state.configuration.paths,
  translatedMessages: state.configuration.translatedMessages,
  /*
    isConfigurationReady property just depends on the required web app configuration loading state (fetched configuration values from backend and
    user's profile loading states). It is used to ensure the components will be able to use configuration values.
    Without them we can not ensure that components (such as forms) will be functional.
 */
  isConfigurationReady: state.configuration.isConfigurationReady,
});

/*
    We allow components to make the loading screen appear by providing them the turnBusy dispatch method.
 */
const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => {
    if (isLoading) dispatch(showLoadingScreen());
    else dispatch(hideLoadingScreen());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
