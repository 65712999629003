import update from 'immutability-helper';
import createAction from '../utils/createAction';

// Logout actions
const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';
const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
const HANDLE_NOTIFICATIONS_ERROR = 'HANDLE_NOTIFICATIONS_ERROR';
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export const requestNotificationsAction = createAction(REQUEST_NOTIFICATIONS);
export const receiveNotificationsAction = createAction(
  RECEIVE_NOTIFICATIONS,
  'response'
);
export const handleNotificationsErrorAction = createAction(
  HANDLE_NOTIFICATIONS_ERROR,
  'error'
);
export const setNotificationsAction = createAction(
  SET_NOTIFICATIONS,
  'notifications'
);

const initialState = {
  notifications: [],
  isRequesting: false,
  wasRequestSuccessful: false,
};

export default (state = initialState, action) => {
  const { response, type } = action;
  switch (type) {
    case REQUEST_NOTIFICATIONS:
      return update(state, {
        isRequesting: { $set: true },
      });
    case RECEIVE_NOTIFICATIONS:
      return update(state, {
        notifications: { $set: response.notifications },
        isRequesting: { $set: false },
        wasRequestSuccessful: { $set: true },
      });
    case HANDLE_NOTIFICATIONS_ERROR:
      return update(state, {
        isRequesting: { $set: false },
      });
    case SET_NOTIFICATIONS:
      return update(state, {
        notifications: { $set: action.notifications },
      });
    default:
      return state;
  }
};
