import React, { Component } from 'react';

/**
 *  Wrapper component that allows to execute a callback function when clicks events are detected outside of it.
 *  Taken from https://code.i-harness.com/en/q/1f0b8c6
 */
class WatchClickOutside extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleClick);
    document.body.addEventListener('keyup', this.onKeyUp);
  }

  componentWillUnmount() {
    // remember to remove all events to avoid memory leaks
    document.body.removeEventListener('click', this.handleClick);
    document.body.removeEventListener('keyup', this.onKeyUp);
  }

  onKeyUp(event) {
    const { container } = this.refs; // get container that we'll wait to be clicked outside
    const { onClickOutside } = this.props; // get click outside callback
    const { target } = event; // get direct click event target
    // if there is no proper callback - no point of checking
    if (
      onClickOutside === null ||
      onClickOutside === undefined ||
      typeof onClickOutside !== 'function'
    ) {
      return;
    }

    if (target !== container && !container.contains(target)) {
      if (event.key === 'Escape') {
        onClickOutside(event);
      }
    }
  }

  handleClick(event) {
    const { container } = this.refs; // get container that we'll wait to be clicked outside
    const { onClickOutside } = this.props; // get click outside callback
    const { target } = event; // get direct click event target

    // if there is no proper callback - no point of checking
    if (typeof onClickOutside !== 'function') {
      return;
    }

    // if target is container - container was not clicked outside
    // if container contains clicked target - click was not outside of it
    if (target !== container && !container.contains(target)) {
      onClickOutside(event); // clicked outside - fire callback
    }
  }

  render() {
    return <div ref="container">{this.props.children}</div>;
  }
}

export default WatchClickOutside;
