import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CardFooter.module.scss';

/**
 * Card Footer components are used for displaying information of Featured Journis, Collections, and Journis
 * inside the cards.
 */
const CardFooter = ({
  cardFooterTypeClass,
  backgroundColor,
  textColor,
  children,
}) => (
  <div
    className={classNames(styles.CardFooter, cardFooterTypeClass)}
    style={{ backgroundColor, color: textColor }}
  >
    {children}
  </div>
);

CardFooter.propTypes = {
  /**
   *  cardFooterTypeClass: CSS Class. It is used to style the card footer according the different available types (Featured Journi, Category, Journi).
   */
  cardFooterTypeClass: PropTypes.string,
  /**
   * backgroundColor: string. Defines the background color of the card footer.
   */
  backgroundColor: PropTypes.string,
  /**
   *  textColor: string. Defines the color of the text inside the footer.
   */
  textColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CardFooter.defaultProps = {
  backgroundColor: 'transparent',
  cardTypeClass: styles.JourniCardFooter,
  titleColor: 'white',
};

/**
 * Featured Journi Card Footer
 */
const FeaturedJourniCardFooter = ({ backgroundColor, title, places }) => (
  <CardFooter
    cardFooterTypeClass={styles.FeaturedJourniCardFooter}
    backgroundColor={backgroundColor}
  >
    <h2 className={styles.Title}>{title}</h2>
    <div className={classNames('tCaption', styles.Caption)}>{places}</div>
  </CardFooter>
);

FeaturedJourniCardFooter.propTypes = {
  /**
   *  Places: string. Text that is displayed to show the places visited in the trip.
   */
  places: PropTypes.string,
  /**
   *  Title: string. Text that will be displayed as the card's title.
   */
  title: PropTypes.string,
};

FeaturedJourniCardFooter.defaultProps = {
  places: '',
};

/**
 * Category Card Footer
 */
const CategoryCardFooter = ({ backgroundColor, title }) => (
  <CardFooter
    cardFooterTypeClass={styles.CategoryCardFooter}
    backgroundColor={backgroundColor}
  >
    <h2 className={styles.Title}>{title}</h2>
  </CardFooter>
);

CategoryCardFooter.propTypes = {
  /**
   * title: string. Text that will be displayed as the card's title.
   */
  title: PropTypes.string,
};

CategoryCardFooter.defaultProps = {
  title: '',
};

/**
 * Journi Card Footer
 */
const JourniCardFooter = ({
  backgroundColor,
  title,
  places,
  momentsPhrase,
}) => (
  <CardFooter
    cardFooterTypeClass={styles.JourniCardFooter}
    backgroundColor={backgroundColor}
  >
    <h2 className={styles.Title}>{title}</h2>
    <div className={classNames('tCaption', styles.Footer)}>
      <div>{places}</div>
      <div>{momentsPhrase}</div>
    </div>
  </CardFooter>
);

JourniCardFooter.propTypes = {
  /**
   *  momentsPhrase: string. Text that is displayed to show the amount of moments that the trip has.
   *  It should consider singular / plural case. {props.entryCount} moments
   */
  momentsPhrase: PropTypes.string,
  /**
   * places: string. Text that is displayed to show the places visited in the trip.
   */
  places: PropTypes.string,
};

JourniCardFooter.defaultProps = {
  places: '',
  momentsPhrase: '',
};

export { JourniCardFooter, FeaturedJourniCardFooter, CategoryCardFooter };
