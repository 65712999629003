/* Analytics Categories */
export const ANALYTICS_CATEGORY_BUTTON = 'button';
export const ANALYTICS_CATEGORY_ACCESS = 'access';
export const ANALYTICS_CATEGORY_LOGIN = 'login';
export const ANALYTICS_CATEGORY_ACQUISITION = 'acquisition';
export const ANALYTICS_CATEGORY_RETENTION = 'retention';

/* Analytics Labels */
export const ANALYTICS_LABEL_LOGIN_SUCCESSFUL = 'login_successful';
export const ANALYTICS_LABEL_SIGNUP_SUCCESSFUL = 'signup_successful';
export const ANALYTICS_LABEL_AUTH_SUCCESSFUL = 'auth_successful';
export const ANALYTICS_LABEL_ADD_FIRST_MOMENT = 'add_first_moment';
export const ANALYTICS_LABEL_PREVIEW_SUBSCRIPTION = 'preview_subscription';
export const ANALYTICS_LABEL_SELECT_ = 'select_%s';
export const ANALYTICS_LABEL_EXPLORE_CATEGORY_PLACE = 'explore_category_place';
export const ANALYTICS_LABEL_EXPLORE_CATEGORY_ = 'explore_category_%s';
export const ANALYTICS_LABEL_LIKE_ACTION = 'like_action';
export const ANALYTICS_LABEL_USER_INTERACTION = 'user_interaction';
