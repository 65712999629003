/**
 * Helper class that provides icon related methods.
 */
import EntryHelper from '../screens/TripTimelinePage/helpers/Entry';

export default class IconHelper {
  /**
   * Returns icon data associated to the given categoryId. If no matches are found, default icon is returned
   * (other category).
   * @param {number} categoryId - numeric representation of one of the entries categories.
   * @returns object containing icon data such its name and CSS classname.
   */
  static getCategoryIcon(categoryId) {
    let icon = null;
    switch (categoryId) {
      case EntryHelper.ENTRY_TYPES.STANDARD_ACCOMMODATION.id:
        icon = {
          class: 'icon-accommodation-streamline',
          name: 'accommodation',
          color: '#FC8458',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_ACTIVITY.id:
        icon = {
          class: 'icon-activity-streamline',
          name: 'activity',
          color: '#00654E',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_NATURE.id:
        icon = {
          class: 'icon-nature-streamline',
          name: 'nature',
          color: '#ABD470',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_FOOD.id:
        icon = {
          class: 'icon-food-streamline',
          name: 'food',
          color: '#A0C4A2',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_SHOPPING.id:
        icon = {
          class: 'icon-shopping-streamline',
          name: 'shopping',
          color: '#F60051',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_NIGHTLIFE.id:
        icon = {
          class: 'icon-nightlife-streamline',
          name: 'nightlife',
          color: '#F9534C',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_CULTURE_ART.id:
        icon = {
          class: 'icon-culture-art-streamline',
          name: 'culture-art',
          color: '#C00A66',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_OTHER.id:
        icon = {
          class: 'icon-other-streamline',
          name: 'other',
          color: '#1467AC',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_WORTH_SEEING.id:
        icon = {
          class: 'icon-worth-seeing-streamline',
          name: 'worth-seeing',
          color: '#FFAD40',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_TRANSPORT.id:
        icon = {
          class: 'icon-transport-streamline',
          name: 'transport',
          color: '#A6549B',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_COFFEE_DRINKS.id:
        icon = {
          class: 'icon-coffee-drinks-streamline',
          name: 'coffee-drinks',
          color: '#C9B098',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_ENTERTAINMENT.id:
        icon = {
          class: 'icon-entertainment-streamline',
          name: 'entertainment',
          color: '#91D4C1',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_ARCHITECTURE.id:
        icon = {
          class: 'icon-architecture-streamline',
          name: 'architecture',
          color: '#58ADA0',
        };
        break;
      case EntryHelper.ENTRY_TYPES.STANDARD_RELAX.id:
        icon = {
          class: 'icon-relax-streamline',
          name: 'relax',
          color: '#009BD5',
        };
        break;
      default:
        icon = {
          class: 'icon-other-streamline',
          name: 'other',
          color: '#1467AC',
        };
    }
    return icon;
  }
}
