import React from 'react';
import connect from 'react-redux/es/connect/connect';
import styles from './LoadingPage.module.scss';
import LoadingSpinner from '../../../common/components/Spinners';

/**
 *  This component uses the whole screen and shows a rotating spinner.
    Useful to denote that the app is busy.
 */
const LoadingPage = ({ children, isLoadingScreenVisible }) => (
  <div>
    <div
      className={[
        styles.loadingPage,
        isLoadingScreenVisible ? styles.visible : '',
      ].join(' ')}
    >
      {children}
      <LoadingSpinner spin />
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  isLoadingScreenVisible: state.loading.isLoadingScreenVisible,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage);
