import { store } from '../index';
import * as JourniAPI from '../network/journiAPI';
import { triggerEventAction } from '../redux/analytics';
import {
  ANALYTICS_CATEGORY_ACCESS,
  ANALYTICS_CATEGORY_BUTTON,
  ANALYTICS_CATEGORY_LOGIN,
  ANALYTICS_LABEL_AUTH_SUCCESSFUL,
  ANALYTICS_LABEL_LOGIN_SUCCESSFUL,
  ANALYTICS_LABEL_SIGNUP_SUCCESSFUL,
} from '../utils/constants/eventConstants';
import {
  HANDLE_USER_LOGIN_ERROR,
  REQUEST_USER_LOGIN,
  SET_USER_LOGIN_MESSAGE,
} from '../redux/account/login';
import {
  HANDLE_USER_SIGNUP_ERROR,
  REQUEST_USER_SIGNUP,
} from '../redux/account/signup';
import {
  HANDLE_FORGOT_PASSWORD_ERROR,
  RECEIVE_FORGOT_PASSWORD,
  REQUEST_FORGOT_PASSWORD,
} from '../redux/account/forgotPassword';
import {
  HANDLE_RECOVER_PASSWORD_ERROR,
  RECEIVE_RECOVER_PASSWORD,
  REQUEST_RECOVER_PASSWORD,
} from '../redux/account/recoverPassword';
import { toastFail } from '../common/components/Toasts';
import {
  cleanEventsQueueAction,
  cleanRedirectUrlAction,
  enqueueAction,
} from '../redux/events';
import history from '../routes/history';
import { setUserProfileAction } from '../redux/user';
import NotificationsHelper from './Notifications';
import {
  getSignUpURLWithRedirection,
  isURLExternal,
  removeBasePath,
} from '../utils/urlHelpers';

/**
 * Helper class that provides authentication methods.
 */
export default class AuthHelper {
  /**
   * Creates a Journi account.
   * @param {string} email - String containing the email address of the user.
   * @param {string} password - String containing the password of the user.
   * @param {string} firstName - String containing the first name of the user.
   * @param {string} lastName - String containing the last name of the user.
   * @return {function} Promise.
   */
  static async signup(email, password, firstName, lastName) {
    let response = null;
    store.dispatch({
      type: REQUEST_USER_SIGNUP,
    });

    // Send login credentials
    try {
      response = await JourniAPI.signup(email, password, firstName, lastName);
    } catch (error) {
      store.dispatch({
        type: HANDLE_USER_SIGNUP_ERROR,
        error,
      });
      return;
    }

    // Logging the event
    store.dispatch(
      triggerEventAction(
        ANALYTICS_CATEGORY_BUTTON,
        null,
        ANALYTICS_LABEL_SIGNUP_SUCCESSFUL,
        null,
        null,
        true
      )
    );
    store.dispatch(
      triggerEventAction(
        ANALYTICS_CATEGORY_ACCESS,
        null,
        ANALYTICS_LABEL_AUTH_SUCCESSFUL,
        null,
        null,
        true
      )
    );

    AuthHelper.onSuccessAuth(response);
  }

  /**
   * Logs in a Journi account.
   * @param {string} email - String containing the email address of the user.
   * @param {string} password - String containing the password of the user.
   * @return {function} Promise.
   */
  static async login(email, password) {
    let response = null;
    store.dispatch({
      type: REQUEST_USER_LOGIN,
    });

    // Send login credentials
    try {
      response = await JourniAPI.login(email, password);
    } catch (error) {
      store.dispatch({
        type: HANDLE_USER_LOGIN_ERROR,
        error,
      });
      return;
    }

    // Logging the event
    store.dispatch(
      triggerEventAction(
        ANALYTICS_CATEGORY_LOGIN,
        null,
        ANALYTICS_LABEL_LOGIN_SUCCESSFUL,
        null,
        null,
        true
      )
    );
    store.dispatch(
      triggerEventAction(
        ANALYTICS_CATEGORY_ACCESS,
        null,
        ANALYTICS_LABEL_AUTH_SUCCESSFUL,
        null,
        null,
        true
      )
    );

    AuthHelper.onSuccessAuth(response);
  }

  /**
   * Logs the user out.
   * @return {function} Promise.
   */
  static async logout() {
    const storeState = store.getState();
    const { translatedMessages } = storeState.configuration;
    const { paths } = storeState.configuration;
    try {
      await JourniAPI.logout();
    } catch (error) {
      toastFail(translatedMessages('main.error.oops'));
      return;
    }
    // Redirect to landing page
    window.location.replace(paths.landingPage);
  }

  /**
   * Submits a forgot password request for the associated email's user.
   * @param {string} email - String containing the email address of the user.
   * @return {function} Promise.
   */
  static async forgotPassword(email) {
    store.dispatch({
      type: REQUEST_FORGOT_PASSWORD,
    });

    try {
      const response = await JourniAPI.forgotPassword(email);
      store.dispatch({
        type: RECEIVE_FORGOT_PASSWORD,
        response: response.status,
      });
    } catch (error) {
      store.dispatch({
        type: HANDLE_FORGOT_PASSWORD_ERROR,
        error: error.status,
      });
    }
  }

  /**
   * Submits a recover password request to change the password of the associated token's user.
   * @param {string} token - String containing the provided authentication token.
   * @param {string} password - String containing the new password of the user.
   * @param {string} password2 - String containing the new password of the user that should match the previous password argument.
   * @param {function} onSuccessCallback - callback function called on request success.
   * @return {function} Promise.
   */
  static async recoverPassword(token, password, password2, onSuccessCallback) {
    store.dispatch({
      type: REQUEST_RECOVER_PASSWORD,
    });

    try {
      const response = await JourniAPI.recoverPassword(
        token,
        password,
        password2
      );
      store.dispatch({
        type: RECEIVE_RECOVER_PASSWORD,
        response,
      });
      store.dispatch({
        type: SET_USER_LOGIN_MESSAGE,
        callbackMessage: response,
      });
      onSuccessCallback();
    } catch (error) {
      store.dispatch({
        type: HANDLE_RECOVER_PASSWORD_ERROR,
        error,
      });
    }
  }

  /**
   * Executes enqueued events stored in the app's global state.
   */
  static async runPendingEvents() {
    try {
      const storeState = store.getState();
      const { eventsQueue } = storeState.events;
      while (eventsQueue.length) {
        await eventsQueue.shift()();
      }
      // Redeem invitation token if any
      const invitationToken = storeState.invitation.token;
      if (invitationToken) await JourniAPI.postInviteRedeem(invitationToken);
    } catch (e) {}
  }

  /**
   * Redirects the user to profile page or, if present, to a redirect url set in the global app. Also, executes pending
   * enqueued actions and redeems any existing invitation token.
   * @param {object} userProfile - Object containing the user profile data.
   * @return {function} Promise.
   */
  static async onSuccessAuth(userProfile) {
    const storeState = store.getState();
    const hasRedirection = storeState.events.redirectUrl !== null;

    await AuthHelper.runPendingEvents();
    store.dispatch(cleanEventsQueueAction());
    // Profile is returned on successful authentication, but notifications are not provided, so they should be fetched.
    NotificationsHelper.getNotifications();

    try {
      await store.dispatch(setUserProfileAction(userProfile));
    } catch (e) {
      return;
    }

    if (hasRedirection) {
      const { redirectUrl } = storeState.events;
      // If the url does not belong to one of the routes of the web app then replace location in window object.
      if (isURLExternal(redirectUrl)) {
        window.location.replace(redirectUrl);
      } else {
        history.replace(redirectUrl);
      }
      store.dispatch(cleanRedirectUrlAction());
    } else {
      // If no pending events redirected the user, then redirect user to profile page as fallback.
      history.replace('/profile');
    }
  }

  /**
   * Checks if the user has special privileges.
   * @param userProfile Object containing the user profile data.
   * @return {*|boolean} True if the user has special privileges, false otherwise.
   */
  static isUserAdmin(userProfile) {
    return (
      userProfile &&
      userProfile.features &&
      userProfile.features.length > 0 &&
      userProfile.features.indexOf('admin') !== -1
    );
  }

  /**
   * Checks if the user is authenticated and then executes a series of actions:
   * If user is authenticated executes callbackAuthenticated, if provided, or
   * callback in any other case.
   * If user is not authenticated, it redirects the user to the login page and saves the URL
   * where the user was standing at. After a successful authentication process,
   * the callback function is called.
   * @param callback
   * @param callbackAuthenticated
   */
  static authenticateAndRun(callback = null, callbackAuthenticated = null) {
    const storeState = store.getState();
    const { isAuthenticated } = storeState.user;
    const redirectionURL = removeBasePath(window.location.pathname);
    if (isAuthenticated) {
      if (typeof callbackAuthenticated === 'function') {
        callbackAuthenticated();
      } else if (typeof callback === 'function') {
        callback();
      }
    } else {
      if (typeof callback === 'function') {
        store.dispatch(enqueueAction(callback));
      }
      history.push(getSignUpURLWithRedirection(redirectionURL));
    }
  }
}
