import React from 'react';
import moment from 'moment';
import connect from 'react-redux/es/connect/connect';
import Footer from '../Footer';

const FooterContainer = ({ translatedMessages, paths }) => {
  const links = [
    {
      title: translatedMessages('navigation.help').toUpperCase(),
      href: paths.helpPage,
    },
    {
      title: translatedMessages('navigation.press').toUpperCase(),
      href: paths.pressPage,
    },
    {
      title: translatedMessages('contact.title').toUpperCase(),
      href: paths.imprintPage,
    },
    {
      title: translatedMessages('privacy.title').toUpperCase(),
      href: paths.privacyPage,
    },
    {
      title: 'JOBS',
      href: paths.jobsPage,
    },
  ];
  const currentYear = moment().year();

  return <Footer links={links} currentYear={currentYear} />;
};

const mapStateToProps = (state) => ({
  paths: state.configuration.paths,
  translatedMessages: state.configuration.translatedMessages,
});

export default connect(mapStateToProps, null)(FooterContainer);
