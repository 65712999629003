/**
 * Helper class that provides entry's useful methods.
 */
export default class EntryHelper {
  static ENTRY_TYPES = {
    STAMP: { id: 100, name: 'Stamp' },
    STICKER: { id: 101, name: 'Sticker' },
    FLIGHT: { id: 102, name: 'Flight' },
    WEATHER: { id: 103, name: 'Weather' },
    STANDARD_FALLBACK: { id: 0, name: 'Other' },
    STANDARD_ACCOMMODATION: { id: 1, name: 'Accommodation' },
    STANDARD_ACTIVITY: { id: 2, name: 'Activity' },
    STANDARD_NATURE: { id: 3, name: 'Nature' },
    STANDARD_FOOD: { id: 4, name: 'Food' },
    STANDARD_SHOPPING: { id: 6, name: 'Shopping' },
    STANDARD_NIGHTLIFE: { id: 7, name: 'Nightlife' },
    STANDARD_CULTURE_ART: { id: 8, name: 'Culture & Art' },
    STANDARD_OTHER: { id: 9, name: 'Other' },
    STANDARD_WORTH_SEEING: { id: 10, name: 'Worth Seeing' },
    STANDARD_TRANSPORT: { id: 11, name: 'Transport' },
    STANDARD_COFFEE_DRINKS: { id: 12, name: 'Coffee & Drinks' },
    STANDARD_ENTERTAINMENT: { id: 13, name: 'Entertainment' },
    STANDARD_ARCHITECTURE: { id: 14, name: 'Architecture' },
    STANDARD_RELAX: { id: 15, name: 'Relax' },
  };

  /**
   * Returns the entry's Category.
   * @param {object} entry Entry element that will be checked
   * @return {ENTRY_TYPES} The category of the entry.
   */
  static getEntryCategory(entry) {
    const { categoryId } = entry;
    const entryType = Object.keys(this.ENTRY_TYPES).find(
      (key) => this.ENTRY_TYPES[key].id === categoryId
    );
    return this.ENTRY_TYPES[entryType] || this.ENTRY_TYPES.STANDARD_OTHER;
  }

  /**
   * Checks whether the entry has pictures or not.
   * @param {object} entry Entry element that will be checked
   * @return {boolean} true if the entry has pictures
   */
  static hasPictures(entry) {
    const { pictures } = entry;
    return !!pictures && pictures.length !== 0;
  }

  /**
   * Checks whether the entry has comments or not.
   * @param {object} entry Entry element that will be checked
   * @return {boolean} true if the entry has comments
   */
  static hasComments(entry) {
    const { comment } = entry;
    return !!(comment && comment !== '');
  }

  /**
   * Checks whether the entry has GPS coordinates or not.
   * @param {object} entry Entry element that will be checked
   * @return {boolean} true if the entry has GPS coordinates
   */
  static hasCoordinates(entry) {
    const { lat, lng } = entry;
    return lat !== null && lng !== null;
  }
}
