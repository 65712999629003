import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './NavigationBar.module.scss';
import SearchBar from '../../Input/SearchBar';
import NavigationDropdownMenu from './NavigationDropdownMenu';
import BottomNavigationBar from './BottomNavigationBar';
import Anchor from '../../Misc/Anchor';
import {
  DropdownNavigationItem,
  NavigationItem,
} from '../../../../network/models';

/**
    Presentational component. Renders navigation bar.
 */
class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Logo should be hidden on mobile views when search bar is extended.
      isLogoVisible: true,
    };
    this.changeLogoVisibility = this.changeLogoVisibility.bind(this);
  }

  /**
   * Changes Journi logo visibility.
   *
   * @param {boolean} [shouldLogoBeVisible = this.state.isLogoVisible]
   *   True value makes the logo visible.
   */
  changeLogoVisibility(shouldLogoBeVisible = !this.state.isLogoVisible) {
    this.setState({
      isLogoVisible: shouldLogoBeVisible,
    });
  }

  renderNavigationItems() {
    const { navigationItems } = this.props;
    return navigationItems.map((navigationItem, key) => {
      const componentStyles = classnames(styles.IconItem, {
        [styles.HideOnMobileView]: navigationItem.hideOnTopNavigationMobileView,
      });
      return !navigationItem.onlyBottomNavigation ? (
        <div className={componentStyles} key={key}>
          <Anchor href={navigationItem.to.pathname}>
            <span className={navigationItem.icon} />
            {navigationItem.title}
          </Anchor>
        </div>
      ) : null;
    });
  }

  render() {
    const {
      userProfilePictureURL,
      logoImgURL,
      logoURL,
      logoAnimation,
      navigationItems,
      dropdownMenuItems,
      renderNotificationDropdown,
      isProfileActive,
    } = this.props;
    const { isLogoVisible } = this.state;

    return (
      <React.Fragment>
        <div className={styles.NavigationBar}>
          <div className={[styles.Content, 'tLabel'].join(' ')}>
            <div
              className={[
                styles.JourniLogo,
                logoAnimation,
                isLogoVisible ? '' : styles.HiddenLogo,
              ].join(' ')}
            >
              <a href={logoURL}>
                <img src={logoImgURL} alt="Journi Logo" />
              </a>
            </div>
            <div className={styles.SearchBar}>
              <SearchBar onIconClick={this.changeLogoVisibility} />
            </div>
            {this.renderNavigationItems()}
            <div
              className={[styles.IconItem, styles.HideOnMobileView].join(' ')}
            >
              {renderNotificationDropdown()}
            </div>
            <div className={styles.DropdownMenu}>
              <NavigationDropdownMenu
                userProfilePicture={userProfilePictureURL}
                elements={dropdownMenuItems}
                isProfileActive={isProfileActive}
              />
            </div>
          </div>
        </div>
        <div className={styles.BottomNavigationBar}>
          <BottomNavigationBar
            userProfilePictureURL={userProfilePictureURL}
            navigationItems={navigationItems}
            userMenuElements={dropdownMenuItems}
            isProfileActive={isProfileActive}
          />
        </div>
      </React.Fragment>
    );
  }
}

NavigationBar.propTypes = {
  /*
        userProfilePictureURL: url of the profile picture that will displayed.
     */
  userProfilePictureURL: PropTypes.string.isRequired,
  /*
        logoImgURL: url of the displayed icon image
     */
  logoImgURL: PropTypes.string.isRequired,
  /*
        logoURL: url for the logo anchor
     */
  logoURL: PropTypes.string.isRequired,
  /*
        logoAnimation: classname of the css animation for the logo
     */
  logoAnimation: PropTypes.string,
  /*
        navigationItems: array of navigation item that will be displayed (check NavigationItem type)
     */
  navigationItems: PropTypes.arrayOf(NavigationItem).isRequired,
  /*
        dropdownMenuItems: array of navigation item that will be displayed (check NavigationItem type)
     */
  dropdownMenuItems: PropTypes.arrayOf(DropdownNavigationItem).isRequired,
  /*
        Render prop for the Notification Dropdown
     */
  renderNotificationDropdown: PropTypes.func.isRequired,
  /*
        isProfileActive: if true profile picture will be featured
     */
  isProfileActive: PropTypes.bool,
};

export default NavigationBar;
