import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './PublicNavigationBar.module.scss';
import PublicBottomNavigationBar from './PublicBottomNavigationBar';
import Anchor from '../../Misc/Anchor';
import { NavigationItem } from '../../../../network/models';

/*
  Presentational component that renders public navigation bar.
 */
class PublicNavigationBar extends Component {
  renderNavigationItems() {
    const { navigationItems } = this.props;
    return navigationItems.map((navigationItem, key) => {
      const componentStyles = classnames(styles.IconItem, {
        [styles.HideOnMobileView]: navigationItem.hideOnTopNavigationMobileView,
      });

      return (
        <div className={componentStyles} key={key}>
          <Anchor
            href={navigationItem.to ? navigationItem.to.pathname : null}
            onClick={navigationItem.onClick}
          >
            <span className={navigationItem.icon} />
            {navigationItem.title}
          </Anchor>
        </div>
      );
    });
  }

  render() {
    const { logoImgURL, logoURL, logoAnimation, navigationItems } = this.props;

    return (
      <React.Fragment>
        <div className={styles.PublicNavigationBar}>
          <div className={[styles.Content, 'tLabel'].join(' ')}>
            <div className={[styles.JourniLogo, logoAnimation].join(' ')}>
              <a href={logoURL}>
                <img src={logoImgURL} alt="Journi Logo" />
              </a>
            </div>
            <div className={styles.Links}>{this.renderNavigationItems()}</div>
          </div>
        </div>
        <div className={styles.PublicBottomNavigationBar}>
          <PublicBottomNavigationBar navigationItems={navigationItems} />
        </div>
      </React.Fragment>
    );
  }
}

PublicNavigationBar.propTypes = {
  /*
    logoImgURL: url of the displayed icon image
  */
  logoImgURL: PropTypes.string,
  /*
    logoURL: url for the logo anchor
  */
  logoURL: PropTypes.string,
  /*
    logoAnimation: classname of the css animation for the logo
  */
  logoAnimation: PropTypes.string,
  /*
    navigationItems: array of navigation item that will be displayed (check NavigationItem type)
  */
  navigationItems: PropTypes.arrayOf(NavigationItem),
};

export default PublicNavigationBar;
