/**
 * Network module to handle all requests including the headers required by the backend.
 * Should be used to make all requests to API.
 */

export default class Http {
  static makeRequest(url, method, obj = null) {
    return new Promise((resolve, reject) => {
      const isFileUpload = obj instanceof FormData;
      const request = new XMLHttpRequest();

      request.onreadystatechange = () => {
        if (request.status !== 0 && request.readyState === 4) {
          // parse json
          const contentType = request.getResponseHeader('content-type');
          let response = null;
          if (contentType.lastIndexOf('application/json', 0) === 0) {
            response = JSON.parse(request.responseText);
          } else {
            response = request.responseText;
          }

          // handle success / failure
          if (request.status <= 200) {
            resolve(response);
          } else {
            reject(response);
          }
        }
        if (request.status === 401) {
          window.location.replace('/login-signup');
        }
      };

      request.open(method, url, true);

      if (method === 'POST' || method === 'PUT') {
        if (isFileUpload) {
          request.send(obj);
        } else {
          request.setRequestHeader(
            'Content-Type',
            'application/json;charset=UTF-8'
          );
          request.send(JSON.stringify(obj));
        }
      } else {
        request.send();
      }
    });
  }

  static get(url) {
    return Http.makeRequest(url, 'GET');
  }

  static post(url, obj) {
    return Http.makeRequest(url, 'POST', obj);
  }

  static put(url, obj) {
    return Http.makeRequest(url, 'PUT', obj);
  }

  static delete(url) {
    return Http.makeRequest(url, 'DELETE');
  }
}
