import { gcd } from './arrays';

export const AXIS_TYPES = {
  VERTICAL: 1,
  HORIZONTAL: 0,
};

export function getRatio(width, height, rounded = true) {
  if (rounded) {
    const calculatedGCD = gcd(width, height);
    return [width / calculatedGCD, height / calculatedGCD];
  }
  return width / height;
}

export function isLandscape(ratio) {
  return ratio > 1.0;
}

export function isPortrait(ratio) {
  return ratio < 1.0;
}

export function isSquared(ratio) {
  return ratio === 1.0;
}

export function getSaturatedRatio(ratio, minValue, maxValue) {
  if (ratio === Infinity) {
    return 1;
  }

  if (ratio > maxValue) {
    return maxValue;
  }

  if (ratio < minValue) {
    return minValue;
  }

  return ratio;
}

export function addRatiosHorizontally(...ratios) {
  return ratios.reduce((a, b) => a + b, 0);
}

export function addRatiosVertically(...ratios) {
  return 1.0 / ratios.reduce((a, b) => a + 1.0 / b, 0);
}
