import moment from 'moment-timezone';
import {
  getImageURL,
  getTripUrl,
  getUserProfileURL,
  IMAGE_FORMAT_TYPE_MOBILE_FULL,
  IMAGE_FORMAT_TYPE_MOBILE_PROFILE,
  IMAGE_FORMAT_TYPE_MOBILE_RET,
} from '../../utils/urlHelpers';
import { getCSSColor, safelyParseJSON } from '../../utils/converters';
import StoreHelper from '../../helpers/Store';
import TripHelper from '../../screens/TripTimelinePage/helpers/Trip';
import { getRatio } from '../../utils/imageHelper';
import { flat, groupBy } from '../../utils/arrays';
import GeoHelper from '../../helpers/GeoHelper';
import IconHelper from '../../helpers/IconsEntries';
import { formatShortTime, formatDay } from '../../helpers/DateFormat';
import EntryHelper from '../../screens/TripTimelinePage/helpers/Entry';

export default class TripTransformer {
  /**
   * Enriches trip entries raw objects.
   * @param entries
   * @param authors
   * @return {*}
   */
  static enrichTripEntries(entries, isUserAuthor, tripColor) {
    const { paths } = StoreHelper.getConfiguration();

    let filteredEntries = entries;

    /**
     * Dont show deleted entries
     */
    filteredEntries = filteredEntries.filter((entry) => !entry.deleted);

    if (!isUserAuthor) {
      filteredEntries = filteredEntries.filter((entry) =>
        TripHelper.isTripEntryPublic(entry)
      );
    }

    filteredEntries.forEach((entry) => {
      entry.parsedCreateDate = formatDay(entry.create, entry.timezone);
      entry.parsedCreateTime = formatShortTime(entry.create, entry.timezone);

      // Enrich pictures if any
      if (entry.pictures) {
        entry.pictures.forEach((picture) => {
          picture.url = getImageURL(
            paths.picture,
            picture.guid,
            IMAGE_FORMAT_TYPE_MOBILE_RET
          );
          picture.previewURL = getImageURL(
            paths.picture,
            picture.guid,
            IMAGE_FORMAT_TYPE_MOBILE_PROFILE
          );
          picture.highResURL = getImageURL(
            paths.picture,
            picture.guid,
            IMAGE_FORMAT_TYPE_MOBILE_FULL
          );
          picture.ratio = getRatio(picture.width, picture.height, false);
          picture.parsedCreateDate = formatDay(picture.createDate);
          picture.parsedCreateTime = formatShortTime(picture.createDate);
          picture.color = tripColor;
        });
      }

      if (entry.comment.trim()) {
        // Some entry types need data to be parsed from string values.
        if (entry.categoryId === EntryHelper.ENTRY_TYPES.FLIGHT.id) {
          // Safely parsing flight entry attributes.
          const { toCode, toName, fromCode, fromName, color } = safelyParseJSON(
            entry.comment
          );

          entry.toCode = toCode;
          entry.toName = toName;
          entry.fromName = fromName;
          entry.fromCode = fromCode;
          entry.color = color;
        } else if (entry.categoryId === EntryHelper.ENTRY_TYPES.WEATHER.id) {
          // Safely parsing weather entry attributes.
          const { weatherId, temperature } = safelyParseJSON(entry.comment);

          entry.weatherId = weatherId;
          entry.temperature = temperature;
        } else if (entry.categoryId === EntryHelper.ENTRY_TYPES.STICKER.id) {
          // Safely parsing emoji entry attributes.
          const { text } = safelyParseJSON(entry.comment);

          entry.emoji = text;
        }
      }
    });

    // Entries are grouped by their creation date.
    const groupedEntries = Array.from(
      groupBy(
        filteredEntries,
        ({ create, timezone }) =>
          `${moment.tz(create, timezone).format('MM/DD/YYYY')}--${timezone}`
      ).values()
    );

    groupedEntries.forEach((groupedEntry, i) => {
      const firstEntryInGroup = groupedEntry[0];
      const prevEntryGroup = groupedEntries[i - 1];

      if (prevEntryGroup) {
        const firstEntryInPrevGroup = prevEntryGroup[0];

        /**
         * If there are two groups of entries with the same date.
         * We have to show the timezone
         */
        if (
          moment(firstEntryInPrevGroup.create).isSame(
            firstEntryInGroup.create,
            'date'
          )
        ) {
          firstEntryInPrevGroup.showTimezone = true;
          firstEntryInGroup.showTimezone = true;
        }
      }

      /**
       * Only first entry of the group show date.
       */
      firstEntryInGroup.showDate = true;
    });

    return flat(groupedEntries);
  }

  /**
   * Enriches trip raw object
   * @param response
   */
  static enrichTrip(response) {
    const { trip, entries } = response;
    // Trip URL
    const { paths } = StoreHelper.getConfiguration();
    const userProfile = StoreHelper.getUserProfile();
    const isUserAuthor = TripHelper.isUserAuthor(userProfile.id, trip);

    trip.url = getTripUrl(trip.url);
    trip.coverImageURL = getImageURL(
      paths.picture,
      trip.pictureGuid,
      IMAGE_FORMAT_TYPE_MOBILE_FULL
    );
    trip.coverColor = getCSSColor(trip.pictureColor);

    // Enrich authors
    trip.authors.forEach((author) => {
      author.pictureURL = getImageURL(
        paths.picture,
        author.pictureGuid,
        IMAGE_FORMAT_TYPE_MOBILE_FULL
      );
      author.userURL = getUserProfileURL(author.url);
    });

    if (entries) {
      response.entries = TripTransformer.enrichTripEntries(
        entries,
        isUserAuthor,
        trip.coverColor
      );
    }
  }

  /**
   * Gets map-valid entries from a collection of entries. A valid map entry should contain lat and lng attributes,
   * and also should not match the center coordinate (0,0).
   * @param entries
   * @return {*}
   */
  static filterMapValidEntries(entries) {
    const { paths } = StoreHelper.getConfiguration();

    return entries
      .filter(
        (entry) =>
          !GeoHelper.isCenterCoordinate(entry.lat, entry.lng) &&
          entry.lat &&
          entry.lng
      )
      .map((entry, index) => ({
        id: entry.id,
        index,
        iconImage: IconHelper.getCategoryIcon(entry.categoryId),
        coordinates: [entry.lng, entry.lat],
        categoryId: entry.categoryId,
        visibility: entry.visibility,
        date: entry.parsedCreateDate,
        time: entry.parsedCreateTime,
        pictureURL:
          entry.pictures && entry.pictures.length > 0
            ? getImageURL(
                paths.picture,
                entry.pictures[0].guid,
                IMAGE_FORMAT_TYPE_MOBILE_FULL
              )
            : null,
        comment: entry.comment,
      }));
  }
}
