import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import styles from './FollowerModalItem.module.scss';
import {
  getImageURL,
  IMAGE_FORMAT_TYPE_MOBILE_FULL,
} from '../../../../utils/urlHelpers';
import { USER_FOLLOWING } from '../../../../utils/constants/usersConstants';
import * as Button from '../../Buttons';
import FollowerHelper from '../../../../helpers/Follower';

class FollowerModalItem extends Component {
  render() {
    const {
      follower,
      paths,
      translatedMessages,
      requesterUserId,
      onFollowAction,
    } = this.props;
    const { firstName, lastName, pictureGuid, trips, url } = follower;
    const followingStatus = follower.status;

    const isOwnUser = follower.userId === requesterUserId;
    const isFollowed = followingStatus === USER_FOLLOWING;

    const userProfilePictureUrl = getImageURL(
      paths.picture,
      pictureGuid,
      IMAGE_FORMAT_TYPE_MOBILE_FULL
    );
    const followerFullName = `${firstName} ${lastName}`;
    const followButtonLabel = isFollowed
      ? translatedMessages('main.unfollow')
      : translatedMessages('main.follow');
    const followerPictureStyle = {
      background: `url(${userProfilePictureUrl}) center center / cover`,
    };

    if (isFollowed) {
      this.followAction = () =>
        FollowerHelper.unfollowUser(follower.userId).then(onFollowAction);
    } else {
      this.followAction = () =>
        FollowerHelper.followUser(follower.userId).then(onFollowAction);
    }

    let userJournis = `${trips} `;

    if (trips > 0) {
      if (trips > 1) {
        userJournis += translatedMessages('main.trips');
      } else {
        userJournis += translatedMessages('main.trip');
      }
    } else {
      userJournis += translatedMessages('main.trips');
    }

    return (
      <div className={styles.Container}>
        <Link to={`/profile/${url}`}>
          <div
            className={styles.FollowerPicture}
            style={followerPictureStyle}
          />
          <div className={styles.FollowerDescription}>
            <div className={styles.FollowerFullName}>{followerFullName}</div>
            <div className={[styles.FollowerJournis, 'tPlaceholder'].join(' ')}>
              {userJournis}
            </div>
          </div>
        </Link>
        {!isOwnUser && (
          <div className={styles.FollowStatus}>
            <Button.ModalFollow
              isFollowed={isFollowed}
              onClick={debounce(500, this.followAction)}
              message={followButtonLabel}
            />
          </div>
        )}
      </div>
    );
  }
}

FollowerModalItem.propTypes = {
  userId: PropTypes.number,
  follower: PropTypes.object,
  onFollowAction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  requesterUserId: state.user.profile.userId,
  paths: state.configuration.paths,
});

export default connect(mapStateToProps, null)(FollowerModalItem);
