import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import connect from 'react-redux/es/connect/connect';
import queryString from 'query-string';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import styles from './SignPage.module.scss';
import * as Button from '../../common/components/Buttons';
import SignUpForm from './SignUp/SignUpForm';
import SignInForm from './Login/LoginForm';
import ForgotPasswordForm from './ForgotPassword/ForgotPasswordForm';
import RecoverPasswordForm from './RecoverPassword/RecoverPasswordForm';
import Translate from '../../common/components/Misc/Translate';
import ValidationBox from '../../common/components/Boxes/ValidationBox';
import { setRedirectUrl } from '../../redux/events';
import BranchPopup from '../../common/components/Misc/BranchPopup';
import FollowerHelper from '../../helpers/Follower';
import logo from '../../assets/images/landing/intro/group-8@2x.png';
import background from '../../assets/images/background/background-waves@2x.png';

/*
    Sign page holds the components shared between: SignUp, SignIn, Forgot Password, Recover Password and Invite pages.
 */
export const PAGE_TYPE_SIGN_IN = 1; // Exported for usage in recover password page (redirection needed in successful password recovery case).
export const PAGE_TYPE_SIGN_UP = 2;
export const PAGE_TYPE_SIGN_FORGOT = 3;
export const PAGE_TYPE_SIGN_RECOVER = 4;

class SignPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageType: SignPage.getPageType(props.match.path),
      invitation: null,
    };
  }

  // We can dynamically change between sign pages by changing the page type (see PAGE_TYPE constants).
  changePageType(pageType) {
    this.setState({
      pageType,
    });
  }

  static getPageType(path) {
    if (path === '/login') {
      return PAGE_TYPE_SIGN_IN;
    }
    if (path === '/signup') {
      return PAGE_TYPE_SIGN_UP;
    }
    if (path === '/forgot-password') {
      return PAGE_TYPE_SIGN_FORGOT;
    }
    if (path.startsWith('/recover-password')) {
      return PAGE_TYPE_SIGN_RECOVER;
    }
    if (path.startsWith('/invite')) {
      return PAGE_TYPE_SIGN_UP;
    }
    return PAGE_TYPE_SIGN_IN;
  }

  componentDidMount() {
    const {
      doSetRedirectUrl,
      location: { search },
      match: { params },
    } = this.props;
    const { hasTokenInvitation } = this.props;
    let { hasFriendInvitation } = this.props;
    const { token } = params;
    let { friendUrl, tripUrl } = params;

    // If URL contains a redirection parameter, then store it for later redirection
    const parsedSearch = queryString.parse(search);
    const { redirect } = parsedSearch;

    if (redirect) {
      // Keep other search keys, values
      parsedSearch.redirect = undefined; // Remove redirect from search keys
    }

    const searchString = `?${queryString.stringify(parsedSearch)}`;

    // Catch case when friend invitation comes redirected
    if (redirect && !hasFriendInvitation) {
      const friendInvitation = matchPath(redirect, {
        path: '/friend-invite/:friendUrl/:tripUrl',
      });

      if (friendInvitation) {
        hasFriendInvitation = true;
        friendUrl = friendInvitation.params.friendUrl;
        tripUrl = friendInvitation.params.tripUrl;
      }
    }

    if (hasFriendInvitation) {
      FollowerHelper.fetchFriendInvitation(friendUrl, tripUrl);
    } else if (hasTokenInvitation) {
      FollowerHelper.fetchTokenInvitation(token);
    } else if (redirect) {
      doSetRedirectUrl(`${redirect}${searchString}`);
    }
  }

  static renderCallbackMessage(callbackBoxType, callbackMessage) {
    if (callbackMessage) {
      return (
        <Row>
          <Col xs={12} className="mb5">
            <ValidationBox boxType={callbackBoxType}>
              {callbackMessage}
            </ValidationBox>
          </Col>
        </Row>
      );
    }
    return null;
  }

  render() {
    const {
      paths,
      translate,
      invitation,
      match: { params },
    } = this.props;
    const { pageType } = this.state;
    const { recoveryToken } = params;

    // A different background image is set if the invitation is related to a trip.
    const backgroundImage = {
      backgroundImage:
        invitation && invitation.maybeTripPicture
          ? `linear-gradient(to bottom, rgba(0, 164, 170,0.6) 0%,rgba(0, 164, 170, 0.9) 100%),
          url("${invitation.maybeTripPicture}")`
          : `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
    };

    /*
            Bottom elements lay outside the form container, so they are defined in here.
         */
    const bottomSignUp = (
      <Translate>
        {(translate) => (
          <Row center="xs" middle="md">
            <Col md={4} className="my3">
              <div
                className="darkBackground tLabel"
                onClick={this.changePageType.bind(this, PAGE_TYPE_SIGN_IN)}
              >
                {translate('invite.haveanaccount')}
              </div>
            </Col>
            <Col md={4}>
              <Button.Secondary
                onClick={this.changePageType.bind(this, PAGE_TYPE_SIGN_IN)}
                buttonSize="Block"
              >
                {translate('main.sign_in')}
              </Button.Secondary>
            </Col>
          </Row>
        )}
      </Translate>
    );

    const bottomSignIn = (
      <Translate>
        {(translate) => (
          <Row center="xs" middle="md">
            <Col md={4} className="my3">
              <div
                className="darkBackground tLabel"
                onClick={this.changePageType.bind(this, PAGE_TYPE_SIGN_UP)}
              >
                {translate('invite.donthaveanaccount')}
              </div>
            </Col>
            <Col md={4}>
              <Button.Secondary
                onClick={this.changePageType.bind(this, PAGE_TYPE_SIGN_UP)}
                buttonSize="Block"
              >
                {translate('main.sign_up')}
              </Button.Secondary>
            </Col>
          </Row>
        )}
      </Translate>
    );

    // When the path contains an invitation token we render additional elements.
    const renderInvitationElements = function () {
      const invitationElements = [];
      if (invitation) {
        if (invitation.friendPicture) {
          invitationElements.push(
            <img
              key="invitationPicture"
              className={[styles.ProfilePicture, 'mb2'].join(' ')}
              src={invitation.friendPicture}
              alt="Friends profile"
            />
          );
        }
        if (invitation.headerText) {
          invitationElements.push(
            <div key="invitationText">
              <h1 className={[styles.Invitation, 'mt2 mb4'].join(' ')}>
                {invitation.headerText}
              </h1>
            </div>
          );
        }
      }
      return invitationElements;
    };

    return (
      <div className={styles.SignPage} style={backgroundImage}>
        <BranchPopup />
        <a href={paths.landingPage}>
          <img alt="Journi Logo" className={styles.Logo} src={logo} />
        </a>
        <div>
          {renderInvitationElements()}
          {pageType === PAGE_TYPE_SIGN_IN ? (
            <SignInForm
              paths={paths}
              translate={translate}
              onPageChange={this.changePageType.bind(this)}
            />
          ) : null}
          {pageType === PAGE_TYPE_SIGN_UP ? (
            <SignUpForm paths={paths} translate={translate} />
          ) : null}
          {pageType === PAGE_TYPE_SIGN_FORGOT ? (
            <ForgotPasswordForm
              paths={paths}
              onPageChange={this.changePageType.bind(this)}
            />
          ) : null}
          {pageType === PAGE_TYPE_SIGN_RECOVER ? (
            <RecoverPasswordForm
              recoveryToken={recoveryToken}
              paths={paths}
              onPageChange={this.changePageType.bind(this)}
            />
          ) : null}
        </div>
        <div className={[styles.Bottom, 'mt2', 'mb4'].join(' ')}>
          {pageType === PAGE_TYPE_SIGN_IN ? bottomSignIn : null}
          {pageType === PAGE_TYPE_SIGN_UP ? bottomSignUp : null}
        </div>
      </div>
    );
  }
}

SignPage.propTypes = {
  /*
    type: bool, on true component was loaded from a friend invitation route and it should fetch
    invitation data after mounting.
  */
  hasFriendInvitation: PropTypes.bool,
  /*
    type: bool, on true component was loaded from a token invitation route and it should fetch
    invitation data after mounting.
  */
  hasTokenInvitation: PropTypes.bool,
};

SignPage.defaultProps = {
  hasFriendInvitation: false,
  hasTokenInvitation: false,
};

const mapStateToProps = (state) => ({
  invitation: state.invitation,
});

const mapDispatchToProps = (dispatch) => ({
  doSetRedirectUrl: (redirectUrl) => {
    dispatch(setRedirectUrl(redirectUrl));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignPage);
