/* Core components */
import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import history from './history';
/* Configuration */
import { fetchConfiguration } from '../redux/configuration';
/* Routes */
import ExclusivePublicRoute from './auth/ExclusivePublicRoute';
import PrivateRoute from './auth/PrivateRoute';
import RichRoute from './auth/RichRoute';
/* Tracking */
import Analytics from '../common/components/Tracking/Analytics';
/* Toasts */
import 'react-toastify/dist/ReactToastify.css';
/* Pages */
import NoMatchPage from '../screens/Common/NoMatchPage/NoMatchPage';
import LoadingPage from '../screens/Common/LoadingPage/LoadingPage';
import SignPage from '../screens/Login/SignPage';
import NotificationsPage from '../screens/Common/NotificationsPage/NotificationsPage';
import SettingsPage from '../screens/SettingsPage/SettingsPage';
import ProfilePage from '../screens/ProfilePage/ProfilePage';
import TripTimelinePage from '../screens/TripTimelinePage/Index';
import SubscriptionPage from '../screens/SubscriptionPage/SubscriptionPage';

/* Styles */
import baseStyle from './App.module.scss';
import { hideLoadingScreen, showLoadingScreen } from '../redux/loading';

class App extends Component {
  componentDidMount() {
    const { init } = this.props;

    // Fetches the configuration from backend
    init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoading, setLoading } = this.props;
    if (isLoading !== prevProps.isLoading) {
      if (isLoading) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
  }

  render() {
    return (
      <Router history={history}>
        <div className={baseStyle.mainTheme}>
          <Route component={Analytics} />
          <ToastContainer autoClose={4000} hideProgressBar />
          <LoadingPage />
          <Switch>
            <PrivateRoute exact path="/" component={ProfilePage} />
            <RichRoute
              exact
              path="/profile/:userUrl"
              component={ProfilePage}
              displayNavBar
            />
            <PrivateRoute exact path="/profile" component={ProfilePage} />
            <PrivateRoute exact path="/myprofile" component={ProfilePage} />
            <PrivateRoute
              exact
              path="/mytrips"
              component={ProfilePage}
              openMyTripsTab
            />
            <PrivateRoute
              path="/tripsifollow"
              component={ProfilePage}
              openTripsIFollowTab
            />
            <PrivateRoute
              path="/explore/:categoryKey/:page?"
              component={ProfilePage}
            />
            <PrivateRoute
              exact
              path="/notifications"
              component={NotificationsPage}
            />
            <PrivateRoute exact path="/explore" component={ProfilePage} />
            <PrivateRoute path="/settings" component={SettingsPage} />
            <RichRoute
              path="/j/:tripURL"
              component={TripTimelinePage}
              displayNavBar
            />
            <RichRoute
              path="/invite/:token"
              component={SignPage}
              hasTokenInvitation
              showFooter={false}
            />
            <RichRoute
              path="/friend-invite/:friendUrl/:tripUrl?"
              component={SignPage}
              hasFriendInvitation
              showFooter={false}
            />
            <RichRoute
              path="/unsubscribe/:userUrl?/:userSecret?/:notificationKey?/:tripUrl?"
              component={SubscriptionPage}
              showFooter={false}
            />
            {/** Login Routes */}
            <ExclusivePublicRoute
              exact
              path="/login-signup"
              component={SignPage}
            />
            <ExclusivePublicRoute exact path="/login" component={SignPage} />
            <ExclusivePublicRoute exact path="/signup" component={SignPage} />
            <RichRoute exact path="/forgot-password" component={SignPage} />
            <RichRoute
              exact
              path="/recover-password/:recoveryToken"
              component={SignPage}
            />
            <RichRoute component={NoMatchPage} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  /*
    isLoading property just depends on the required web app configuration loading state (fetched configuration values from backend and
    user's profile loading states). It is used to ensure the components will be able to use configuration values.
    Without them we can not ensure that components (such as forms) will be functional.
  */
  isLoading: state.configuration.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    dispatch(fetchConfiguration());
  },
  setLoading: (isLoading) => {
    if (isLoading) dispatch(showLoadingScreen());
    else dispatch(hideLoadingScreen());
  },
});

const ApplicationContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default ApplicationContainer;
