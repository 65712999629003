import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import moment from 'moment';
import styles from './DateInput.module.scss';
import Select from './Select';
import 'moment/min/locales';
import { formattedDateToLong } from '../../../utils/converters';
import { getBrowserLanguage } from '../../../helpers/Locality';

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: props.year,
      month: props.month,
      day: props.day,
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeDateInput = this.onChangeDateInput.bind(this);
    moment.locale(getBrowserLanguage());
  }

  onChange(event) {
    const { year, month, day } = this.state;
    // Build date
    event.target.value = `${year}-${month}-${day}`;
    // Only event's value is provided
    const propagatedEvent = {
      target: {
        name: this.props.name,
        value: formattedDateToLong(`${year}-${month}-${day}`),
      },
    };
    this.props.onChange(propagatedEvent);
  }

  getMonths() {
    return moment.months().map((month, i) => ({
      id: i + 1,
      text: month,
    }));
  }

  getDays() {
    const { month, year } = this.state;
    const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
    return [...Array(daysInMonth).keys()].map((day, i) => ({
      id: i + 1,
      text: `${i + 1}`,
    }));
  }

  getYears() {
    const currentYear = moment().year();

    return [...Array(120).keys()].map((month, i) => ({
      id: currentYear - i,
      text: `${currentYear - i}`,
    }));
  }

  onChangeDateInput(event) {
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.onChange(event)
    );
  }

  render() {
    const { className } = this.props;

    const monthInputComponent = (
      <Select
        className="tInput"
        id="month"
        name="month"
        value={this.state.month}
        onChange={this.onChangeDateInput}
        elements={this.getMonths()}
      />
    );

    const dayInputComponent = (
      <Select
        className="tInput"
        id="day"
        name="day"
        value={this.state.day}
        onChange={this.onChangeDateInput}
        elements={this.getDays()}
      />
    );

    const yearInputComponent = (
      <Select
        className="tInput"
        id="year"
        name="year"
        value={this.state.year}
        onChange={this.onChangeDateInput}
        elements={this.getYears()}
      />
    );

    return (
      <div className={[styles.Container, className].join(' ')}>
        <label htmlFor={this.props.name} className="tLabel mb1">
          {this.props.title}
        </label>
        <Row>
          <Col xs={3} md={6} xl={3}>
            {dayInputComponent}
          </Col>
          <Col xs={5} md={6} xl={5} className="mb1">
            {monthInputComponent}
          </Col>
          <Col xs={4} md={12} xl={4}>
            {yearInputComponent}
          </Col>
        </Row>
      </div>
    );
  }
}

DateInput.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  year: PropTypes.number,
  month: PropTypes.number,
  day: PropTypes.number,
};

export default DateInput;
