/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component
 */
const StickerComponent = (props) => {
  const { className, emoji } = props;

  return <div className={className || null}>{emoji}</div>;
};

/**
 * PropTypes
 */
StickerComponent.propTypes = {
  className: PropTypes.string,
  emoji: PropTypes.string.isRequired,
};

StickerComponent.defaultProps = {
  className: null,
};

/**
 * Expose Component
 */
export default StickerComponent;
