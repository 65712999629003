import React from 'react';
import classNames from 'classnames';
import styles from './BackgroundLine.module.scss';

/**
 * Adds an horizontal line in the background of the children component. Useful as separator.
 */
const BackgroundLine = ({ style, className, children }) => (
  <div style={style} className={classNames(styles.HorizontalStrike, className)}>
    {children}
  </div>
);

export default BackgroundLine;
