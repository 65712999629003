import { store } from '../index';
import * as JourniAPI from '../network/journiAPI';
import NotificationsHelper from './Notifications';
import {
  HANDLE_EDIT_FRIEND_ERROR,
  RECEIVE_EDIT_FRIEND,
  REQUEST_EDIT_FRIEND,
} from '../redux/followers/editFriend';
import {
  handleFriendInvitationErrorAction,
  handleTokenInvitationErrorAction,
  receiveFriendInvitationAction,
  receiveTokenInvitationDataAction,
  requestFriendInvitationAction,
  requestTokenInvitationDataAction,
  setInvitationTokenAction,
} from '../redux/invitation';
import { hideLoadingScreen, showLoadingScreen } from '../redux/loading';
import { setRedirectUrl } from '../redux/events';
import history from '../routes/history';
import { isObjectEmpty } from '../utils/checkers';

/**
 * Helper class that provides user methods.
 */
export default class FollowerHelper {
  /**
   * Invite friend (general, or to trip)
   * @param {string} userId - String containing the user id of the targeted person / follower / author
   * @param {string} tripId - String optional, provided if we are inviting or removing the user from a specific trip
   * @param {string} isAuthorInvite - boolean, provide only when tripId is given: if true, the user is given author rights for the trip shared trips feature.
   * @param {string} remove - boolean, false: invite/accept/follow this user, true: unfollow/remove this user
   * @return {function} Promise.
   */
  static async editFriend(userId, tripId, isAuthorInvite, remove) {
    let response = null;
    store.dispatch({
      type: REQUEST_EDIT_FRIEND,
    });

    try {
      response = await JourniAPI.postFollow(
        userId,
        tripId,
        isAuthorInvite,
        remove
      );
    } catch (error) {
      store.dispatch({
        type: HANDLE_EDIT_FRIEND_ERROR,
        error,
      });
      return;
    }

    store.dispatch({
      type: RECEIVE_EDIT_FRIEND,
      response,
    });

    // Fetch notifications to provide feedback about the action through a notification
    await NotificationsHelper.getNotifications();
  }

  /**
   * Follows user
   * @param {string} userId - String containing the user id of the targeted person.
   * @return {function} Promise.
   */
  static async followUser(userId) {
    await FollowerHelper.editFriend(userId, null, false, false);
  }

  /**
   * Unfollows user
   * @param {string} userId - String containing the user id of the targeted person.
   * @return {function} Promise.
   */
  static async unfollowUser(userId) {
    await FollowerHelper.editFriend(userId, null, false, true);
  }

  /**
   * Follows trip
   * @param {string} userId - String containing the user id of the targeted person.
   * @param {string} tripId - String containing the trip id of the targeted trip.
   * @return {function} Promise.
   */
  static async followTrip(userId, tripId) {
    await JourniAPI.postFollow(userId, tripId, false, false);
  }

  /**
   * Unfollows trip
   * @param {string} userId - String containing the user id of the targeted person.
   * @param {string} tripId - String containing the trip id of the targeted trip.
   * @return {function} Promise.
   */
  static async unfollowTrip(userId, tripId) {
    await JourniAPI.postFollow(userId, tripId, false, true);
  }

  /**
   * Fetches data from token invitation
   * @param {string} token - String containing the invitation token
   * @return {function} Promise.
   */
  static async fetchTokenInvitation(token) {
    store.dispatch(showLoadingScreen()); // We try to avoid that the user can see the invitation elements being loaded.
    let response = null;
    store.dispatch(requestTokenInvitationDataAction());

    try {
      response = await JourniAPI.getTokenInvitation(token);
    } catch (error) {
      store.dispatch(handleTokenInvitationErrorAction(error));
      store.dispatch(hideLoadingScreen());
      return;
    }

    store.dispatch(hideLoadingScreen());

    store.dispatch(receiveTokenInvitationDataAction(response));

    // Store the token for further use
    store.dispatch(setInvitationTokenAction(token));

    const appState = store.getState();
    const userIsAuthenticated = appState.user.isAuthenticated;

    if (userIsAuthenticated) {
      if (isObjectEmpty(response)) {
        history.push('/profile');
      } else if (FollowerHelper.hasTokenInvitationBeenRedeemed(response)) {
        window.location.replace(response.redirectUrl);
      } else {
        history.go(0); // A second request will redeem the token and the user will be redirected.
      }
    } else {
      store.dispatch(setRedirectUrl(response.redirectUrl));
    }
  }

  /**
   * Invite friend (general, or to trip)
   * @param {string} friendUrl - String containing the url of the user who is inviting
   * @param {string} tripUrl - String optional, string containing the url of the trip to start following.
   * @return {function} Promise.
   */
  static async fetchFriendInvitation(friendUrl, tripUrl) {
    store.dispatch(showLoadingScreen()); // We try to avoid that the user can see the invitation elements being loaded.
    let response = null;
    store.dispatch(requestFriendInvitationAction());
    try {
      response = await JourniAPI.getFriendInvitation(friendUrl, tripUrl);
    } catch (error) {
      store.dispatch(handleFriendInvitationErrorAction(error));
      store.dispatch(hideLoadingScreen());
      return;
    }

    store.dispatch(hideLoadingScreen());

    store.dispatch(receiveFriendInvitationAction(response));

    const appState = store.getState();
    const userIsAuthenticated = appState.user.isAuthenticated;

    if (userIsAuthenticated) {
      if (isObjectEmpty(response)) {
        history.push('/profile');
      } else {
        window.location.replace(response.redirectUrl);
      }
    } else {
      store.dispatch(setRedirectUrl(response.redirectUrl));
    }
  }

  /**
   * Checks if the token invitation response has been already redeemed.
   * @param response
   * @return {boolean} true if it was redeemed, false otherwise.
   */
  static hasTokenInvitationBeenRedeemed(response) {
    return typeof response.header === 'undefined';
  }
}
