import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import classnames from 'classnames';
import styles from './GroupedUser.module.scss';
import {
  getImageURL,
  getUserProfileURL,
  IMAGE_FORMAT_TYPE_MOBILE,
} from '../../../../../utils/urlHelpers';
import Anchor from '../../../Misc/Anchor';

const GroupedUser = ({ user, isSmall, paths }) => {
  const backgroundImageURL = getImageURL(
    paths.picture,
    user.pictureGuid,
    IMAGE_FORMAT_TYPE_MOBILE
  );
  const componentStyles = classnames(styles.GroupUserImage, {
    [styles.GroupUserImageSmall]: isSmall,
  });

  const groupedUser = (
    <div
      className={componentStyles}
      style={{ backgroundImage: `url('${backgroundImageURL}')` }}
    />
  );

  if (user.userUrl) {
    return (
      <Anchor href={getUserProfileURL(user.userUrl)}>{groupedUser}</Anchor>
    );
  }
  return <React.Fragment key={user.id}>{groupedUser}</React.Fragment>;
};

GroupedUser.propTypes = {
  user: PropTypes.shape({
    pictureGuid: PropTypes.string.isRequired,
    userUrl: PropTypes.string,
  }),
  isSmall: PropTypes.bool,
};

GroupedUser.defaultValues = {
  isSmall: false,
};

const mapStateToProps = (state) => ({
  paths: state.configuration.paths,
  translatedMessages: state.configuration.translatedMessages,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupedUser);
