import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import {
  getImageURL,
  getCreatePhotoBookURL,
  IMAGE_FORMAT_TYPE_MOBILE_PROFILE,
  getShoppingBagURL,
} from '../../../../utils/urlHelpers';
import { hasNewNotifications } from '../../../../utils/checkers';
import AuthHelper from '../../../../helpers/Auth';
import NavigationBar from './NavigationBar';
import NotificationDropdown from '../Notifications/NotificationDropdown';
import journiLogo from '../../../../assets/images/logos/journi-logo@2x.png';

/**
 * Container component that renders navigation bar.
 */
const NavigationBarContainer = (props) => {
  /**
   * Returns an array of objects which contain links' data.
   */
  const getNavigationItems = () => {
    const { translatedMessages, match, notifications } = props;
    const currentPath = match.path;
    return [
      {
        title: translatedMessages('navigation.trips_i_follow'),
        to: {
          pathname: '/tripsifollow',
          state: {
            openTripsIFollow: true,
          },
        },
        icon: 'icon icon-star',
        isActive: currentPath.startsWith('/tripsifollow'),
        onlyTopNavigation: false,
        hideOnTopNavigationMobileView: true,
      },
      {
        title: translatedMessages('main.trips'),
        to: {
          pathname: '/mytrips',
        },
        icon: 'icon icon-journis',
        isActive: currentPath.startsWith('/mytrips'),
        onlyTopNavigation: false,
        hideOnTopNavigationMobileView: true,
      },
      {
        to: {
          pathname: getShoppingBagURL(),
        },
        icon: 'icon icon-shopping-bag',
        onlyTopNavigation: false,
        hideOnTopNavigationMobileView: true,
      },
      {
        title: translatedMessages('main.notifications'),
        to: '/notifications',
        icon: 'icon icon-notifications',
        isActive: currentPath.startsWith('/notifications'),
        hasNewActivity: hasNewNotifications(notifications),
        onlyBottomNavigation: true,
        hideOnTopNavigationMobileView: true,
      },
    ];
  };

  /**
     Returns an array of objects which be displayed in the dropdown menu.
     */
  const getDropdownMenuItems = () => {
    const { translatedMessages, userProfile, paths } = props;
    const features = userProfile.features || [];
    const { helpPage } = paths;
    const dropdownMenuItems = [
      {
        title: translatedMessages('main.profile'),
        to: {
          pathname: '/profile',
          state: {
            openMyTrips: false, // jump to fragments using hashtags is not supported by react router
            openTripsIFollow: false,
          },
        },
      },
      {
        title: translatedMessages('main.settings'),
        to: '/settings',
      },
      {
        title: translatedMessages('navigation.help'),
        to: helpPage,
        isURLAbsolute: true,
      },
      {
        title: translatedMessages('button.to_print'),
        to: getCreatePhotoBookURL(),
        isURLAbsolute: true,
      },
      {
        title: translatedMessages('main.logout'),
        onClick: () => AuthHelper.logout(),
        isURLAbsolute: true,
      },
    ];

    if (features.indexOf('admin') !== -1) {
      dropdownMenuItems.push({
        title: 'Admin area',
        to: paths.adminPage,
        emphasized: true,
        isURLAbsolute: true,
      });
    }

    return dropdownMenuItems;
  };

  const {
    paths,
    userProfile,
    notifications,
    isBottomNavigation,
    translatedMessages,
    match,
  } = props;
  const { pictureGuid } = userProfile;
  const currentPath = match.path;

  const userProfilePictureURL = getImageURL(
    paths.picture,
    pictureGuid,
    IMAGE_FORMAT_TYPE_MOBILE_PROFILE
  );
  const navigationItems = getNavigationItems();
  const dropdownMenuItems = getDropdownMenuItems();

  return (
    <NavigationBar
      userProfilePictureURL={userProfilePictureURL}
      logoImgURL={journiLogo}
      logoURL={paths.landingPage}
      navigationItems={navigationItems}
      dropdownMenuItems={dropdownMenuItems}
      isProfileActive={currentPath.startsWith('/profile')}
      renderNotificationDropdown={() => (
        <NotificationDropdown
          notifications={notifications}
          isBottomNavigation={isBottomNavigation}
          seeAllMessage={translatedMessages('explore.see_all')}
          noNotificationsMessage={translatedMessages(
            'notifications.empty.notifications'
          )}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  /**
   * required to fetch the user's profile picture.
   */
  userProfile: state.user.profile,
  /**
   * url paths required for links.
   */
  paths: state.configuration.paths,
  translatedMessages: state.configuration.translatedMessages,
  notifications: state.notifications.notifications,
});

export default withRouter(
  connect(mapStateToProps, null)(NavigationBarContainer)
);
