import TrackSubscriber from './TrackSubscriber';

/**
 * Google Analytics Track component
 */
export const GOOGLE_TRACKER_NAME = 'GA';

class GoogleAnalytics extends TrackSubscriber {
  init(shouldTrack, shouldLog) {
    super.init(GOOGLE_TRACKER_NAME, shouldTrack, shouldLog);
    window.ga('create', 'UA-43714271-4', 'journiapp.com');
    if (shouldTrack) {
      window.ga('send', 'pageview');
      this.isInitialized = true;
    }
  }

  logPageChange(pathname, search = '') {
    if (this.shouldLog) {
      console.log(
        `Analytics - GoogleAnalytics ### logPageChange: ${pathname} (search: ${search})`
      );
    }
    if (this.isInitialized) {
      const page = pathname + search;
      const { location } = window;
      // Triggers a pageview event
      window.ga('send', 'pageview', {
        location: location.origin,
        page,
      });
    }
  }

  /** track event with analytics (category, action, [label]), calls callback when completed */
  triggerEventAction(
    eventCategory,
    eventAction,
    eventLabel,
    eventProperties,
    callback = null
  ) {
    if (this.shouldLog) {
      console.log(
        `Analytics - GoogleAnalytics ### triggerEventAction: category=${eventCategory};action=${eventAction};label=${eventLabel};properties=${eventProperties}`
      );
    }
    if (this.isInitialized) {
      // universal analytics tracking
      if (eventLabel) {
        window.ga('send', 'event', eventCategory, eventAction, eventLabel, {
          hitCallback: callback,
        });
      } else {
        window.ga('send', 'event', eventCategory, eventAction, {
          hitCallback: callback,
        });
      }
    } else if (callback) {
      callback();
    }
  }
}

export default GoogleAnalytics;
