/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/min/locales';
import classnames from 'classnames';
import { Markdown } from 'react-showdown';
import Styles from './styles.module.scss';
import { getBrowserLanguage } from '../../../../helpers/Locality';
import Icons from '../../../../helpers/IconsCommon';
import IconCategoryHelper from '../../../../helpers/IconsEntries';
import TranslationsQuantity from '../../../../helpers/TranslationsQuantity';

/**
 * UI Components
 */
import Reactions from '../Reactions';
import TotalReactions from '../TotalReactions';
import CommentModal from '../CommentModal';

/**
 * Component
 */
class DaySection extends React.Component {
  constructor(props) {
    super(props);

    moment.locale(getBrowserLanguage());

    this.state = {
      isCommentModalVisible: false,
    };

    this.onChangeReaction = this.onChangeReaction.bind(this);
    this.toggleCommentModal = this.toggleCommentModal.bind(this);
  }

  onChangeReaction(likedIcon, liked) {
    const { entryId, onChangeReaction } = this.props;
    onChangeReaction(entryId, likedIcon, liked);
  }

  toggleCommentModal() {
    this.setState(({ isCommentModalVisible }) => ({
      isCommentModalVisible: !isCommentModalVisible,
    }));
  }

  render() {
    const {
      children,
      time,
      comment,
      categoryId,
      likeCount,
      top1LikeId,
      top2LikeId,
      commentCount,
      translatedMessages,
      userLikeId,
    } = this.props;

    const { isCommentModalVisible } = this.state;
    const iconCategory = IconCategoryHelper.getCategoryIcon(categoryId);

    return (
      <div className={Styles.DaySection}>
        {/* Header */}
        <div className={classnames(Styles.Row, Styles.Header)}>
          <div className={Styles.Row}>
            <div
              className={Styles.IconCircle}
              style={{ backgroundColor: iconCategory.color }}
            >
              <span
                className={classnames(
                  'icon',
                  iconCategory.class,
                  Styles.IconCategory
                )}
              />
            </div>
            <p className={classnames('tCaption', Styles.Hour)}>{time}</p>
          </div>

          {/** TODO: Add MoreButton with options when the edit/delete actions are available */}
        </div>

        {/* Comment text */}
        {comment && <Markdown markup={comment} />}

        {/* Main content */}
        {children ? <div className={Styles.Content}>{children}</div> : null}

        {/* Reactions & comments */}
        <div className={Styles.Row}>
          <TotalReactions
            likeCount={likeCount}
            top1LikeId={top1LikeId}
            top2LikeId={top2LikeId}
            translatedMessages={translatedMessages}
          />
          <p className={classnames(Styles.Comments, 'tCaptionDark')}>
            {commentCount
              ? TranslationsQuantity.getQuantityPhrase(
                  commentCount,
                  translatedMessages('main.1_comment', commentCount),
                  translatedMessages('main.n_comment', commentCount),
                  '{p1}'
                )
              : null}
          </p>
        </div>

        {/** Actions */}
        <div className={Styles.Actions}>
          <Reactions
            onChange={this.onChangeReaction}
            translatedMessages={translatedMessages}
            reactionSelectedId={userLikeId || null}
          >
            <button className={Styles.ActionButton} type="button">
              <span
                className={classnames(
                  'icon',
                  Icons.HeartOutline,
                  Styles.ActionButtonIcon
                )}
              />
              <p className={Styles.ActionButtonText}>
                {translatedMessages('button.to_like')}
              </p>
            </button>
          </Reactions>

          <button
            className={Styles.ActionButton}
            type="button"
            onClick={() => this.toggleCommentModal()}
          >
            <span
              className={classnames(
                'icon',
                Icons.CommentOutline,
                Styles.ActionButtonIcon
              )}
            />
            <p className={Styles.ActionButtonText}>
              {translatedMessages('button.to_comment')}
            </p>
          </button>
        </div>

        {isCommentModalVisible && (
          <CommentModal
            translatedMessages={translatedMessages}
            onClose={this.toggleCommentModal}
          />
        )}
      </div>
    );
  }
}

/**
 * PropTypes
 */
DaySection.propTypes = {
  entryId: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  comment: PropTypes.string,
  categoryId: PropTypes.number.isRequired,
  likeCount: PropTypes.number,
  top1LikeId: PropTypes.number,
  top2LikeId: PropTypes.number,
  commentCount: PropTypes.number,
  children: PropTypes.node,
  userLikeId: PropTypes.number.isRequired,
  onChangeReaction: PropTypes.func.isRequired,
  translatedMessages: PropTypes.func.isRequired,
};

DaySection.defaultProps = {
  comment: null,
  likeCount: null,
  top1LikeId: null,
  top2LikeId: null,
  commentCount: 0,
  children: null,
};

/**
 * Expose Component
 */
export default DaySection;
