import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { shadeColor } from '../../../utils/converters';
import styles from './styles.module.scss';

export const BADGE_TYPES = {
  PREMIUM: 'Premium',
  FOLLOWED: 'Followed',
};

let Badge = (props) => {
  const { icon, title, responsive, mainColor, badgeType } = props;

  const elementsComponent = (
    <React.Fragment>
      {icon && <span className={icon} />}
      {title && (
        <div className={['tLabel', styles.Title].join(' ')}>{title}</div>
      )}
    </React.Fragment>
  );

  const contentComponent = responsive ? (
    <div className={styles.Responsive}>{elementsComponent}</div>
  ) : (
    elementsComponent
  );

  const backgroundColor = mainColor;

  const badgeStyle = {
    background: shadeColor(0.8, backgroundColor),
    color: mainColor,
    ':hover': {
      background: shadeColor(0.85, backgroundColor),
    },
  };

  return (
    <div
      className={[styles.Badge, styles[badgeType]].join(' ')}
      style={badgeStyle}
    >
      <div className={styles.Content}>{contentComponent}</div>
    </div>
  );
};

Badge = Radium(Badge);

Badge.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  responsive: PropTypes.bool,
  mainColor: PropTypes.string,
};

Badge.defaultProps = {
  responsive: true,
};

export const PremiumBadge = withRouter((props) => (
  <Badge
    badgeType={BADGE_TYPES.PREMIUM}
    title={props.title}
    icon="icon icon-star"
    {...props}
  />
));

export const BasicBadge = withRouter((props) => (
  <Badge
    badgeType={BADGE_TYPES.PREMIUM}
    title={props.title}
    icon="icon icon-star-streamline"
    {...props}
  />
));

// Requires translatedMessages from app global state
export const FollowedBadge = withRouter((props) => (
  <Badge {...props} badgeType={BADGE_TYPES.FOLLOWED}>
    <h2>{props.translatedMessages('main.following')}</h2>
  </Badge>
));
