import moment from 'moment-timezone';

/**
 * Formats date and time to a short time.
 *
 * @param {Date|Moment} date The date and time to format.
 * @param {String?} timezone A timezone to use for formatting.
 */
export function formatShortTime(date, timezone) {
  return moment.tz(date, timezone).format('h:mm A');
}

/**
 * Formats the date and time to day.
 *
 * @param {Date|Moment} date The date and time to format.
 * @param {String?} timezone A timezone to use for formatting.
 */
export function formatDay(date, timezone) {
  const dateTime = moment.tz(date, timezone);
  const currentDateTime = moment.tz(timezone);

  if (
    dateTime.date() < 10 &&
    dateTime.month() === currentDateTime.month() &&
    dateTime.year() === currentDateTime.year()
  ) {
    return dateTime.fromNow();
  }

  if (dateTime.year() !== currentDateTime.year()) {
    return dateTime.format('Do MMM YYYY');
  }

  return dateTime.format('Do MMM');
}
