import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import ModalContainer, { MODAL_TYPES } from '../ModalContainer';

import * as journiAPI from '../../../../network/journiAPI';
import FollowerModalItem from './FollowerModalItem';
import styles from './FollowersModal.module.scss';

class FollowedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followed: {},
      isRequesting: true,
    };
  }

  componentDidMount() {
    this.getUserFollowed();
  }

  componentDidUpdate(prevProps) {
    const isNowModalVisible = !prevProps.isVisible && this.props.isVisible;
    if (isNowModalVisible) {
      this.getUserFollowed();
    }
  }

  setRequestingState(isRequesting) {
    this.setState({
      isRequesting,
    });
  }

  getUserFollowed() {
    const { userId } = this.props;
    journiAPI
      .fetchUserFollowed(userId)
      .then((response) => {
        this.setState({
          followed: response.users,
          isRequesting: false,
        });
      })
      .catch(() => {
        this.setRequestingState(false);
      });
  }

  render() {
    const { isRequesting, followed } = this.state;
    const { translatedMessages, userId, isVisible, onClose } = this.props;
    const followedNumber = followed.length;
    const onFollowAction = () => this.getUserFollowed();

    const header = (
      <h2>
        {translatedMessages('main.following')} {followedNumber}
      </h2>
    );

    const content = (
      <div className={styles.Followers}>
        {followedNumber > 0 &&
          followed.map((follower) => (
            <FollowerModalItem
              key={follower.userId}
              userId={userId}
              follower={follower}
              onFollowAction={onFollowAction}
            />
          ))}
      </div>
    );

    return (
      <ModalContainer
        isVisible={isVisible}
        modalType={MODAL_TYPES.FOLLOWERS}
        header={header}
        content={content}
        isLoading={isRequesting}
        onClose={onClose}
      />
    );
  }
}

FollowedModal.propTypes = {
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FollowedModal);
