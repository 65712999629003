import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './MoreIndicator.module.scss';

const MoreIndicator = ({ isSmall, number }) => {
  const componentStyles = classnames(styles.MoreIndicator, {
    [styles.MoreIndicatorSmall]: isSmall,
  });

  return <div className={componentStyles}>{`+${number}`}</div>;
};

MoreIndicator.propTypes = {
  isSmall: PropTypes.bool,
  number: PropTypes.number.isRequired,
};

MoreIndicator.defaultProps = {
  isSmall: false,
};

export default MoreIndicator;
