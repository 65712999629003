import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from './SubscriptionPage.module.scss';
import ValidationBox, {
  BOX_TYPES,
} from '../../common/components/Boxes/ValidationBox';
import Form from '../../common/components/Form';
import Switch from '../../common/components/Input/Switch';
import * as JourniAPI from '../../network/journiAPI';
import * as Button from '../../common/components/Buttons';
import LoadingSpinner from '../../common/components/Spinners';
import journiLogo from '../../assets/images/landing/intro/group-8@2x.png';
import backgroundWaves from '../../assets/images/background/background-waves@2x.png';

class SubscriptionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trip_like: false,
      trip_invite: false,
      trip_update: false,
      recommendation: false,
      offer: false,
      wasRequestSuccessful: false,
      wasRequestExecuted: false,
      wasChanged: false,
    };
    this.renderCallbackMessage = this.renderCallbackMessage.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleResubscribe = this.handleResubscribe.bind(this);
    this.changeNotificationsSettings = this.changeNotificationsSettings.bind(
      this
    );
  }

  componentDidMount() {
    const {
      location: { search },
      match: { params },
      translatedMessages,
    } = this.props;

    // Token invitation
    // userUrl: String, userSecret: String, notificationKey: String, tripUrl: String
    const { userUrl, userSecret, notificationKey, tripUrl } = params;
    JourniAPI.postUnsubscribe(
      userUrl,
      userSecret,
      notificationKey,
      tripUrl,
      search
    ).then(
      (response) => {
        const {
          trip_like,
          trip_invite,
          trip_update,
          recommendation,
          offer,
        } = response.notifications;
        let callbackMessage = translatedMessages(
          'settings.notification.unsubscribed'
        );
        if (notificationKey === 'daily_updates') {
          callbackMessage = translatedMessages('mail.user.changed_to_weekly');
        } else if (notificationKey === 'manage') {
          callbackMessage = translatedMessages('settings.notification.manage');
        }

        this.setState({
          wasRequestSuccessful: true,
          wasRequestExecuted: true,
          trip_like: trip_like.email,
          trip_invite: trip_invite.email,
          trip_update: trip_update.email,
          recommendation: recommendation.email,
          offer: offer.email,
          callbackMessage,
        });
      },
      (error) => {
        this.setState({
          wasRequestSuccessful: false,
          wasRequestExecuted: true,
          callbackMessage: translatedMessages('main.error.oops'),
        });
      }
    );
  }

  changeNotificationsSettings() {
    const {
      location: { search },
      match: { params },
      translatedMessages,
    } = this.props;
    // Token invitation
    // userUrl: String, userSecret: String, notificationKey: String, tripUrl: String
    const { userUrl, userSecret, notificationKey, tripUrl } = params;
    const notificationSettings = {
      trip_like: this.state.trip_like,
      trip_invite: this.state.trip_invite,
      trip_update: this.state.trip_update,
      recommendation: this.state.recommendation,
      offer: this.state.offer,
    };

    JourniAPI.putUnsubscribe(
      userUrl,
      userSecret,
      notificationKey,
      tripUrl,
      search,
      notificationSettings
    ).then(
      (response) => {
        const callbackMessage = translatedMessages('main.changes_saved');
        this.setState({
          wasRequestSuccessful: true,
          wasRequestExecuted: true,
          wasChanged: false,
          callbackMessage,
        });
      },
      (error) => {
        this.setState({
          wasRequestSuccessful: false,
          wasRequestExecuted: true,
          wasChanged: false,
          callbackMessage: translatedMessages('main.error.oops'),
        });
      }
    );
  }

  handleResubscribe() {
    // Resubscribe the user
    const {
      match: { params },
    } = this.props;
    const { notificationKey } = params;
    this.setState({
      [notificationKey]: true,
    });
    this.changeNotificationsSettings();
  }

  handleCheckboxChange(checked, _event, id) {
    this.setState({
      [id]: checked,
      wasChanged: true,
    });
  }

  renderCallbackMessage(callbackBoxType, callbackMessage) {
    if (callbackMessage) {
      return (
        <ValidationBox className={styles.Validation} boxType={callbackBoxType}>
          {callbackMessage}
        </ValidationBox>
      );
    }
    return null;
  }

  render() {
    const {
      match: {
        params: { notificationKey },
      },
    } = this.props;
    const backgroundImage = {
      backgroundImage: `url("${backgroundWaves}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
    };

    const { translatedMessages, paths } = this.props;
    const {
      trip_like,
      trip_invite,
      trip_update,
      recommendation,
      offer,
      wasRequestSuccessful,
      wasRequestExecuted,
      callbackMessage,
      wasChanged,
    } = this.state;

    const canResubscribe = !wasChanged && notificationKey !== 'manage' && !this.state[notificationKey];
    const canSave = wasChanged;

    const notificationsComponent = (
      <React.Fragment>
        <h3>{translatedMessages('notifications.subtitle')}</h3>
        <Row>
          <Col
            xs={12}
            className={classnames(
              {
                [styles.highlight]: notificationKey === 'offer',
              },
              'mb1 pb2 pt2'
            )}
          >
            <Switch
              name="offer"
              checked={offer}
              label={translatedMessages('notifications.offers')}
              detailedLabel={translatedMessages('notifications.offers_detail')}
              onChange={this.handleCheckboxChange}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className={classnames(
              {
                [styles.highlight]: notificationKey === 'recommendation',
              },
              'mb1 pb2 pt2'
            )}
          >
            <Switch
              name="recommendation"
              checked={recommendation}
              label={translatedMessages('notifications.recommendation')}
              detailedLabel={translatedMessages(
                'notifications.recommendation_detail'
              )}
              onChange={this.handleCheckboxChange}
            />
          </Col>
        </Row>
        <Row>
          <Col
            className={classnames(
              {
                [styles.highlight]: notificationKey === 'trip_like',
              },
              'mb1 pb2 pt2'
            )}
            xs={12}
          >
            <Switch
              name="trip_like"
              checked={trip_like}
              label={translatedMessages('notifications.like')}
              onChange={this.handleCheckboxChange}
            />
          </Col>
          <Col
            xs={12}
            className={classnames(
              {
                [styles.highlight]: notificationKey === 'trip_invite',
              },
              'mb1 pb2 pt2'
            )}
          >
            <Switch
              name="trip_invite"
              checked={trip_invite}
              label={translatedMessages('notifications.request')}
              detailedLabel={translatedMessages('notifications.request_detail')}
              options={this.isMetricOptions}
              onChange={this.handleCheckboxChange}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className={classnames(
              {
                [styles.highlight]: notificationKey === 'trip_update',
              },
              'mb1 pb2 pt2'
            )}
          >
            <Switch
              name="trip_update"
              checked={trip_update}
              label={translatedMessages('notifications.update')}
              detailedLabel={translatedMessages('notifications.update_detail')}
              onChange={this.handleCheckboxChange}
            />
          </Col>
        </Row>

        <div className={[styles.Bottom, 'mt2', 'mb4'].join(' ')}>
          <Button.Primary
            disabled={!canSave}
            onClick={this.changeNotificationsSettings}
          >
            {translatedMessages('button.save')}
          </Button.Primary>
        </div>
      </React.Fragment>
    );

    return (
      <div className={styles.SignPage} style={backgroundImage}>
        <a href={paths.landingPage}>
          <img alt="Journi Logo" className={styles.Logo} src={journiLogo} />
        </a>
        <Form>
          <div className={styles.EmailNotifications}>
            {wasRequestExecuted ? (
              <React.Fragment>
                <div className="tLabel">
                  {translatedMessages('main.email_notifications')}
                </div>
                {wasRequestSuccessful ? (
                  <React.Fragment>
                    <h2 className="mb4">{callbackMessage}</h2>
                    <Button.Primary
                      disabled={!canResubscribe}
                      onClick={this.handleResubscribe}
                    >
                      {translatedMessages('settings.notification.undo')}
                    </Button.Primary>
                    <hr className="mb4 mt3" />
                    {notificationsComponent}
                  </React.Fragment>
                ) : (
                  <Row className="mt4">
                    <Col xs={12}>
                      {this.renderCallbackMessage(
                        BOX_TYPES.FAILURE,
                        callbackMessage
                      )}
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            ) : (
              <LoadingSpinner relativeToContainer />
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
