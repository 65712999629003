import { combineReducers } from 'redux';
import { reducer as configuration } from '../configuration';
import user from '../user';
import forgotPassword from '../account/forgotPassword';
import login from '../account/login';
import signup from '../account/signup';
import notifications from '../account/notifications';
import recoverPassword from '../account/recoverPassword';
import updateFollow from '../followers/editFriend';
import loading from '../loading';
import invitation from '../invitation';
import analytics from '../analytics';
import events from '../events';

export default combineReducers({
  configuration,
  analytics,
  invitation,
  loading,
  forgotPassword,
  login,
  recoverPassword,
  updateFollow,
  signup,
  events,
  notifications,
  user,
});
