import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import styles from './styles.module.scss';
import screenHelper from '../../../../utils/screenHelper';

class CollapsableComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      maxCharacters: this.getMaxCharacters(),
    };

    this.changeCollapse = this.changeCollapse.bind(this);
    this.setMaxCharacters = this.setMaxCharacters.bind(this);
    this.setMaxCharactersDecounced = debounce(500, () =>
      this.setMaxCharacters(this.getMaxCharacters())
    );
    window.addEventListener('resize', this.setMaxCharactersDecounced);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    // remember to remove all events to avoid memory leaks
    if (this.mounted) {
      window.removeEventListener('resize', this.setMaxCharactersDecounced);
    }
  }

  /**
   * Sets the maximum visible characters to display when the component is not collapsed.
   * @param maxCharacters Maximum amount of characters that will be displayed.
   */
  setMaxCharacters(maxCharacters) {
    this.setState({
      maxCharacters,
    });
  }

  /**
   * Gets the maximum amount characters that should be displayed when the component is not collapsed for different
   * screen width sizes:
   *  50 for width < 576
   *  300 for width < 768
   *  600 otherwise
   * @return {number} Maximum amount of characters that should be displayed.
   */
  getMaxCharacters() {
    const screenWidth = screenHelper.getScreenWidth();

    if (screenWidth < 576) {
      return 100;
    }
    if (screenWidth < 768) {
      return 300;
    }
    return 600;
  }

  /**
   * Collapses the comment component to display the entire comment message.
   * @param shouldCollapse On true the component will collapse, otherwise will "uncollapse"
   */
  changeCollapse(shouldCollapse) {
    this.setState({
      isCollapsed: shouldCollapse,
    });
  }

  render() {
    const { comment, noLimit, translations } = this.props;
    const { isCollapsed, maxCharacters } = this.state;

    const canBeCollapsed = comment.length >= maxCharacters;
    const textClass = classNames(styles.Text, {
      [styles.Collapsed]: isCollapsed,
    });

    const displayedComment =
      isCollapsed || noLimit ? comment : comment.substring(0, maxCharacters);

    const collapse = () => this.changeCollapse(true);
    const unCollapse = () => this.changeCollapse(false);

    return (
      <div className={styles.Container}>
        <div className={textClass}>
          <div className={styles.Content}>
            {displayedComment}
            {canBeCollapsed && !noLimit && (
              <div
                className={styles.ReadMore}
                onClick={isCollapsed ? unCollapse : collapse}
              >
                {isCollapsed ? translations.readLess : translations.readMore}
              </div>
            )}
          </div>
          {!isCollapsed && !noLimit && canBeCollapsed && (
            <div className={styles.Vanished} />
          )}
        </div>
      </div>
    );
  }
}

CollapsableComment.propTypes = {
  comment: PropTypes.string.isRequired,
  noLimit: PropTypes.bool,
  translations: PropTypes.shape({
    readLess: PropTypes.string,
    readMore: PropTypes.string,
  }),
};

CollapsableComment.defaultProps = {
  /**
   * noLimit: number If true, then the whole comment is displayed and no Read more or Read less options are displayed.
   */
  noLimit: false,
  translations: {
    readLess: 'Read Less',
    readMore: 'Read More',
  },
};

export default CollapsableComment;
