/**
 * Helper class that provides store useful methods.
 */
import { store } from '../index';

class StoreHelper {
  static dispatch(action) {
    store.dispatch(action);
  }

  static getAppState() {
    if (!store) throw new Error('Store is not ready');
    const appState = store.getState();
    if (!appState) throw new Error('Store is not ready');
    return appState;
  }

  static getConfiguration() {
    const appState = StoreHelper.getAppState();
    const { configuration } = appState;
    if (!configuration) throw new Error('Configuration is not ready');
    return configuration;
  }

  static getUserProfile() {
    const appState = StoreHelper.getAppState();
    const { user } = appState;
    if (!user) throw new Error('User profile is not ready');
    const { profile } = user;
    if (!profile) throw new Error('User profile is not ready');
    return profile;
  }
}

export default StoreHelper;
