import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import WatchClickOutside from '../Misc/WatchClickOutside';
import Anchor from '../Misc/Anchor';

/**
 * HOC which enables the wrapped component to open a dropdown menu when clicked.
 * @param WrappedComponent React component to be wrapped
 * @param elements Dropdown menu items
 * @return {*} Wrapped React component
 */
function withDropdown(WrappedComponent, elements) {
  return class extends React.Component {
    static propTypes = {
      children: PropTypes.node,
    };

    static defaultProps = {
      children: null,
    };

    constructor(props) {
      super(props);
      this.state = {
        isDropdownMenuVisible: false,
      };
      this.changeDropdownMenuVisibility = this.changeDropdownMenuVisibility.bind(
        this
      );
    }

    /**
     * Provides accessibility behaviour. Items will call its onClick callback if space or enter key is pressed
     * when they are being focused.
     * @param e event object
     * @param callback function that will be called once the required conditions are met
     */
    onMenuItemKeyPress = (e, callback) => {
      if (e.key === 'Space' || e.key === 'Enter') {
        callback();
      }
    };

    /**
     * Changes dropdown menu visibility.
     * @param isVisible if true then dropdown menu will be visible
     */
    changeDropdownMenuVisibility(isVisible) {
      this.setState({
        isDropdownMenuVisible: isVisible,
      });
    }

    /**
     * Dropdown menu elements are rendered considering:
     * onClick callback functions are attached to the div container tag.
     * On key interaction, onClick callback is used.
     * If it has a link, then it the element container is wrapped with custom anchor component.
     * @return {*} Dropdown menu component
     */
    renderDropdownElements() {
      return (
        <div className={styles.DropdownMenu}>
          {elements.filter(Boolean).map((element) => {
            const hasOnClick = typeof element.onClick === 'function';
            const hasLink = !!element.href;

            const component = (
              <div
                role="menuitem"
                tabIndex="0"
                key={element.text}
                className={classNames(styles.Item, 'tLabel')}
                onClick={hasOnClick ? element.onClick : () => true}
                onKeyPress={
                  hasOnClick
                    ? (e) => this.onMenuItemKeyPress(e, element.onClick)
                    : () => true
                }
              >
                {element.text}
              </div>
            );

            if (hasLink) {
              return (
                <Anchor href={element.href} key={element.href}>
                  {component}
                </Anchor>
              );
            }
            return component;
          })}
        </div>
      );
    }

    render() {
      const { isDropdownMenuVisible } = this.state;
      const { children } = this.props;
      return (
        <WatchClickOutside
          onClickOutside={() => this.changeDropdownMenuVisibility(false)}
        >
          <div
            role="presentation"
            className={styles.NavigationDropdown}
            onClick={() =>
              this.changeDropdownMenuVisibility(!isDropdownMenuVisible)
            }
            onKeyPress={(e) =>
              this.onMenuItemKeyPress(
                e,
                this.changeDropdownMenuVisibility(!isDropdownMenuVisible)
              )
            }
          >
            <WrappedComponent {...this.props}>{children}</WrappedComponent>
            {isDropdownMenuVisible &&
              elements.length &&
              this.renderDropdownElements()}
          </div>
        </WatchClickOutside>
      );
    }
  };
}

export default withDropdown;
