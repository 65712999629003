import update from 'immutability-helper';
import createAction from './utils/createAction';

const REQUEST_USER_PROFILE = 'REQUEST_USER_PROFILE';
const RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE';
const HANDLE_USER_PROFILE_ERROR = 'HANDLE_USER_PROFILE_ERROR';
const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const requestUserProfileAction = createAction(REQUEST_USER_PROFILE);
export const recieveUserProfileAction = createAction(
  RECEIVE_USER_PROFILE,
  'response'
);
export const handleUserProfileErrorAction = createAction(
  HANDLE_USER_PROFILE_ERROR,
  'error'
);
export const setUserProfileAction = createAction(
  SET_USER_PROFILE,
  'userProfile'
);

const initialState = {
  profile: [],
  isAuthenticated: false,
  isFetching: true,
};

export default (state = initialState, action) => {
  const { type, response, error } = action;
  switch (type) {
    case REQUEST_USER_PROFILE:
      return update(state, { isFetching: { $set: true } });
    case RECEIVE_USER_PROFILE:
      return update(state, {
        profile: { $set: response },
        isAuthenticated: { $set: true },
        isFetching: { $set: false },
      });
    case HANDLE_USER_PROFILE_ERROR:
      return update(state, {
        error: { $set: error },
        isAuthenticated: { $set: false },
        isFetching: { $set: false },
      });
    case SET_USER_PROFILE:
      return update(state, {
        profile: { $set: action.userProfile },
        isAuthenticated: { $set: true },
        isFetching: { $set: false },
      });
    default:
      return state;
  }
};
