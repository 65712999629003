import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './RadioInput.module.scss';

class RadioInput extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { onChange } = this.props;
    onChange(event);
    this.setState({ value: event.target.value });
  }

  renderOptions() {
    const { options, name } = this.props;
    const { value } = this.state;

    return options.map((option) => (
      <li key={option.id} className={styles.OptionContainer}>
        <input
          type="radio"
          id={option.id}
          name={name}
          value={option.value}
          defaultChecked={option.value === value}
          className={styles.Option}
          onChange={this.onChange}
        />
        <label htmlFor={option.id}>{option.name}</label>
      </li>
    ));
  }

  render() {
    const { id, className, title, isInline } = this.props;

    return (
      <div className={classnames(styles.Container, className)}>
        <label
          htmlFor={id}
          className={classnames(
            'tLabel',
            { [styles.LabelInline]: isInline },
            { [styles.Label]: !isInline }
          )}
        >
          {title}
        </label>

        <ul className={styles.OptionsContainer}>{this.renderOptions()}</ul>
      </div>
    );
  }
}

RadioInput.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
};

RadioInput.defaultProps = {
  options: [],
};

export default RadioInput;
