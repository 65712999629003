import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ModalContainer.module.scss';
import * as Button from '../Buttons';
import LoadingOverlay from '../Misc/LoadingOverlay';
import WatchClickOutside from '../Misc/WatchClickOutside';

export const MODAL_TYPES = {
  STAMPS: 'Stamps',
  FOLLOWERS: 'Followers',
};

const ModalContainer = ({
  modalType,
  header,
  content,
  footer,
  isLoading,
  onClose,
  className,
}) => {
  const hasFooter = footer !== null;
  return (
    <div className={[styles.Background].join(' ')}>
      <WatchClickOutside onClickOutside={onClose}>
        <div
          className={classnames(styles.Container, styles[modalType], className)}
        >
          <div className={styles.Header}>
            {header}
            <div className={styles.CloseButton}>
              <Button.Close onClick={onClose} />
            </div>
          </div>
          {isLoading ? (
            <LoadingOverlay relativeToContainer />
          ) : (
            <React.Fragment>
              <div className={styles.Content}>{content}</div>
              {hasFooter ? <div className={styles.Footer}>{footer}</div> : null}
            </React.Fragment>
          )}
        </div>
      </WatchClickOutside>
    </div>
  );
};

ModalContainer.propTypes = {
  header: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

ModalContainer.defaultProps = {
  header: null,
  content: null,
  footer: null,
  className: null,
  onClose: () => {},
  isLoading: false,
};

export default ModalContainer;
