/**
 * Helper class that provides string related methods.
 */
export default class TranslationsQuantity {
  /**
   * Handles singular or plural string cases. (e.g, 1 moment, 0 moments, 2 moments)
   * @param {number} quantity - for singular cases use 1, all other numbers will be treated as plural cases.
   * @param {string} singularPhrase - phrase that will be used for the singular case.
   * @param {string} pluralPhrase - phrase that will be used for the plural case.
   * @param {string} placeholder - if the provided phrases contain a placeholder, please specify it in here (e.g. %d)
   * @returns string with the quantity appended to the appropriate phrase using the singular or plural phrase.
   */
  static getQuantityPhrase(
    quantity,
    singularPhrase,
    pluralPhrase,
    placeholder
  ) {
    let phrase = '';
    if (placeholder) {
      if (quantity !== 1) {
        phrase = pluralPhrase.replace(placeholder, quantity);
      } else {
        phrase = singularPhrase.replace(placeholder, quantity);
      }
    } else if (quantity !== 1) {
      phrase = `${quantity} ${pluralPhrase}`;
    } else {
      phrase = `${quantity} ${singularPhrase}`;
    }

    return phrase;
  }
}
