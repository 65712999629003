export default {
  firebase: {
    apiKey: 'AIzaSyCrIx9E9FUOwHt4NrYhRAzqWPiIxpgIuFQ',
    authDomain: 'journiapp-web.firebaseapp.com',
    databaseURL: 'https://journiapp-web.firebaseio.com',
    projectId: 'journiapp-web',
    storageBucket: 'journiapp-web.appspot.com',
    messagingSenderId: '1033487503810',
    appId: '1:1033487503810:web:0c87864b4ec1ed3c',
  },
};
