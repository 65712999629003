/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import getReactions from '../../helpers/ReactionsList';
import Styles from './Styles.module.scss';
import TranslationsQuantity from '../../../../helpers/TranslationsQuantity';

/**
 * Component
 */
const ToTalReactions = ({
  likeCount,
  top1LikeId,
  top2LikeId,
  translatedMessages,
}) => {
  const iconOne = top1LikeId ? getReactions(top1LikeId) : null;
  const iconTwo = top2LikeId ? getReactions(top2LikeId) : null;

  if (!likeCount) return null;

  return (
    <div className={Styles.ToTalReactions}>
      {iconOne && (
        <img
          className={Styles.IconOne}
          src={iconOne.urlIcon}
          alt={iconOne.name}
        />
      )}

      {iconTwo && (
        <img
          className={Styles.IconTwo}
          src={iconTwo.urlIcon}
          alt={iconTwo.name}
        />
      )}

      {likeCount ? (
        <p className={classNames('tCaptionDark', Styles.TotalCount)}>
          {TranslationsQuantity.getQuantityPhrase(
            likeCount,
            translatedMessages('main.1_like', likeCount),
            translatedMessages('main.n_likes', likeCount)
          )}
        </p>
      ) : null}
    </div>
  );
};

/**
 * PropTypes
 */
ToTalReactions.propTypes = {
  likeCount: PropTypes.number,
  top1LikeId: PropTypes.number,
  top2LikeId: PropTypes.number,
  translatedMessages: PropTypes.func.isRequired,
};

ToTalReactions.defaultProps = {
  /**
   * Total reactions
   */
  likeCount: null,
  /**
   *  Most used like id
   */
  top1LikeId: null,
  /**
   * Second most used like id
   */
  top2LikeId: null,
};

/**
 * Expose Component
 */
export default ToTalReactions;
