import update from 'immutability-helper/index';
import Http from '../utils/Http';
import FacebookPixel from '../common/components/Tracking/FacebookPixel';
import GoogleAnalytics from '../common/components/Tracking/GoogleAnalytics';
import { initTracker } from './analytics';
import { store } from '../index';
import { translate } from '../common/components/Misc/Translate';
import { setNotificationsAction } from './account/notifications';
import { setUserProfileAction } from './user';
import createAction from './utils/createAction';

// Configuration fetch actions
const REQUEST_CONFIGURATION = 'REQUEST_CONFIGURATION';
const RECEIVE_CONFIGURATION = 'RECEIVE_CONFIGURATION';
const HANDLE_CONFIGURATION_ERROR = 'HANDLE_CONFIGURATION_ERROR';
const SET_CONFIGURATION_READY = 'SET_CONFIGURATION_READY';

const setConfigurationReadyAction = createAction(
  'SET_CONFIGURATION_READY',
  'isConfigurationReady'
);

// Trackers that will be subscribed to the Analytics component
const trackers = [new FacebookPixel(), new GoogleAnalytics()];

function hasDeviceTouchSupport() {
  /*
        Taken from https://stackoverflow.com/a/4819886
        Works on most of the cases, however false positive ones have been reported.
    */
  return !!(
    ('ontouchstart' in window || navigator.maxTouchPoints) // works on most browsers
  ); // works on IE10/11 and Surface
}

const initialState = {
  trackers,
  fbAppId: '',
  fbChannel: '',
  branchKey: '',
  langCode: '',
  isLocal: false,
  isLocalOrStage: false,
  mapbox: {},
  messages: [],
  paths: [],
  isFetching: true,
  translatedMessages: null,
  hasDeviceTouchSupport: hasDeviceTouchSupport(),
  isConfigurationReady: false,
};

export function fetchConfiguration() {
  return {
    types: [
      REQUEST_CONFIGURATION,
      RECEIVE_CONFIGURATION,
      HANDLE_CONFIGURATION_ERROR,
    ],
    // TODO: add error handlers in configuration fetching
    callAPI: () => Http.get('/api/v8.0/configuration'),
    onSuccessCallback: (response) => {
      // TODO: We can improve the way we are defining whether the tracking will be activated or not
      store.dispatch(
        initTracker(!response.isLocalOrStage, response.isLocalOrStage)
      );

      /**
       * Set userData configuarion for NON anonymous users.
       */
      if (response.userProfile && response.userProfile.email) {
        store.dispatch(setUserProfileAction(response.userProfile));
        store.dispatch(setNotificationsAction(response.notifications));
      }

      store.dispatch(setConfigurationReadyAction(true));
    },
  };
}

export function reducer(state = initialState, action) {
  const { type, response } = action;
  switch (type) {
    case REQUEST_CONFIGURATION:
      return update(state, { isFetching: { $set: true } });
    // Please check the mapping in backend: getConfiguration.
    case RECEIVE_CONFIGURATION:
      return update(state, {
        fbAppId: { $set: response.fbAppId }, // Used to initialize facebook sdk
        fbChannel: { $set: response.fbChannel }, // Used to initialize facebook sdk
        branchKey: { $set: response.branchKey }, // Used to initialize branch SDK
        langCode: { $set: response.langCode }, // // Used to initialize facebook sdk
        isLocal: { $set: response.isLocal }, // Used to avoid tracking on local environments
        isLocalOrStage: { $set: response.isLocalOrStage }, // Used to avoid tracking on local environments
        mapbox: { $set: response.mapbox }, // Used to initialize the mapbox component
        messages: { $set: response.messages },
        translatedMessages: { $set: translate(response.messages) },
        paths: { $set: response.paths },
        isFetching: { $set: false },
      });
    case HANDLE_CONFIGURATION_ERROR:
      return update(state, {
        error: { $set: action.error },
        isFetching: { $set: false },
      });
    case SET_CONFIGURATION_READY:
      return update(state, { isConfigurationReady: { $set: true } });
    default:
      return state;
  }
}
