import connect from 'react-redux/es/connect/connect';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styles from './NotificationsPage.module.scss';
import Container from '../../../common/components/Containers/Container';
import DropdownNotificationItem from '../../../common/components/Navigation/Notifications/NotificationItem';
import LoadingPage from '../LoadingPage/LoadingPage';
import NotificationsHelper from '../../../helpers/Notifications';

/**
 *  Fetches and displays trips.
 */
class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
    };
    this.renderNotifications = this.renderNotifications.bind(this);
  }

  componentDidMount() {
    NotificationsHelper.getNotifications().then(() =>
      this.setState({
        isFetching: false,
      })
    );
  }

  renderNotifications() {
    const { notifications, translatedMessages } = this.props;
    const isNotificationsEmpty =
      notifications === undefined || notifications.length === 0;
    if (!isNotificationsEmpty) {
      return notifications.map((notification, i) => (
        <div key={i} className="mt3">
          <DropdownNotificationItem notification={notification} />
        </div>
      ));
    }
    return <h2>{translatedMessages('notifications.empty.notifications')}</h2>;
  }

  render() {
    const { translatedMessages } = this.props;
    const { isFetching } = this.state;

    if (isFetching) {
      return <LoadingPage isLoadingScreenVisible />;
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Notifications - Journi</title>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <div className={[styles.NotificationsPage, 'px2'].join(' ')}>
          <Container withVerticalMargin>
            <h1 className={[styles.Title, 'mb3'].join(' ')}>
              {translatedMessages('main.notifications')}
            </h1>
            <div className={styles.Notifications}>
              {this.renderNotifications()}
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  notifications: state.notifications.notifications,
});

export default connect(mapStateToProps, null)(NotificationsPage);
