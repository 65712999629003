import React from 'react';
import PropTypes from 'prop-types';
import styles from './Container.module.scss';

const Container = ({
  fluidToRight,
  isFullWidth,
  withVerticalMargin,
  className,
  withHorizontalPadding,
  children,
}) => {
  let classNames = styles.Container;

  if (fluidToRight) {
    classNames = ` ${styles.FluidToRight}`;
  } else if (isFullWidth) {
    classNames += ` ${styles.FullWidthContainer}`;
  } else if (withVerticalMargin) {
    classNames += ` ${styles.WithVerticalMargin}`;
  } else if (withHorizontalPadding) {
    classNames += ` ${styles.WithHorizontalPadding}`;
  }
  classNames += ` ${className}`;

  return <div className={classNames}>{children}</div>;
};

Container.propTypes = {
  /*
    fluidToRight: boolean, true makes the container to take full width from the center position to rightmost side
  */
  fluidToRight: PropTypes.bool,
  /*
    isFullWidth: boolean, true makes the container to take full width (no horizontal margin).
  */
  isFullWidth: PropTypes.bool,
  /*
    withVerticalMargin: boolean, adds vertical margin.
  */
  withVerticalMargin: PropTypes.bool,
  /*
    withHorizontalPadding: boolean, adds horizontal padding.
  */
  withHorizontalPadding: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Container.defaultProps = {
  fluidToRight: false,
  isFullWidth: false,
  withVerticalMargin: false,
  withHorizontalPadding: false,
  className: '',
  children: null,
};

export default Container;
