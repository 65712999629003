import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import styles from './MyTrips.module.scss';
import { JourniCardContainer } from '../../common/components/Cards/Card';
import {
  getImageURL,
  IMAGE_FORMAT_TYPE_MOBILE_RET,
} from '../../utils/urlHelpers';
import { getCSSColor } from '../../utils/converters';
import Container from '../../common/components/Containers/Container';

class MyTrips extends Component {
  renderCollections() {
    const {
      paths,
      trips,
      translatedMessages,
      isOwnProfile,
      fullUserName,
    } = this.props;
    const { picture } = paths;
    const hasTrips = trips !== undefined && trips.length > 0;

    let noTripsMessage = '';

    if (hasTrips) {
      return trips.map((trip) => (
        <JourniCardContainer
          key={trip.id}
          title={trip.title}
          places={trip.places}
          entryCount={trip.entryCount}
          backgroundImageUrl={getImageURL(
            picture,
            trip.pictureGuid,
            IMAGE_FORMAT_TYPE_MOBILE_RET
          )}
          backgroundColor={getCSSColor(trip.pictureColor)}
          url={`${window.location.origin}/j/${trip.url}`}
          textColor={getCSSColor(trip.titleColor)}
          useGridColumn
        />
      ));
    }
    if (isOwnProfile) {
      noTripsMessage = translatedMessages('profile.user.empty_trips');
    } else {
      noTripsMessage = translatedMessages('profile.friend.empty_trips').replace(
        '{p1}',
        fullUserName
      );
    }

    return <div className="tLabel">{noTripsMessage}</div>;
  }

  render() {
    return (
      <div className={styles.Content}>
        <Container>
          <div className={styles.Collections}>
            <Row>{this.renderCollections()}</Row>
          </div>
        </Container>
      </div>
    );
  }
}

MyTrips.propTypes = {
  /*
    userId: integer, used to detect if a new profile page was loaded.
  */
  userId: PropTypes.number.isRequired,
};

MyTrips.defaultProps = {};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyTrips);
