const ALPHA = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const MAX_CHARS = ALPHA.length;

/**
 * Generates a 16-character long random string that can be used to
 * uniqly identify a resource.
 */
// eslint-disable-next-line import/prefer-default-export
export function generateGuid() {
  const seconds = new Date().getTime();
  let minutes = (seconds / 60) % (62 * 62 * 62 * 62);
  let randomString = '';
  for (let i = 3; i >= 0; i -= 1) {
    const ch = ALPHA[Math.floor(minutes % MAX_CHARS)];
    randomString += ch;
    minutes /= MAX_CHARS;
  }

  for (let i = 4; i < 16; i += 1) {
    const index = Math.floor(Math.random() * MAX_CHARS);
    randomString += ALPHA[index];
  }

  return randomString;
}
