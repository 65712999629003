import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import CollapsableComment from '../CollapsableComment';
import * as Button from '../../../../common/components/Buttons';

/**
 * Trip Entry Slide. Displayed in the fullscreen trip slideshow component.
 */
const TripEntrySlide = ({
  categoryName,
  categoryIcon,
  date,
  time,
  comment,
  image,
  children,
  slideNumber,
  slidesNumber,
  fullBackground,
  onClose,
  onHideControls,
  hideControls,
}) => {
  const visibilityStyle = hideControls ? { visibility: 'hidden' } : null;

  const header = (
    <div className={styles.Header}>
      <div className={styles.HeaderContent}>
        <div className={styles.HeaderControls}>
          <Button.ChangeVisibility
            isVisible={!hideControls}
            onClick={onHideControls}
          />
        </div>
        <div className={styles.Date} style={visibilityStyle}>
          <span className="icon icon-calendar" />
          {date}
        </div>
        <div className={styles.Category} style={visibilityStyle}>
          <span className={`icon ${categoryIcon}`} />
          {categoryName}{' '}
          {slidesNumber > 0 && ` ${slideNumber} / ${slidesNumber} `}
        </div>
        <div className={styles.Time} style={visibilityStyle}>
          <span className="icon icon-clock" />
          {time}
        </div>
        <div className={styles.HeaderControls}>
          <Button.Close onClick={onClose} noStyleChange />
        </div>
      </div>
      <div className={styles.HeaderOverlay} style={visibilityStyle} />
    </div>
  );

  if (image) {
    const backgroundStyle = {
      background: `url('${image}') no-repeat center center / contain`,
    };
    const commentComponent = !hideControls ? (
      <div className={styles.CommentOnPictureWrapper}>
        <CollapsableComment comment={comment} />
      </div>
    ) : null;

    return (
      <div
        className={styles.Container}
        style={fullBackground ? backgroundStyle : null}
      >
        {header}
        {comment ? commentComponent : null}
        <div
          className={styles.WrapperPicture}
          style={fullBackground ? null : backgroundStyle}
        />
      </div>
    );
  }

  if (comment) {
    return (
      <div className={styles.Container}>
        {header}
        <div className={styles.CommentWrapper}>
          <CollapsableComment comment={comment} noLimit />
        </div>
      </div>
    );
  }

  if (children) {
    return (
      <div className={styles.ComponentWrapper}>
        {header}
        {children}
      </div>
    );
  }

  return null;
};

TripEntrySlide.propTypes = {
  /*
   * Comment that will be displayed (if any) in the slide.
   */
  comment: PropTypes.string,
  /*
   * URL of the background picture.
   */
  image: PropTypes.string,
  /*
   * Date of the entry.
   */
  date: PropTypes.string.isRequired,
  /*
   * UTime of the entry.
   */
  time: PropTypes.string.isRequired,
  /*
   * Name of the category of the entry.
   */
  categoryName: PropTypes.string,
  /*
   * Name of CSS Icon that will be displayed next to the category name.
   */
  categoryIcon: PropTypes.string,
  /*
   * Number of the slide within the moment (e.g. 5).
   */
  slideNumber: PropTypes.number,
  /*
   * Total number of slides of this entry.
   */
  slidesNumber: PropTypes.number,
  /*
   * If true, the background picture will be fitted to entire container.
   */
  fullBackground: PropTypes.bool,
  /*
   * If true, all controls will be hidden.
   */
  hideControls: PropTypes.bool,
  /*
   * If true, the background picture will be fitted to entire container.
   */
  onHideControls: PropTypes.func,
  /*
   * If true, the background picture will be fitted to entire container.
   */
  onClose: PropTypes.func,
};

TripEntrySlide.defaultProps = {
  comment: '',
  image: '',
  categoryName: '',
  categoryIcon: '',
  slideNumber: 0,
  slidesNumber: 0,
  fullBackground: false,
  hideControls: false,
  onHideControls: () => true,
  onClose: () => true,
};

export default TripEntrySlide;
