// Configuration fetch actions
import update from 'immutability-helper';
import FacebookPixel, {
  FACEBOOK_TRACKER_NAME,
  FB_PIXEL_EVENT_NAME_ADDED_TO_WISHLIST,
  FB_PIXEL_EVENT_NAME_COMPLETED_REGISTRATION,
  FB_PIXEL_EVENT_NAME_INITIATED_CHECKOUT,
  FB_PIXEL_EVENT_NAME_VIEWED_CONTENT,
} from '../common/components/Tracking/FacebookPixel';
import GoogleAnalytics from '../common/components/Tracking/GoogleAnalytics';
import createAction from './utils/createAction';
import {
  ANALYTICS_LABEL_ADD_FIRST_MOMENT,
  ANALYTICS_LABEL_PREVIEW_SUBSCRIPTION,
  ANALYTICS_LABEL_SELECT_,
  ANALYTICS_LABEL_SIGNUP_SUCCESSFUL,
} from '../utils/constants/eventConstants';

/*
 * Analytics enables handling tracking related events using redux.
 * */

const INIT_TRACKER = 'INIT_TRACKER';
const TRACK_EVENT = 'TRACK_EVENT';
const LOG_PAGE_CHANGE = 'LOG_PAGE_CHANGE';

const actionInitTracker = createAction(
  INIT_TRACKER,
  'shouldTrack',
  'shouldLog'
);
const actionTrackEvent = createAction(
  TRACK_EVENT,
  'eventCategory',
  'eventAction',
  'eventLabel',
  'eventProperties',
  'callback',
  'isImportant'
);
const actionLogPageChange = createAction(LOG_PAGE_CHANGE, 'pathname', 'search');

const initialState = {
  trackers: [],
  shouldTrack: false,
  shouldLog: false,
};

/**
 * Dispatches the TRACK EVENT action.
 * isImportant: if it is false, then all trackers will be used for tracking this action, otherwise it will be
 *             just tracked by those that were chosen to track important events.
 */
export function triggerEventAction(
  eventCategory,
  eventAction,
  eventLabel,
  eventProperties,
  callback,
  isImportant
) {
  return (dispatch) => {
    dispatch(
      actionTrackEvent(
        eventCategory,
        eventAction,
        eventLabel,
        eventProperties,
        callback,
        isImportant
      )
    );
  };
}

/**
 * Dispatches the LOG PAGE CHANGE action.
 */
export function logPageChange(pathname, search) {
  return (dispatch) => {
    dispatch(actionLogPageChange(pathname, search));
  };
}

export function initTracker(shouldTrack, shouldLog) {
  return (dispatch) => {
    dispatch(actionInitTracker(shouldTrack, shouldLog));
  };
}

function trackEvent(event, trackers) {
  const {
    eventCategory,
    eventAction,
    eventLabel,
    eventProperties,
    callback,
    isImportant,
  } = event;
  trackers
    .filter((tracker) => tracker.name !== FACEBOOK_TRACKER_NAME) // Only important events are tracked with facebook pixel
    .forEach((tracker) =>
      tracker.triggerEventAction(
        eventCategory,
        eventAction,
        eventLabel,
        eventProperties,
        callback
      )
    );

  if (isImportant) {
    // Track with facebook
    const fbTracker = trackers
      .filter((tracker) => tracker.name === FACEBOOK_TRACKER_NAME)
      .shift();
    fbTracker.triggerEventAction(eventCategory, null, eventLabel, null, null);

    const fbLabel = (() => {
      switch (eventLabel) {
        // case ANALYTICS_LABEL_OPEN_APP: return FB_PIXEL_EVENT_NAME_ACTIVATED_APP;
        case ANALYTICS_LABEL_SIGNUP_SUCCESSFUL:
          return FB_PIXEL_EVENT_NAME_COMPLETED_REGISTRATION;
        case ANALYTICS_LABEL_ADD_FIRST_MOMENT:
          return FB_PIXEL_EVENT_NAME_ADDED_TO_WISHLIST;
        case ANALYTICS_LABEL_PREVIEW_SUBSCRIPTION:
          return FB_PIXEL_EVENT_NAME_VIEWED_CONTENT;
        case ANALYTICS_LABEL_SELECT_:
          return FB_PIXEL_EVENT_NAME_INITIATED_CHECKOUT;
        // case ANALYTICS_VALUE_SCREEN_LOGIN_CHOICE: return FB_PIXEL_EVENT_NAME_COMPLETED_TUTORIAL
        default:
          return null;
      }
    })();

    if (fbLabel !== null) {
      fbTracker.triggerEventAction(
        eventCategory,
        null,
        fbLabel,
        null,
        callback
      );
    }
  }
}

export default function reducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case INIT_TRACKER:
      // Trackers that will be subscribed to the Analytics component
      const trackers = [new FacebookPixel(), new GoogleAnalytics()];
      trackers.forEach((tracker) =>
        tracker.init(action.shouldTrack, action.shouldLog)
      );
      return update(state, {
        trackers: { $set: trackers },
        shouldLog: { $set: action.shouldLog },
        shouldTrack: { $set: action.shouldTrack },
      });
    case TRACK_EVENT:
      trackEvent(action, state.trackers);
      return state;
    case LOG_PAGE_CHANGE:
      state.trackers.forEach((tracker) =>
        tracker.logPageChange(action.pathname, action.search)
      );
      return state;
    default:
      return state;
  }
}
