import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import ModalContainer from '../ModalContainer';
import styles from './DeleteAccountModal.module.scss';
import * as journiAPI from '../../../../network/journiAPI';
import * as Button from '../../Buttons';
import { BUTTON_SIZES } from '../../Buttons';
import ValidationBox, { BOX_TYPES } from '../../Boxes/ValidationBox';

class DeleteAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      callbackMessage: '',
      failedRequest: false,
      successRequest: false,
    };

    this.doDeleteAccount = this.doDeleteAccount.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  resetState() {
    this.setState({
      isFetching: false,
      callbackMessage: '',
      failedRequest: false,
      successRequest: false,
    });
  }

  doDeleteAccount() {
    const { paths } = this.props;
    this.setState({
      isFetching: true,
      callbackMessage: '',
      failedRequest: false,
      successRequest: false,
    });

    journiAPI.postDeleteAccount().then(
      (response) => {
        this.setState({
          isFetching: false,
          callbackMessage: response.status,
          successRequest: true,
        });
        window.location.replace(paths.landingPage);
      },
      (error) => {
        this.setState({
          isFetching: false,
          callbackMessage: error.status,
          failedRequest: true,
        });
      }
    );
  }

  renderCallbackMessage(callbackBoxType, callbackMessage) {
    if (callbackMessage) {
      return (
        <ValidationBox boxType={callbackBoxType}>
          {callbackMessage}
        </ValidationBox>
      );
    }
    return null;
  }

  render() {
    const { onClose, isVisible, translatedMessages } = this.props;
    const { failedRequest, successRequest, isFetching } = this.state;

    const callbackBoxType = failedRequest
      ? BOX_TYPES.FAILURE
      : BOX_TYPES.SUCCESS;
    const { callbackMessage } = this.state;

    const header = (
      <React.Fragment>
        {translatedMessages('settings.delete_account')}
      </React.Fragment>
    );

    const content = (
      <React.Fragment>
        {translatedMessages('settings.delete_account.message')}
        {this.renderCallbackMessage(callbackBoxType, callbackMessage)}
      </React.Fragment>
    );

    const footer = (
      <div className={styles.Footer}>
        <Button.Secondary
          buttonSize={BUTTON_SIZES.MEDIUM_SMALL}
          disabled={isFetching || successRequest}
          onClick={this.doDeleteAccount}
        >
          {translatedMessages('settings.delete_account')}
        </Button.Secondary>

        <Button.Primary
          buttonSize={BUTTON_SIZES.MEDIUM_SMALL}
          disabled={isFetching}
          onClick={onClose}
        >
          {translatedMessages('main.cancel')}
        </Button.Primary>
      </div>
    );

    return (
      <ModalContainer
        isVisible={isVisible}
        header={header}
        content={content}
        footer={footer}
        onClose={() => {
          this.resetState();
          onClose();
        }}
      />
    );
  }
}

DeleteAccountModal.propTypes = {
  /**
   *  onClose. Function called when the modal is closed.
   */
  onClose: PropTypes.func,
  /**
   *  isVisible. If true modal will be visible.
   */
  isVisible: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountModal);
