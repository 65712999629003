/**
 * Get URL static for icons weather
 */
import { getAssetURL } from '../../../../utils/urlHelpers';

export default (weatherId) => {
  const URL = getAssetURL('/icons/weather/');
  const IMG_EXTENSION = '.png';
  let type = '';

  switch (weatherId) {
    case 1:
      type = 'sunny';
      break;
    case 2:
      type = 'party_cloudy';
      break;
    case 3:
      type = 'cloudy';
      break;
    case 4:
      type = 'rainy';
      break;
    case 5:
      type = 'thunder';
      break;
    case 6:
      type = 'snowy';
      break;
    default:
      type = 'sunny';
  }

  return URL + type + IMG_EXTENSION;
};
