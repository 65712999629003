/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles.module.scss';

/**
 * Component
 */
const DateSeparator = ({
  showDate,
  date,
  showTimezone,
  timezone,
  children,
}) => (
  <div>
    <div className={Styles.Row}>
      {showDate ? <h4 className={Styles.Date}>{date}</h4> : null}

      <span className={Styles.DateLine} />
    </div>
    {showTimezone ? <p className={Styles.Timezone}>{timezone}</p> : null}
    {children}
  </div>
);

/**
 * PropTypes
 */
DateSeparator.propTypes = {
  showDate: PropTypes.bool,
  children: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  showTimezone: PropTypes.bool,
};

DateSeparator.defaultProps = {
  showDate: false,
  timezone: null,
  showTimezone: false,
};

/**
 * Expose Component
 */
export default DateSeparator;
