import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './UserAvatar.module.scss';
import Anchor from '../Misc/Anchor';

export const AVATAR_TYPES = {
  SUPER_LARGE: 'SuperLarge',
  LARGE: 'Large',
  MEDIUM: 'Medium',
  SMALL: 'Small',
  AUTHOR: 'Author',
};

const UserAvatar = ({
  pictureURL,
  userProfileURL,
  backgroundColor,
  type,
  withBorder,
}) => {
  const headerStyle = {
    backgroundColor,
    backgroundImage: `url("${pictureURL}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const hasLink = !!userProfileURL;

  if (hasLink) {
    return (
      <Anchor href={userProfileURL}>
        <div
          className={classNames(styles.Container, styles[type], {
            [styles.WithBorder]: withBorder,
          })}
          style={headerStyle}
        >
          <div className={styles.Overlay} />
        </div>
      </Anchor>
    );
  }
  return (
    <div
      className={classNames(styles.Container, styles[type])}
      style={headerStyle}
    >
      <div className={styles.Overlay} />
    </div>
  );
};

UserAvatar.propTypes = {
  /*
   * URL of the picture that will be displayed as the user's avatar.
   */
  pictureURL: PropTypes.string.isRequired,
  /*
   * User's profile link
   */
  userProfileURL: PropTypes.string,
  /*
   * Background color that will be displayed as fallback in case the picture URL is not available.
   */
  backgroundColor: PropTypes.string.isRequired,
  /*
   * Type of user avatar. Check AVATAR_TYPES constant to see the available ones.
   */
  type: PropTypes.oneOf(Object.entries(AVATAR_TYPES).map((a) => a[1])),
  /*
   * If true, draws a border around the avatar.
   */
  withBorder: PropTypes.bool,
};

UserAvatar.defaultProps = {
  userProfileURL: '',
  type: AVATAR_TYPES.MEDIUM,
  withBorder: false,
};

export default UserAvatar;
