import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import ModalContainer from '../ModalContainer';
import BackgroundLine from '../../Misc/BackgroundLine';
import CopyBox from '../../Misc/CopyBox';
import { ShareEmail, ShareFacebook, ShareTwitter } from '../../Buttons';

/**
 * Modal component used to enable the user to share content through social media or by using hyperlinks.
 */
const ShareModal = ({
  shareLink,
  translations,
  onClose,
  onFacebookShare,
  onTwitterShare,
  onEmailShare,
}) => {
  const header = <h2>{translations.title}</h2>;

  const content = (
    <React.Fragment>
      <div className="tCaptionDark">{translations.shareDescriptionPhrase}</div>

      <div className="mt2">
        <CopyBox
          text={shareLink}
          copyPhrase={translations.copyPhrase}
          copiedPhrase={translations.copiedPhrase}
        />
      </div>

      <BackgroundLine className="tCaptionDark my2">
        {translations.orPhrase}
      </BackgroundLine>
      <div className="tCaptionDark">{translations.shareViaPhrase}</div>

      <div className={classNames(styles.ShareMethods, 'mt2')}>
        <ShareFacebook onClick={onFacebookShare} />
        <ShareTwitter onClick={onTwitterShare} />
        <ShareEmail onClick={onEmailShare} />
      </div>
    </React.Fragment>
  );

  return (
    <ModalContainer
      header={header}
      content={content}
      isLoading={false}
      onClose={onClose}
    />
  );
};

ShareModal.propTypes = {
  /*
   * Generated link used to share the trip / moment
   */
  shareLink: PropTypes.string.isRequired,
  /*
   * Translations used in this component
   */
  translations: PropTypes.shape({
    title: PropTypes.string.isRequired,
    shareDescriptionPhrase: PropTypes.string.isRequired,
    shareViaPhrase: PropTypes.string.isRequired,
    orPhrase: PropTypes.string.isRequired,
    copyPhrase: PropTypes.string.isRequired,
    copiedPhrase: PropTypes.string.isRequired,
  }),
  /*
   * Callback function for action on facebook share button.
   */
  onFacebookShare: PropTypes.func.isRequired,
  /*
   * Callback function for action on twitter share button.
   */
  onTwitterShare: PropTypes.func.isRequired,
  /*
   * Callback function for action on email share button.
   */
  onEmailShare: PropTypes.func.isRequired,
  /*
   * Callback function for action after closing the modal.
   */
  onClose: PropTypes.func.isRequired,
};

ShareModal.defaultProps = {
  translations: {
    title: 'Share your Journi',
    shareDescriptionPhrase:
      'Anybody with this link will be able to see your Journi',
    shareViaPhrase: 'Share via',
    orPhrase: 'OR',
  },
};

export default ShareModal;
