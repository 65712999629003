import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import Map from './Map';
import { MapEntry } from '../../../network/models';

/**
 * MapContainer, container component. It uses react-mapbox-gl library for rendering markers in the MapBox map.
 */
const MapContainer = ({
  entries,
  containerStyle,
  onFullScreenClick,
  focusedEntry,
  interactive,
  onPinClick,
  mapbox,
}) => {
  let activeEntry = null;

  if (focusedEntry !== null) {
    activeEntry = focusedEntry;
  }

  return (
    <Map
      accessToken={mapbox.token}
      entries={entries}
      activeEntry={activeEntry}
      onFullScreenClick={onFullScreenClick}
      containerStyle={containerStyle}
      interactive={interactive}
      onPinClick={onPinClick}
    />
  );
};

MapContainer.propTypes = {
  entries: PropTypes.arrayOf(MapEntry),
  onFullScreenClick: PropTypes.func,
  onPinClick: PropTypes.func,
  focusedEntry: PropTypes.number,
  interactive: PropTypes.bool,
};

MapContainer.defaultProps = {
  entries: [],
  onFullScreenClick: () => true,
  onPinClick: () => true,
  focusedEntry: null,
  interactive: true,
};

const mapStateToProps = (state) => ({
  mapbox: state.configuration.mapbox,
});

export default connect(mapStateToProps, null)(MapContainer);
