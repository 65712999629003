import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './MapPopup.module.scss';

const MapPopup = ({ pictureURL, text, date, time }) => {
  const pictureStyle = {
    backgroundImage: `url("${pictureURL}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  return (
    <div className={styles.Wrapper}>
      {pictureURL ? (
        <div style={pictureStyle} className={styles.Picture} />
      ) : null}
      <div className={styles.Content}>
        <div className={classNames(styles.Date, 'tCaption')}>
          {`${date} ${time}`}
        </div>
        {text ? (
          <div className={classNames(styles.Text, 'tLabel')}>{text}</div>
        ) : null}
      </div>
    </div>
  );
};

MapPopup.propTypes = {
  /*
   * URL of the picture that will be displayed in the popup.
   */
  pictureURL: PropTypes.string,
  /*
   * Date text.
   */
  date: PropTypes.string.isRequired,
  /*
   * Time text.
   */
  time: PropTypes.string.isRequired,
  /*
   * Comment text.
   */
  text: PropTypes.string,
};

MapPopup.defaultProps = {
  pictureURL: '',
  text: '',
};

export default MapPopup;
