/**
 * Module Dependencies
 */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import connect from 'react-redux/es/connect/connect';
import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';
import ScrollableAnchor, {
  configureAnchors,
  goToAnchor,
} from 'react-scrollable-anchor';
import { debounce } from 'throttle-debounce';
import { Redirect } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { hideLoadingScreen, showLoadingScreen } from '../../redux/loading';
import { getTripEntries, postLikeEntry } from '../../network/journiAPI';
import Styles from './TripTimeline.module.scss';
import TripHelper from './helpers/Trip';
/**
 * UI Components
 */
import MapContainer from '../../common/components/Map/MapContainer';
import TimeLineHeaderWrapper from './components/TimeLineHeaderWrapper';
import Container from '../../common/components/Containers/Container';
import FullscreenTripSlideShow from './components/FullScreenSlideShow';
import EntryWrapper from './components/EntryWrapper';
import {
  ANALYTICS_CATEGORY_RETENTION,
  ANALYTICS_LABEL_LIKE_ACTION,
} from '../../utils/constants/eventConstants';
import { triggerEventAction } from '../../redux/analytics';
import screenHelper from '../../utils/screenHelper';
import TripTransformer from '../../network/transformers/TripTransformer';
import AuthHelper from '../../helpers/Auth';

// Navbar offset is added for precision when scrolling to anchors.
configureAnchors({
  offset: -250,
  scrollDuration: 200,
});

/**
 * Page for TripTimeline
 */
class TripTimeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Journi - Timeline',
      isFetching: true,
      isFirstTimeLoad: true,
      isMapTranslated: false,
      focusedEntry: null,
      slideShowFocusedEntry: null,
      filteredMapEntries: null,
      filteredMapIndexes: null,
      data: null,
      isFullScreenModalVisible: false,
      isMapFullScreenMode: false,
      imageSelectedForFullScreen: null,
      notFound: false,
    };

    this.onLikeEntry = this.onLikeEntry.bind(this);
    this.translateMap = this.translateMap.bind(this);
    this.switchMapFullScreenMode = this.switchMapFullScreenMode.bind(this);
    this.setFocusedEntry = this.setFocusedEntry.bind(this);
    this.setFocusedEntryDebounced = debounce(500, this.setFocusedEntry);
    this.onToggleFullScreen = this.onToggleFullScreen.bind(this);
    this.onClickGridImage = this.onClickGridImage.bind(this);
    this.scrollToFirstMoment = this.scrollToFirstMoment.bind(this);
    this.mapContainerStyle = {
      width: '100%',
      height: '100%',
    };
  }

  async componentDidMount() {
    await this.fetchTrip();
    const { notFound } = this.state;
    if (!notFound) {
      this.setFocusedEntry();
      // Avoid detecting hash changes in mobile devices.
      const shouldDetectHashChange = screenHelper.getScreenWidth() > 576;
      if (shouldDetectHashChange)
        window.addEventListener(
          'hashchange',
          this.setFocusedEntryDebounced,
          false
        );
    }
  }

  async componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { tripURL } = match.params;

    if (prevProps.match.params && prevProps.match.params.tripURL) {
      const oldTripURL = prevProps.match.params.tripURL;

      if (tripURL !== oldTripURL) {
        this.fetchTrip(true);
      }
    }
  }

  componentWillUnmount() {
    // Remove event listener to avoid memory leaks.
    window.removeEventListener(
      'hashchange',
      this.setFocusedEntryDebounced,
      false
    );
  }

  onToggleFullScreen() {
    this.setState(({ isFullScreenModalVisible }) => ({
      isFullScreenModalVisible: !isFullScreenModalVisible,
      imageSelectedForFullScreen: null,
    }));
  }

  onClickGridImage(entryID, pictureID) {
    this.onToggleFullScreen();
    this.setState({
      imageSelectedForFullScreen: pictureID,
      // slideShowFocusedEntry: entryID,
    });
  }

  onLikeEntry(entryId, icon, liked) {
    const { setLoading, doTriggerEventAction } = this.props;
    const { data } = this.state;
    const { entries } = data;

    setLoading(true);

    postLikeEntry(entryId, icon.id, liked).then(
      (entry) => {
        if (entry && entries && entries.length) {
          const indexEntry = entries.findIndex(({ id }) => id === entry.id);
          if (indexEntry) {
            entries[indexEntry].likeCount = entry.likeCount;
            entries[indexEntry].top1LikeId = entry.top1LikeId;
            entries[indexEntry].top2LikeId = entry.top2LikeId;
            entries[indexEntry].userLikeId = entry.userLikeId;
            this.setState({
              data,
            });
          }
        }
        // Track event
        doTriggerEventAction(
          ANALYTICS_CATEGORY_RETENTION,
          null,
          ANALYTICS_LABEL_LIKE_ACTION,
          null,
          null,
          false
        );

        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }

  /**
   * Changes the focused entry. Focused entry is used in the map component to center the map in the appropriate
   * coordinate.
   * @param event
   * @param {number} [index] Id of the entry that should be focused
   * @param {boolean} [isEntryId] true if the provided index is actually an entry's id
   */
  setFocusedEntry(event, index = -1, isEntryId = false) {
    const {
      filteredMapEntries,
      filteredMapIndexes,
      slideShowFocusedEntry,
    } = this.state;
    const { data } = this.state;
    const { entries } = data;

    let shouldResetSlideshow = false;
    let isEntryValidSlideshow = false;
    let newSlideShowFocusedEntry = null;

    let entryIndex = index;

    // If the provided index is an entry's id, then search its index in the filtered map entries.
    if (isEntryId) {
      entryIndex = filteredMapIndexes[index] || -1;

      if (entryIndex === -1) {
        entryIndex = null;
      } else {
        goToAnchor(filteredMapEntries[entryIndex].id);
      }
    }

    // If no entry index is provided it means that the method was called from the event listener.
    if (entryIndex === -1) {
      const { hash } = document.location;
      if (hash && hash !== '#header') {
        entryIndex = parseInt(hash.replace('#', ''), 10);
        entryIndex = filteredMapIndexes[entryIndex] || -1;
        if (entryIndex === -1) {
          entryIndex = null;
        }
      } else {
        shouldResetSlideshow = true;
        newSlideShowFocusedEntry = null;
        entryIndex = null;
      }
    }

    // If the focused entry was filtered out from the map valid entries, then keep the last valid entry in the slideshow
    if (!shouldResetSlideshow) {
      // Check if new focused entry is a valid entry for the fullscreen slideshow
      isEntryValidSlideshow =
        entryIndex !== null &&
        entryIndex >= 0 &&
        FullscreenTripSlideShow.isEntryValidInSlideShow(entries[entryIndex]);
      if (isEntryValidSlideshow) {
        newSlideShowFocusedEntry = entries[entryIndex];
      } else {
        // If entry is not valid, then keep the last valid entry.
        newSlideShowFocusedEntry = slideShowFocusedEntry;
      }
    }

    this.setState({
      focusedEntry: entryIndex,
      slideShowFocusedEntry: newSlideShowFocusedEntry,
    });
  }

  async fetchTrip(isNewTrip = false) {
    const { match, setLoading } = this.props;
    const { tripURL } = match.params;

    // Check if it is a new trip, if it is not, then state should not be reseted'
    if (isNewTrip) {
      this.setState({
        title: 'Journi - Timeline',
        isFetching: true,
        isFirstTimeLoad: true,
        isMapTranslated: false,
        focusedEntry: null,
        slideShowFocusedEntry: null,
        filteredMapEntries: null,
        filteredMapIndexes: null,
        data: null,
        isFullScreenModalVisible: false,
        notFound: false,
      });
    } else {
      this.setState({
        isFetching: true,
      });
    }

    setLoading(true);

    await getTripEntries(tripURL).then(
      (data) => {
        let filteredMapEntries = null;
        let filteredMapIndexes = null;
        // Filter all entries that do not have coordinates or that contain invalid coordinates.
        if (data.entries) {
          filteredMapEntries = TripTransformer.filterMapValidEntries(
            data.entries
          );
          filteredMapIndexes = filteredMapEntries.reduce((map, entry) => {
            map[entry.id] = entry.index;
            return map;
          }, {});
        }

        this.setState({
          filteredMapEntries,
          filteredMapIndexes,
          data,
          title: `Journi - Timeline ${data.trip.title}`,
          isFetching: false,
          isFirstTimeLoad: false,
        });
        setLoading(false);
      },
      () => {
        // TODO: Add error handler
        this.setState({
          isFetching: false,
          notFound: true,
        });

        setLoading(false);
      }
    );
  }

  /**
   * Moves the browser's scroll to the first available entry.
   */
  scrollToFirstMoment() {
    const {
      data: { entries },
    } = this.state;
    const firstEntry = entries[0];
    if (firstEntry) {
      window.location.replace(`#${firstEntry.id}`);
    }
  }

  /**
   * Moves the map to a sticky position if the header is not visible in the viewport
   * @param isHeaderVisible visibility of the header
   */
  translateMap(isHeaderVisible) {
    this.setState({
      isMapTranslated: !isHeaderVisible,
    });
  }

  /**
   * Switches the map to fullscreen (not browser fullscreen but viewport fullscreen)
   * @param callback {function} callback function that will be executed once the map is changed to fullscreen.
   */
  switchMapFullScreenMode(callback = null) {
    const { isMapFullScreenMode } = this.state;
    this.setState(
      {
        isMapFullScreenMode: !isMapFullScreenMode,
      },
      callback
    );
  }

  renderContent() {
    const {
      data,
      isMapTranslated,
      focusedEntry,
      isFullScreenModalVisible,
      filteredMapEntries,
      slideShowFocusedEntry,
      notFound,
      imageSelectedForFullScreen,
      isMapFullScreenMode,
    } = this.state;

    if (notFound) return <Redirect to="/login" />;

    const { translatedMessages, userProfile, paths } = this.props;
    const { trip } = data;
    let { entries } = data;
    if (!entries) entries = [];

    const isFollowing = TripHelper.isUserFollowing(trip);
    const isAuthor = TripHelper.isUserAuthor(userProfile.id, trip);
    const isOwner = TripHelper.isUserOwner(userProfile.id, trip);
    const isAdmin = AuthHelper.isUserAdmin(userProfile);

    const isContentDisplayed = !!(
      TripHelper.isTripPublic(trip) ||
      isFollowing ||
      isAuthor ||
      isOwner ||
      isAdmin
    );
    let mapClass = classNames(Styles.Map, {
      [Styles.TranslatedMap]: isMapTranslated,
    });
    if (isMapFullScreenMode) {
      mapClass = Styles.MapFullScreen;
    }

    const mapComponent = (
      <MapContainer
        entries={filteredMapEntries}
        containerStyle={this.mapContainerStyle}
        focusedEntry={focusedEntry}
        onFullScreenClick={this.switchMapFullScreenMode}
        onPinClick={this.setFocusedEntryDebounced}
        visiblePopup
        interactive
      />
    );

    return (
      <React.Fragment>
        {isFullScreenModalVisible && (
          <FullscreenTripSlideShow
            entries={entries}
            onClose={this.onToggleFullScreen}
            activeSlide={slideShowFocusedEntry}
            activeImage={imageSelectedForFullScreen}
          />
        )}

        <VisibilitySensor
          onChange={this.translateMap}
          minTopValue={100}
          intervalDelay={10}
        >
          <ScrollableAnchor key="header" id="header">
            <TimeLineHeaderWrapper
              onChevronClick={this.scrollToFirstMoment}
              trip={trip}
              onFollow={() => this.fetchTrip()}
              mapComponent={mapComponent}
              isContentDisplayed={isContentDisplayed}
            />
          </ScrollableAnchor>
        </VisibilitySensor>

        {!isContentDisplayed ? null : (
          <Container withHorizontalPadding>
            <div className={Styles.Wrapper}>
              <div className={Styles.Entries}>
                {entries.map((entry) => (
                  <ScrollableAnchor key={entry.id} id={`${entry.id}`}>
                    <div>
                      <LazyLoad height={1000}>
                        <EntryWrapper
                          key={entry.id}
                          entry={entry}
                          translatedMessages={translatedMessages}
                          onLikeEntry={this.onLikeEntry}
                          pathPicture={paths.picture}
                          userPreferCelsius={userProfile.isMetric}
                          onClickImage={this.onClickGridImage}
                        />
                      </LazyLoad>
                    </div>
                  </ScrollableAnchor>
                ))}
              </div>

              <div className={mapClass}>{mapComponent}</div>
            </div>
          </Container>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { isFetching, title, isFirstTimeLoad } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>

        {!isFetching || !isFirstTimeLoad ? (
          this.renderContent()
        ) : (
          <div className={Styles.Placeholder} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  userProfile: state.user.profile,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => {
    if (isLoading) {
      dispatch(showLoadingScreen());
    } else {
      dispatch(hideLoadingScreen());
    }
  },
  doTriggerEventAction: (
    eventCategory,
    eventAction,
    eventLabel,
    eventProperties,
    callback,
    isImportant
  ) => {
    dispatch(
      triggerEventAction(
        eventCategory,
        eventAction,
        eventLabel,
        eventProperties,
        callback,
        isImportant
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TripTimeline);
