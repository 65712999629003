import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Select.module.scss';

class Select extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;

    this.state = { value };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    event.persist();
    const { onChange } = this.props;
    const eventTargetValue = event.target.value;
    this.setState({ value: eventTargetValue });
    onChange(event);
  }

  renderOptions() {
    const { elements, selected } = this.props;
    return elements.map(({ id, text }) => (
      <option key={id} value={id} defaultValue={id === selected}>
        {text}
      </option>
    ));
  }

  render() {
    const { value } = this.state;
    const { id, name, className } = this.props;
    return (
      <select
        id={id}
        name={name}
        className={[styles.Select, className].join(' ')}
        onChange={this.onChange}
        value={value}
      >
        {this.renderOptions()}
      </select>
    );
  }
}

Select.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    })
  ),
  selected: PropTypes.string,
};

Select.defaultValues = {
  elements: [],
};

export default Select;
