import React from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';
import classnames from 'classnames';

import styles from './Switch.module.scss';

function Switch({ label, name, className, detailedLabel, ...props }) {
  return (
    <div className={classnames(styles.Container, className)}>
      <div className={styles.Content}>
        <label className={classnames(styles.Label, 'tLabel')} htmlFor={name}>
          <ReactSwitch {...props} id={name} className={styles.Switch} />
          <div>{label}</div>
        </label>
      </div>

      {detailedLabel ? (
        <ul className={classnames(styles.DetailedLabel, 'tLabel')}>
          {detailedLabel
            .split('\n')
            .filter(Boolean)
            .map((line, index) => (
              <li key={index.toString()}>{line}</li>
            ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
}

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  detailedLabel: PropTypes.string,

  /**
   * The checked state of the switch. If true, the switch is set to checked. If false, it is not checked.
   */
  checked: PropTypes.bool.isRequired,

  /**
   * Invoked when the user clicks or drags the switch.
   *
   * **checked** describes the presumed future state of the checked prop.
   *
   * **event** is a native MouseEvent when the handle is clicked or dragged, and a SyntheticEvent at all other times.
   *
   *  **id** is the ID prop of the switch.
   *
   * @param {boolean} checked
   * @param {object} event
   * @param {string} id
   */
  onChange: PropTypes.func.isRequired,

  /**
   * When true, the switch will no longer be interactive and its colors will be greyed out.
   */
  disabled: PropTypes.bool,

  /**
   * The switch will take on this color when it is **not** checked. Only accepts 3 or 6 digit hex colors, e.g., #888, #45abcd.
   */
  offColor: PropTypes.string,

  /** The switch will take on this color when it is checked. Only accepts 3 or 6 digit hex colors, e.g., #080, #45abcd.
   */
  onColor: PropTypes.string,

  /**
   * The color of the handle of the switch when **not** checked. Only accepts 3 or 6 digit hex colors, e.g., #fff, #45abcd.
   */
  offHandleColor: PropTypes.string,

  /**
   * The color of the handle of the switch when checked. Only accepts 3 or 6 digit hex colors, e.g., #fff, #45abcd.
   */
  onHandleColor: PropTypes.string,

  /**
   * The diameter of the handle, measured in pixels. By default it will be slightly smaller than the height of the switch.
   */
  handleDiameter: PropTypes.number,

  /**
   * An icon that will be shown on the switch when it is **not** checked. Set to false to show no icon.
   */
  uncheckedIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),

  /**
   * An icon that will be shown on the switch when it is checked. Set to false to show no icon.
   */
  checkedIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),

  /**
   * The box-shadow of the handle of the switch.
   */
  boxShadow: PropTypes.string,

  /**
   * The box-shadow of the handle of the switch when it is active or focused.
   *
   * **Do not set this to null as it is important for accessibility.**
   */
  activeBoxShadow: PropTypes.string,

  /**
   * The height of the background of the switch, measured in pixels.
   */
  height: PropTypes.number,

  /**
   * The width of the background of the switch, measured in pixels.
   */
  width: PropTypes.number,

  /**
   * The className of the outer shell of the switch.
   */
  className: PropTypes.string,

  /**
   * The id of the embedded checkbox.
   */
  id: PropTypes.string,
};

Switch.defaultProps = {
  detailedLabel: undefined,
  disabled: undefined,
  offColor: undefined,
  onColor: '#80D2D5',
  offHandleColor: undefined,
  onHandleColor: '#00a4aa',
  handleDiameter: 20,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 15,
  width: 35,
  className: undefined,
  id: undefined,
};

export default Switch;
