import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import styles from './NavigationDropdownMenu.module.scss';
import WatchClickOutside from '../../Misc/WatchClickOutside';
import { DropdownNavigationItem } from '../../../../network/models';

/*
  Component that shows a dropdown menu when it gets clicked
 */
class NavigationDropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownMenuVisible: false,
    };
    this.changeDropdownMenuVisibility = this.changeDropdownMenuVisibility.bind(
      this
    );
  }

  changeDropdownMenuVisibility(isVisible) {
    this.setState({
      isDropdownMenuVisible: isVisible,
    });
  }

  renderDropdown() {
    const { elements, isBottomNavigation } = this.props;
    return (
      <div
        className={[
          styles.DropdownMenu,
          isBottomNavigation ? styles.OpenUpwards : '',
        ].join(' ')}
      >
        {elements.map((element, index) => {
          const { isURLAbsolute, emphasized, title, to, onClick } = element;
          const isOnClickAFunction = typeof onClick === 'function';
          const dropdownElement = (
            <div
              key={index}
              className={[
                styles.Item,
                'tLabel',
                emphasized ? styles.EmphasizedItem : '',
              ].join(' ')}
            >
              {title}
            </div>
          );
          if (isURLAbsolute) {
            return (
              <a
                key={index}
                href={to}
                onClick={isOnClickAFunction ? onClick : null}
              >
                {dropdownElement}
              </a>
            );
          }
          return (
            <Link to={to} key={index}>
              {dropdownElement}
            </Link>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      userProfilePicture,
      showChevron,
      isProfileActive,
      isSmall,
    } = this.props;
    const { isDropdownMenuVisible } = this.state;

    const componentStyles = classnames(
      styles.NavigationDropdown,
      styles.ProfilePicture,
      { [styles.BorderedProfilePicture]: isProfileActive },
      { [styles.SmallProfilePicture]: isSmall }
    );

    return (
      <WatchClickOutside
        onClickOutside={() => this.changeDropdownMenuVisibility(false)}
      >
        <div
          className={componentStyles}
          onClick={() =>
            this.changeDropdownMenuVisibility(!isDropdownMenuVisible)
          }
        >
          {isDropdownMenuVisible && this.renderDropdown()}
          <img src={userProfilePicture} alt="User profile" />
          {showChevron ? <span className="icon icon-chevron-down" /> : ''}
        </div>
      </WatchClickOutside>
    );
  }
}

NavigationDropdownMenu.propTypes = {
  /*
    elements: array of objects that should at least contain a title attribute
  */
  elements: PropTypes.arrayOf(DropdownNavigationItem),
  /*
    isBottomNavigation: true indicates that the dropdown will be positioned according the bottom navigation position.
  */
  isBottomNavigation: PropTypes.bool,
  /*
    isProfileActive: true draws a border around the profile picture
  */
  isProfileActive: PropTypes.bool,
  /*
    isSmall: true draws a small profile picture
  */
  isSmall: PropTypes.bool,
};

NavigationDropdownMenu.defaultProps = {
  elements: [],
  showChevron: true,
  isProfileActive: false,
  isSmall: false,
};

export default NavigationDropdownMenu;
