import connect from 'react-redux/es/connect/connect';
import { Component } from 'react';

/**
 * The BranchPopup is used to display a branch's 3rd party banner that offers the user the chance to get the app from
 * their device's app store.
 */
class BranchPopup extends Component {
  componentDidUpdate(prevProps) {
    const { branchKey } = this.props;
    if (branchKey !== prevProps.branchKey) {
      window.branch.init(branchKey);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  branchKey: state.configuration.branchKey,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(BranchPopup);
