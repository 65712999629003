import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import TimeLineHeader from '../Header';
import TranslationsQuantity from '../../../../helpers/TranslationsQuantity';
import { getCreatePhotoBookURL } from '../../../../utils/urlHelpers';
import TripHelper from '../../helpers/Trip';
import FollowerHelper from '../../../../helpers/Follower';
import ShareModal from '../../../../common/components/Modals/Share';
import PrintExceedModal from '../PrintExceedModal';
import TripFollowersModal from '../../../../common/components/Modals/Followers/TripFollowersModal';
import {
  postCreateShareLink,
  postCreateTripBook,
} from '../../../../network/journiAPI';
import {
  hideLoadingScreen,
  showLoadingScreen,
} from '../../../../redux/loading';
import { triggerEventAction } from '../../../../redux/analytics';
import {
  ANALYTICS_CATEGORY_RETENTION,
  ANALYTICS_LABEL_USER_INTERACTION,
} from '../../../../utils/constants/eventConstants';
import AuthHelper from '../../../../helpers/Auth';

class TimeLineHeaderWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShareModalVisible: false,
      isFollowersModalVisible: false,
      isPrintModalExceedVisible: false,
      shareURL: '',
      articleId: '',
      maxPrintElements: 0,
    };
    this.changeShareModalVisibility = this.changeShareModalVisibility.bind(
      this
    );
    this.changeFollowersModalVisibility = this.changeFollowersModalVisibility.bind(
      this
    );
    this.tooglePrintExceedModal = this.tooglePrintExceedModal.bind(this);
    this.redirectToBook = this.redirectToBook.bind(this);

    this.followTrip = this.followTrip.bind(this);
    this.onEmailShare = this.onEmailShare.bind(this);
    this.onFacebookShare = this.onFacebookShare.bind(this);
    this.onTwitterShare = this.onTwitterShare.bind(this);
    this.onPrintTrip = this.onPrintTrip.bind(this);
  }

  onEmailShare() {
    const { trip } = this.props;
    const { title } = trip;
    const { shareURL } = this.state;

    window.location.href = `mailto:?subject=${title}&body=${shareURL}`;
  }

  onFacebookShare() {
    const { shareURL } = this.state;
    window.open(`http://www.facebook.com/sharer.php?u=${shareURL}`, '_blank');
  }

  onTwitterShare() {
    const { trip } = this.props;
    const { title, places } = trip;
    const { shareURL } = this.state;

    window.open(
      `https://twitter.com/share?url=${shareURL};text=${title};hashtags=Journi,${places}`,
      '_blank'
    );
  }

  onPrintTrip() {
    const { trip, setLoading } = this.props;
    const { id } = trip;

    setLoading(true);

    postCreateTripBook(id)
      .then((response) => {
        const { exceedElementsLimit, article, maxElementCount } = response;
        const { orderArticleId } = article;

        this.setState(
          {
            articleId: orderArticleId,
            maxPrintElements: maxElementCount,
          },
          () => {
            if (exceedElementsLimit) this.tooglePrintExceedModal();
            else this.redirectToBook();
          }
        );

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  redirectToBook() {
    const { articleId } = this.state;

    window.open(getCreatePhotoBookURL(articleId), '_blank');
  }

  changeShareModalVisibility() {
    const { isShareModalVisible, shareURL } = this.state;
    const { trip, userProfile, setLoading } = this.props;
    const { id } = trip;
    const isAuthor = TripHelper.isUserAuthor(userProfile.id, trip);

    if (!isShareModalVisible && !shareURL) {
      setLoading(true);
      postCreateShareLink(id, 'web', isAuthor)
        .then(({ url }) => {
          this.setState({
            shareURL: url,
            isShareModalVisible: !isShareModalVisible,
          });
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      this.setState({
        isShareModalVisible: !isShareModalVisible,
      });
    }
  }

  changeFollowersModalVisibility() {
    const { isFollowersModalVisible } = this.state;
    this.setState({
      isFollowersModalVisible: !isFollowersModalVisible,
    });
  }

  tooglePrintExceedModal() {
    this.setState(({ isPrintModalExceedVisible }) => ({
      isPrintModalExceedVisible: !isPrintModalExceedVisible,
    }));
  }

  followTrip() {
    const { trip, onFollow, doTriggerEventAction } = this.props;
    const isUserFollowingTrip = TripHelper.isUserFollowing(trip);
    const isFollowRequestPending = TripHelper.hasRequestedToFollowTrip(trip);
    if (!isUserFollowingTrip && !isFollowRequestPending) {
      FollowerHelper.followTrip(trip.userId, trip.id).then(() => {
        onFollow();
        doTriggerEventAction(
          ANALYTICS_CATEGORY_RETENTION,
          null,
          ANALYTICS_LABEL_USER_INTERACTION,
          null,
          null,
          false
        );
      });
    } else {
      FollowerHelper.unfollowTrip(trip.userId, trip.id).then(() => onFollow());
    }
  }

  render() {
    const {
      onChevronClick,
      trip,
      translatedMessages,
      userProfile,
      mapComponent,
      largeHeader,
      isContentDisplayed,
    } = this.props;

    const {
      title,
      places,
      entryCount,
      followerCount,
      authors,
      coverImageURL,
      coverColor,
    } = trip;

    const {
      isShareModalVisible,
      isFollowersModalVisible,
      isPrintModalExceedVisible,
      shareURL,
      maxPrintElements,
    } = this.state;

    const isFollowing = TripHelper.isUserFollowing(trip);
    const isTripPrivate =
      TripHelper.isTripPrivate(trip) || TripHelper.isTripSecret(trip);
    const isFollowRequestPending = TripHelper.hasRequestedToFollowTrip(trip);
    const isAuthor = TripHelper.isUserAuthor(userProfile.id, trip);
    const isOwner = TripHelper.isUserOwner(userProfile.id, trip);

    const maxVisibleAuthors = 3;
    let numberAdditionalAuthors = authors - 1 - maxVisibleAuthors;
    numberAdditionalAuthors =
      numberAdditionalAuthors >= 0 ? numberAdditionalAuthors : 0;

    const extendedAuthors = TripHelper.getAdditionalAuthors(
      trip,
      false,
      maxVisibleAuthors
    );
    const originalAuthor = TripHelper.getOwner(trip);

    const translations = {
      momentsPhrase: TranslationsQuantity.getQuantityPhrase(
        entryCount,
        translatedMessages('main.moment'),
        translatedMessages('main.moments'),
        ''
      ),
      followersPhrase: TranslationsQuantity.getQuantityPhrase(
        followerCount,
        translatedMessages('main.follower'),
        translatedMessages('main.followers'),
        ''
      ),
      follow: translatedMessages('main.follow'),
      share: translatedMessages('button.share'),
      stopFollowing: translatedMessages('main.unfollow'),
      cancelRequest: translatedMessages('main.cancel'),
      requestFollow: translatedMessages('main.follow'),
      print: translatedMessages('button.to_print'),
    };

    const shareModalComponent = (
      <ShareModal
        translations={{
          title: translatedMessages('accounts.basic_title_3'),
          orPhrase: translatedMessages('main.or') || 'OR',
          shareDescriptionPhrase:
            translatedMessages('main.share_warning') ||
            'Anybody with this link will be able to see your Journi',
          shareViaPhrase: translatedMessages('button.share'),
          copyPhrase: translatedMessages('button.copy'),
          copiedPhrase: translatedMessages('button.copy'),
        }}
        shareLink={shareURL}
        onEmailShare={this.onEmailShare}
        onFacebookShare={this.onFacebookShare}
        onTwitterShare={this.onTwitterShare}
        onClose={() => this.changeShareModalVisibility(false)}
      />
    );

    const followersModalComponent = (
      <TripFollowersModal
        trip={trip}
        onClose={() => {
          this.changeFollowersModalVisibility(false);
        }}
      />
    );

    return (
      <React.Fragment>
        {isShareModalVisible && shareModalComponent}
        {isFollowersModalVisible && followersModalComponent}

        {isPrintModalExceedVisible ? (
          <PrintExceedModal
            translatedMessages={translatedMessages}
            maxPrintElements={maxPrintElements}
            onClose={this.tooglePrintExceedModal}
            onConfirm={this.redirectToBook}
          />
        ) : null}

        <TimeLineHeader
          coverImageURL={coverImageURL}
          coverColor={coverColor}
          originalAuthor={originalAuthor}
          authors={extendedAuthors}
          additionalAuthors={numberAdditionalAuthors}
          title={title}
          places={places}
          onShareButtonClick={this.changeShareModalVisibility}
          onFollowerButtonClick={this.changeFollowersModalVisibility}
          onFollowButtonClick={() =>
            AuthHelper.authenticateAndRun(this.followTrip)
          }
          onPrintTripClick={this.onPrintTrip}
          isFollowing={isFollowing}
          isAuthor={isAuthor}
          isOwner={isOwner}
          isTripPrivate={isTripPrivate}
          mapComponent={mapComponent}
          onChevronClick={onChevronClick}
          isFollowRequestPending={isFollowRequestPending}
          translations={translations}
          largeHeader={largeHeader}
          hasEntries={!!entryCount}
          isContentDisplayed={isContentDisplayed}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfile: state.user.profile,
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => {
    if (isLoading) {
      dispatch(showLoadingScreen());
    } else {
      dispatch(hideLoadingScreen());
    }
  },
  doTriggerEventAction: (
    eventCategory,
    eventAction,
    eventLabel,
    eventProperties,
    callback,
    isImportant
  ) => {
    dispatch(
      triggerEventAction(
        eventCategory,
        eventAction,
        eventLabel,
        eventProperties,
        callback,
        isImportant
      )
    );
  },
});

TimeLineHeaderWrapper.propTypes = {
  onChevronClick: PropTypes.func.isRequired,
  onFollow: PropTypes.func.isRequired,
  trip: PropTypes.object.isRequired,
  mapComponent: PropTypes.node,
  isContentDisplayed: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

TimeLineHeaderWrapper.defaultProps = {
  mapComponent: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeLineHeaderWrapper);
