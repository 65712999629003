import update from 'immutability-helper';

// Logout actions
export const REQUEST_EDIT_FRIEND = 'REQUEST_EDIT_FRIEND';
export const RECEIVE_EDIT_FRIEND = 'RECEIVE_EDIT_FRIEND';
export const HANDLE_EDIT_FRIEND_ERROR = 'HANDLE_EDIT_FRIEND_ERROR';

const initialState = {
  isRequesting: false,
  wasRequestSuccessful: false,
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case REQUEST_EDIT_FRIEND:
      return update(state, {
        isRequesting: { $set: true },
      });
    case RECEIVE_EDIT_FRIEND:
      return update(state, {
        isRequesting: { $set: false },
        wasRequestSuccessful: { $set: true },
      });
    case HANDLE_EDIT_FRIEND_ERROR:
      return update(state, {
        isRequesting: { $set: false },
      });
    default:
      return state;
  }
};
