import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './combineReducers';
import { callAPIMiddleware } from '../middleware/callAPIMiddleware';

const loggerMiddleware = createLogger();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Avoids redux logs in production
const appliedMiddleware =
  process.env.NODE_ENV === 'development'
    ? applyMiddleware(thunkMiddleware, callAPIMiddleware, loggerMiddleware)
    : applyMiddleware(callAPIMiddleware, thunkMiddleware);

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(appliedMiddleware)
  );
}
