import React, { Component } from 'react';
import { debounce } from 'throttle-debounce';
import propTypes from 'prop-types';
import styles from './SearchBar.module.scss';
import SearchBarResults from './SearchBarResults';
import WatchClickOutside from '../Misc/WatchClickOutside';
import { getExploreAutocomplete } from '../../../network/journiAPI';

/**
 * Provides an input UI component which performs a search when the attached
 * onChange listener gets an event.
 */
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Value obtained from the input component (search string).
      value: '',
      // Used to store the response after the search request is made.
      results: {},
      // On true value, it makes the search bar visible on small screens (width < 768px).
      isExtended: false,
      // On true value, it makes the search bar results visible.
      isSearchBarResultsVisible: false,
    };

    this.performSearch = debounce(300, this.performSearch);
    this.extendSearchBar = this.extendSearchBar.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.changeSearchBarResultsVisibility = this.changeSearchBarResultsVisibility.bind(
      this
    );
    this.hideSearchBar = this.hideSearchBar.bind(this);
  }

  hideSearchBar() {
    this.changeSearchBarResultsVisibility(false);
  }

  // it makes the search bar results visible/invisible.
  changeSearchBarResultsVisibility(
    shouldBeVisible = !this.state.isSearchBarResultsVisible
  ) {
    this.setState({
      isSearchBarResultsVisible: shouldBeVisible,
    });
  }

  // Makes the search visible on small screens (width < 768px).
  extendSearchBar() {
    this.setState(({ isExtended }) => ({
      isExtended: !isExtended,
    }));
  }

  /**
   *  Perform a search using the input component value as search parameter and stores
   *  the results in the component's state.
   */
  performSearch = (eventTargetValue) => {
    const inputText = eventTargetValue.trim();
    const isInputEmpty = inputText === '';
    const hasInputEnoughCharacters = inputText.length >= 2;

    if (!isInputEmpty && hasInputEnoughCharacters) {
      getExploreAutocomplete(eventTargetValue).then((response) => {
        this.setState({
          results: response,
          isSearchBarResultsVisible: true,
        });
      });
    } else {
      // On empty searches, results are cleared.
      this.setState({
        results: {},
        isSearchBarResultsVisible: false,
      });
    }
  };

  // Clears previous search results.
  clearSearch() {
    this.setState({
      value: '',
      results: {},
    });
  }

  render() {
    const {
      results,
      value,
      isExtended,
      isSearchBarResultsVisible,
    } = this.state;
    const { name, placeholder, onIconClick } = this.props;
    const isResultsEmpty = typeof results === 'undefined' || results.length;

    if (isSearchBarResultsVisible) {
      // Preventing body scrolling whilst there is an overlay focused
      if (!document.body.classList.contains('noScrollable')) {
        document.body.classList.add('noScrollable');
      }
      if (!document.documentElement.classList.contains('noScrollable')) {
        document.documentElement.classList.add('noScrollable');
      }
    } else {
      if (document.body.classList.contains('noScrollable')) {
        document.body.classList.remove('noScrollable');
      }
      if (document.documentElement.classList.contains('noScrollable')) {
        document.documentElement.classList.remove('noScrollable');
      }
    }

    return (
      <WatchClickOutside onClickOutside={this.hideSearchBar}>
        <div
          className={[styles.Container, isExtended ? styles.Extended : ''].join(
            ' '
          )}
        >
          <input
            className={[
              styles.InputField,
              isExtended ? styles.Extended : '',
              'tInput',
            ].join(' ')}
            id={name}
            name={name}
            value={value}
            onChange={(event) => {
              this.setState({ value: event.target.value });
              this.performSearch(event.target.value);
            }}
            placeholder={placeholder}
            onClick={() => this.changeSearchBarResultsVisibility(true)}
          />
          <span
            className={[
              styles.Icon,
              styles.AmplifierIcon,
              'icon icon-search',
              isExtended ? styles.Extended : '',
            ].join(' ')}
            onClick={() => {
              this.extendSearchBar();
              this.hideSearchBar();
              onIconClick();
            }}
          />
          <span
            className={[
              styles.Icon,
              styles.ClearSearchIcon,
              'icon icon-rounded-x',
              isExtended ? styles.Extended : '',
            ].join(' ')}
            onClick={() => {
              this.clearSearch();
            }}
          />
          <div
            className={[
              styles.SearchBarResults,
              isResultsEmpty || !isSearchBarResultsVisible
                ? styles.SearchBarEmpty
                : '',
            ].join(' ')}
            onClick={this.hideSearchBar}
          >
            <SearchBarResults
              isVisible={isSearchBarResultsVisible}
              searchResults={results}
              searchString={value}
            />
          </div>
        </div>
      </WatchClickOutside>
    );
  }
}

SearchBar.propTypes = {
  // value which will be set to the id and name attribute of the component's input tag.
  name: propTypes.string,
  // message that will be displayed as a placeholder in the search input area.
  placeholder: propTypes.string,
  // callback function invoked the user clicks/tap the amplifier icon.
  onIconClick: propTypes.func,
};

export default SearchBar;
