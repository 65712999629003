import PropTypes from 'prop-types';
import React from 'react';
import styles from './ScrollButton.module.scss';

export const ORIENTATION_UPWARDS = 'upwards';
export const ORIENTATION_DOWNWARDS = 'downwards';
export const ORIENTATION_LEFTWARDS = 'leftwards';
export const ORIENTATION_RIGHTWARDS = 'rightwards';

const getIconClass = (orientation) => {
  switch (orientation) {
    case ORIENTATION_UPWARDS:
      return 'icon-chevron-up';
    case ORIENTATION_LEFTWARDS:
      return 'icon-chevron-left';
    case ORIENTATION_DOWNWARDS:
      return 'icon-chevron-down';
    case ORIENTATION_RIGHTWARDS:
      return 'icon-chevron';
    default:
      return 'icon-chevron';
  }
};

/**
 * Component representing a scroll action via a button shape.
 */
const ScrollButton = ({ orientation, onClick }) => (
  <div className={styles.Oval} onClick={onClick}>
    <span className={getIconClass(orientation)} />
  </div>
);

ScrollButton.propTypes = {
  /**
   * Orientation: one of the ORIENTATION constants.
   * Specifies the orientation of the arrow that will be inside the button.
   * DEFAULT: ORIENTATION_RIGHT
   */
  orientation: PropTypes.oneOf([
    ORIENTATION_RIGHTWARDS,
    ORIENTATION_DOWNWARDS,
    ORIENTATION_LEFTWARDS,
    ORIENTATION_UPWARDS,
  ]),
  /**
   *  onClick: function. Function which will be called once the user press the button.
   */
  onClick: PropTypes.func,
};

ScrollButton.defaultProps = {
  orientation: ORIENTATION_RIGHTWARDS,
  onClick: () => {},
};

export default ScrollButton;
