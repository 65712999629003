import React from 'react';
import styles from './FluidAnchor.module.scss';

const FluidAnchor = (props) => {
  const { href, alt, ...rest } = props;
  return (
    <a className={styles.FluidAnchor} href={href} {...rest}>
      {alt}
    </a>
  );
};

export default FluidAnchor;
