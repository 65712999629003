import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './BottomNavigationBar.module.scss';
import Anchor from '../../Misc/Anchor';
import { NavigationItem } from '../../../../network/models';

/*
    Bottom navigation bar. Displayed only on for small screens devices (screen width < 768px).
 */
class PublicBottomNavigationBar extends Component {
  renderLinks() {
    const { navigationItems } = this.props;
    return navigationItems.map((navigationItem, index) => {
      const navigationItemClass = [
        styles.IconItem,
        navigationItem.isActive ? styles.ActiveIcon : '',
      ].join(' ');
      return !navigationItem.onlyTopNavigation ? (
        <div
          key={index}
          className={navigationItemClass}
          onClick={navigationItem.onClick}
        >
          <Anchor href={navigationItem.to.pathname}>
            <span className={navigationItem.icon} />
          </Anchor>
        </div>
      ) : null;
    });
  }

  render() {
    return (
      <div className={styles.Bar}>
        <div className={styles.Content}>{this.renderLinks()}</div>
      </div>
    );
  }
}

PublicBottomNavigationBar.propTypes = {
  /*
    navigationItems: array of navigation item that will be displayed (check NavigationItem type)
  */
  navigationItems: PropTypes.arrayOf(NavigationItem),
};

export default PublicBottomNavigationBar;
