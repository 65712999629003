import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import ModalContainer from '../ModalContainer';
import styles from './ChangeEmailModal.module.scss';
import * as journiAPI from '../../../../network/journiAPI';
import * as Button from '../../Buttons';
import Input from '../../Input/Input';
import ValidationBox, { BOX_TYPES } from '../../Boxes/ValidationBox';

class ChangeEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isFetching: false,
      callbackMessage: '',
      failedRequest: false,
      successRequest: false,
    };
    this.doChangeEmail = this.doChangeEmail.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  resetState() {
    this.setState({
      email: '',
      isFetching: false,
      callbackMessage: '',
      failedRequest: false,
      successRequest: false,
    });
  }

  doChangeEmail() {
    const { email } = this.state;

    this.setState({
      isFetching: true,
      callbackMessage: '',
      failedRequest: false,
      successRequest: false,
    });

    journiAPI.changeEmail(email).then(
      (response) => {
        this.setState({
          isFetching: false,
          callbackMessage: response.status,
          successRequest: true,
        });
      },
      (error) => {
        this.setState({
          isFetching: false,
          callbackMessage: error.status,
          failedRequest: true,
        });
      }
    );
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  renderCallbackMessage(callbackBoxType, callbackMessage) {
    if (callbackMessage) {
      return (
        <ValidationBox boxType={callbackBoxType}>
          {callbackMessage}
        </ValidationBox>
      );
    }
    return null;
  }

  render() {
    const { onClose, isVisible, translatedMessages } = this.props;
    const { failedRequest, successRequest, isFetching } = this.state;

    const callbackBoxType = failedRequest
      ? BOX_TYPES.FAILURE
      : BOX_TYPES.SUCCESS;
    const { callbackMessage } = this.state;

    const header = (
      <div className={styles.Header}>
        {translatedMessages('change_email.title')}
      </div>
    );

    const content = (
      <div className={styles.Content}>
        <Input
          title={translatedMessages('change_email.subtitle')}
          placeholder={translatedMessages('main.email')}
          className="mb2"
          type="email"
          name="email"
          onChange={this.handleInputChange}
          required
        />
        {this.renderCallbackMessage(callbackBoxType, callbackMessage)}
      </div>
    );

    const footer = (
      <Button.Primary
        disabled={isFetching || successRequest}
        onClick={this.doChangeEmail}
      >
        {translatedMessages('explore.submit_button')}
      </Button.Primary>
    );

    return (
      <ModalContainer
        isVisible={isVisible}
        header={header}
        content={content}
        footer={footer}
        onClose={() => {
          this.resetState();
          onClose();
        }}
      />
    );
  }
}

ChangeEmailModal.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailModal);
