import * as firebase from 'firebase/app';
import 'firebase/performance';
import config from '../config/default';

export default function init() {
  /**
   * Initialize Firebase.
   */
  firebase.initializeApp(config.firebase);

  if (process.env.NODE_ENV !== 'development') {
    /**
     * Initialize Performance Monitoring.
     */
    firebase.performance();
  }
}
