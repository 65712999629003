import update from 'immutability-helper';
import createAction from './utils/createAction';

/*
    Redux actions for fetching invitation data.
 */

const REQUEST_TOKEN_INVITATION_DATA = 'REQUEST_TOKEN_INVITATION_DATA';
const RECEIVE_TOKEN_INVITATION_DATA = 'RECEIVE_TOKEN_INVITATION_DATA';
const HANDLE_TOKEN_INVITATION_ERROR = 'HANDLE_TOKEN_INVITATION_ERROR';
const SET_INVITATION_TOKEN = 'SET_INVITATION_TOKEN';

const REQUEST_FRIEND_INVITATION = 'REQUEST_FRIEND_INVITATION';
const RECEIVE_FRIEND_INVITATION = 'RECEIVE_FRIEND_INVITATION';
const HANDLE_FRIEND_INVITATION_ERROR = 'HANDLE_FRIEND_INVITATION_ERROR';

export const requestTokenInvitationDataAction = createAction(
  REQUEST_TOKEN_INVITATION_DATA
);
export const receiveTokenInvitationDataAction = createAction(
  RECEIVE_TOKEN_INVITATION_DATA,
  'response'
);
export const handleTokenInvitationErrorAction = createAction(
  HANDLE_TOKEN_INVITATION_ERROR,
  'error'
);
export const setInvitationTokenAction = createAction(
  SET_INVITATION_TOKEN,
  'token'
);

export const requestFriendInvitationAction = createAction(
  REQUEST_FRIEND_INVITATION
);
export const receiveFriendInvitationAction = createAction(
  RECEIVE_FRIEND_INVITATION,
  'response'
);
export const handleFriendInvitationErrorAction = createAction(
  HANDLE_FRIEND_INVITATION_ERROR,
  'error'
);

const initialState = {
  friendPicture: '',
  maybeTripPicture: '',
  pageTitle: '',
  headerText: '',
  redirectUrl: '',
  token: '',
  isFetching: true,
};

export default (state = initialState, action) => {
  const { type, response, error } = action;
  switch (type) {
    case REQUEST_TOKEN_INVITATION_DATA:
      return update(state, { isFetching: { $set: true } });
    case RECEIVE_TOKEN_INVITATION_DATA:
      return update(state, {
        $merge: response,
        token: { $set: action.token },
        isFetching: { $set: false },
      });
    case HANDLE_TOKEN_INVITATION_ERROR:
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    case SET_INVITATION_TOKEN:
      return update(state, { token: { $set: action.token } });
    case REQUEST_FRIEND_INVITATION:
      return update(state, { isFetching: { $set: true } });
    case RECEIVE_FRIEND_INVITATION:
      return update(state, {
        $merge: response,
        token: { $set: action.token },
        isFetching: { $set: false },
      });
    case HANDLE_FRIEND_INVITATION_ERROR:
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    default:
      return state;
  }
};
