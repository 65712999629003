/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  getImageURL,
  IMAGE_FORMAT_TYPE_MOBILE_FULL,
} from '../../../../utils/urlHelpers';

/**
 * Component
 */
const StampComponent = (props) => {
  const { pathPicture, stampGuid, className } = props;
  const stampPictureUrl = getImageURL(
    pathPicture,
    stampGuid,
    IMAGE_FORMAT_TYPE_MOBILE_FULL
  );

  return (
    <div className={className || null}>
      <img src={stampPictureUrl} alt="stamp" />
    </div>
  );
};

/**
 * PropTypes
 */
StampComponent.propTypes = {
  pathPicture: PropTypes.string.isRequired,
  stampGuid: PropTypes.string.isRequired,
  className: PropTypes.string,
};

StampComponent.defaultProps = {
  className: null,
};

/**
 * Expose Component
 */
export default StampComponent;
