import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LazyImage from 'react-lazy-progressive-image';
import Styles from './styles.module.scss';

/**
 * Presentational component that render a picture cell. Used in the Image Grid component.
 */
const ImageGridImageContainer = ({
  id,
  onClick,
  imageURL,
  imagePreviewURL,
  border,
  showRemainingQuantity,
  placeholderColor,
}) => (
  <div
    onClick={() => onClick(id)}
    className={classnames(Styles.Container, {
      [Styles.OverLayer]: showRemainingQuantity,
    })}
    style={{
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: placeholderColor,
      borderRadius: border ? '8px' : null,
      border: border ? '2px transparent' : null,
      overflow: 'hidden',
    }}
  >
    <LazyImage placeholder={imagePreviewURL} src={imageURL}>
      {(src, loading) => {
        const imageComponent = (
          <img
            src={src}
            style={{
              filter: loading ? 'blur(10px)' : null,
            }}
            alt="Journi Moment"
          />
        );
        return imageComponent;
      }}
    </LazyImage>
    {showRemainingQuantity ? (
      <span className={Styles.MoreQuantity}>{`+${showRemainingQuantity}`}</span>
    ) : null}
  </div>
);

ImageGridImageContainer.propTypes = {
  /**
   * imageURL: string, URL of the picture that will be displayed.
   */
  imageURL: PropTypes.string.isRequired,
  /**
   * margin: bool, if true, adds border radius to the image cell.
   */
  border: PropTypes.bool,
  /**
   * showRemainingQuantity: Displays the number of images if it exceeds the amount of the grid.
   */
  showRemainingQuantity: PropTypes.number,
  /**
   * placeholderColor: CSS color that will be displayed as a placeholder.
   */
  placeholderColor: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ImageGridImageContainer.defaultProps = {
  placeholderColor: '#EDEDED',
  showRemainingQuantity: 0,
  border: true,
  onClick: () => {},
};

export default ImageGridImageContainer;
