import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flatMap from 'array.prototype.flatmap';
import { ExtendedEntry } from '../../../../network/models';
import EntryHelper from '../../helpers/Entry';
import BlockScroll from '../../../../helpers/BlockScroll';
import styles from './styles.module.scss';
import TripEntrySlide from '../EntrySlide';
import ScrollButton, {
  ORIENTATION_LEFTWARDS,
  ORIENTATION_RIGHTWARDS,
} from '../../../../common/components/Buttons/ScrollButton';
import * as Button from '../../../../common/components/Buttons';

/**
 * Provides a fullscreen slide show of the trip's moments.
 */
class FullscreenTripSlideShow extends Component {
  /**
   * Checks if the provided entry should be displayed in the component or not.
   * @param entry Entry that will be checked
   * @return {boolean} true if the entry should be displayed, false otherwise.
   */
  static isEntryValidInSlideShow(entry) {
    const { categoryId } = entry;
    return (
      categoryId !== EntryHelper.ENTRY_TYPES.FLIGHT.id &&
      categoryId !== EntryHelper.ENTRY_TYPES.STAMP.id &&
      categoryId !== EntryHelper.ENTRY_TYPES.STICKER.id &&
      categoryId !== EntryHelper.ENTRY_TYPES.WEATHER.id
    );
  }

  constructor(props) {
    super(props);
    const filteredSlides = this.getSlides();
    let activeSlideIndex = 0;

    if (props.activeImage) {
      activeSlideIndex = filteredSlides.findIndex(
        (entry) => entry.guid === props.activeImage
      );
    } else if (props.activeSlide) {
      activeSlideIndex = filteredSlides.findIndex(
        (entry) => entry.id === props.activeSlide.id
      );
    }

    this.state = {
      currentIndex: activeSlideIndex !== -1 ? activeSlideIndex : 0, // index in the slides array of the active slide
      slides: filteredSlides,
      hideControls: false,
    };

    this.changeSlide = this.changeSlide.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.hideControls = this.hideControls.bind(this);
    this.isComponentMounted = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
    document.body.addEventListener('keyup', this.onKeyUp);
    BlockScroll.on();
  }

  componentWillUnmount() {
    // remember to remove all events to avoid memory leaks
    if (this.isComponentMounted) {
      document.body.removeEventListener('keyup', this.onKeyUp);
    }
  }

  /**
   * Handle slides change by using arrow keys.
   * @param event
   */
  onKeyUp(event) {
    event.stopPropagation();
    const { currentIndex } = this.state;
    const keys = {
      leftArrow: 37,
      upArrow: 38,
      rightArrow: 39,
      downArrow: 40,
      escape: 27,
    };

    switch (event.which) {
      case keys.leftArrow:
        this.changeSlide(currentIndex - 1);
        break;
      case keys.rightArrow:
        this.changeSlide(currentIndex + 1);
        break;
      case keys.escape:
        this.onClose();
        break;
      default:
        break;
    }
  }

  onClose() {
    const { slides, currentIndex } = this.state;
    const { onClose } = this.props;
    const anchorId = slides[currentIndex].id;
    BlockScroll.off();
    if (anchorId) this.changeAnchor(anchorId);
    onClose();
  }

  /**
   * Collapses the entries. Useful for creating one individual entry per image when the entry has multiple images.
   * @return {*} Array of TripEntrySlide objects
   */
  getSlides() {
    const { entries } = this.props;

    if (entries) {
      let filteredSlideEntries = entries.filter((entry) =>
        this.constructor.isEntryValidInSlideShow(entry)
      );
      filteredSlideEntries = flatMap(filteredSlideEntries, (entry) => {
        const hasPictures = EntryHelper.hasPictures(entry);
        if (hasPictures) {
          return entry.pictures.map((pictureEntry, key) => {
            const merged = { ...entry, ...pictureEntry };
            merged.isPicture = true;
            merged.slideNumber = key + 1;
            merged.slidesNumber = entry.pictures.length;
            merged.parsedCreateDate = entry.parsedCreateDate;
            merged.parsedCreateTime = entry.parsedCreateTime;
            delete merged.pictures;
            return merged;
          });
        }
        return entry;
      });
      return filteredSlideEntries;
    }

    return null;
  }

  /**
   * Changes fullscreen controls visibility
   */
  hideControls() {
    const { hideControls } = this.state;
    this.setState({ hideControls: !hideControls });
  }

  changeAnchor(entryId) {
    const { setAnchorDynamically } = this.props;

    if (setAnchorDynamically && entryId) {
      const loc = document.location.toString().split('#')[0];
      document.location = `${loc}#${entryId}`;
    }
  }

  /**
   * Changes the active slide. If the provided slide index is out of range then:
   * - In overflow case, index sets to 0.
   * - In underflow case, index sets to the largest array index.
   * @param slideIndex array index of the slide that should be displayed
   */
  changeSlide(slideIndex) {
    const { slides } = this.state;

    if (slides) {
      let fixedIndex = slideIndex;
      if (slideIndex === slides.length) {
        fixedIndex = 0;
      } else if (slideIndex < 0) {
        fixedIndex = slides.length - 1;
      }

      this.setState({
        currentIndex: fixedIndex,
      });
    }
  }

  /**
   * Renders the active slide. Each slide can be categorized into:
   * - Only Comment slide
   * - Picture (+comment if available) slide
   * - Component slide (weather, sticker, flight, stamp.)
   * @return {*} React Component
   */
  renderActiveSlide() {
    const { slides, currentIndex, hideControls } = this.state;
    const { fullBackground } = this.props;
    const entry = slides[currentIndex];
    const {
      categoryName,
      categoryIcon,
      isPicture,
      parsedCreateDate,
      parsedCreateTime,
    } = entry;

    const tripEntryProps = {
      categoryName,
      categoryIcon,
      time: parsedCreateTime,
      date: parsedCreateDate,
      comment: entry.comment ? entry.comment : '',
      key: parsedCreateDate + parsedCreateTime,
      fullBackground,
      hideControls,
      onClose: this.onClose,
      onHideControls: this.hideControls,
    };

    if (isPicture) {
      const { slideNumber, slidesNumber, key, highResURL } = entry;
      tripEntryProps.image = highResURL;
      tripEntryProps.slideNumber = slideNumber;
      tripEntryProps.slidesNumber = slidesNumber;
      tripEntryProps.key = parsedCreateDate + parsedCreateTime + key;
    }

    return <TripEntrySlide {...tripEntryProps} />;
  }

  render() {
    const { currentIndex, slides } = this.state;
    const { onClose } = this.props;
    if (slides && slides.length) {
      return (
        <div className={styles.Container}>
          {
            <div className={styles.Chevrons}>
              <ScrollButton
                orientation={ORIENTATION_LEFTWARDS}
                onClick={() => this.changeSlide(currentIndex - 1)}
              />
              <ScrollButton
                orientation={ORIENTATION_RIGHTWARDS}
                onClick={() => this.changeSlide(currentIndex + 1)}
              />
            </div>
          }
          <div className={styles.Wrapper}>{this.renderActiveSlide()}</div>
        </div>
      );
    }

    return (
      <div className={styles.Container}>
        <div className={styles.CloseButtonWrapper}>
          <Button.Close onClick={onClose} noStyleChange />
        </div>
        {'No slides available'}
      </div>
    );
  }
}

FullscreenTripSlideShow.propTypes = {
  /*
   * Array of entries that will be displayed in the slide show.
   */
  entries: PropTypes.arrayOf(ExtendedEntry).isRequired,
  /*
   * If true, slides will display a full size background image
   */
  fullBackground: PropTypes.bool,
  /*
   * Callback function that will be called when the close button is clicked.
   */
  onClose: PropTypes.func,
  /*
   * Change the anchor dynamically by the current EntryID
   */
  setAnchorDynamically: PropTypes.bool,
  /*
   * Entry object that will be initially rendered in the slider.
   */
  activeSlide: PropTypes.object,
  /**
   * Guid of image slide selected
   */
  activeImage: PropTypes.string,
};

FullscreenTripSlideShow.defaultProps = {
  fullBackground: false,
  onClose: () => true,
  setAnchorDynamically: true,
  activeSlide: null,
  activeImage: null,
};

export default FullscreenTripSlideShow;
