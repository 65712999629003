import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../common/components/Modals/ModalContainer';
import {
  BUTTON_SIZES,
  Primary,
  Secondary,
} from '../../../../common/components/Buttons';

import styles from './styles.module.scss';

const PrintExceedModal = ({
  onClose,
  onConfirm,
  translatedMessages,
  maxPrintElements,
}) => (
  <Modal
    className={styles.Container}
    onClose={() => onClose()}
    header={translatedMessages('accounts.basic_title_photobook')}
    content={translatedMessages(
      'web.photo_picker.book_limit_reached.alert.message'
    ).replace('{p1}', maxPrintElements.toString())}
    footer={
      <div className={styles.ModalActions}>
        <Secondary
          className={styles.CancelButton}
          buttonSize={BUTTON_SIZES.SMALL}
          onClick={onClose}
        >
          {translatedMessages('main.cancel')}
        </Secondary>
        <Primary buttonSize={BUTTON_SIZES.SMALL} onClick={onConfirm}>
          {translatedMessages('button.create')}
        </Primary>
      </div>
    }
  />
);

PrintExceedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  translatedMessages: PropTypes.func.isRequired,
  maxPrintElements: PropTypes.number,
};

PrintExceedModal.defaultProps = {
  maxPrintElements: 0,
};

export default PrintExceedModal;
