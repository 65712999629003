import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ValidationBox.module.scss';

export const BOX_TYPES = {
  INFORMATION: 'Information',
  SUCCESS: 'Success',
  WARNING: 'Warning',
  FAILURE: 'Failure',
};

const ValidationBox = ({ boxType, className, children }) => (
  <div
    className={classNames('tButton', styles.Box, styles[boxType], className)}
  >
    {children}
  </div>
);

ValidationBox.propTypes = {
  /**
   * type: one of BOX_TYPES values, they set the color of the children container.
   * DEFAULT: BOX_TYPES.INFORMATION.
   */
  boxType: PropTypes.oneOf(Object.entries(BOX_TYPES).map((a) => a[1])),
  /**
   * className: string. Any other additional css class that should be appended to the button's actual class(es).
   * DEFAULT: "".
   */
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ValidationBox.defaultProps = {
  boxType: BOX_TYPES.INFORMATION,
  className: '',
};

export default ValidationBox;
