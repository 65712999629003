/**
 * Helper class that provides trips's useful methods.
 */
export default class GeoHelper {
  static CENTER_COORDINATES = [0, 0];

  static isCenterCoordinate(lat, lng) {
    return lat === 0 && lng === 0;
  }
}
