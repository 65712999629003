import update from 'immutability-helper';
import { BOX_TYPES } from '../../common/components/Boxes/ValidationBox';

// Forgot password actions
export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const RECEIVE_FORGOT_PASSWORD = 'RECEIVE_FORGOT_PASSWORD';
export const HANDLE_FORGOT_PASSWORD_ERROR = 'HANDLE_FORGOT_PASSWORD_ERROR';

const initialState = {
  callbackMessage: '',
  callbackBoxType: BOX_TYPES.INFORMATION,
  isRequesting: false,
  wasRequestSuccessful: false,
};

export default (state = initialState, action) => {
  const { type, response, error } = action;
  switch (type) {
    case REQUEST_FORGOT_PASSWORD:
      return update(state, {
        isRequesting: { $set: true },
      });
    case RECEIVE_FORGOT_PASSWORD:
      return update(state, {
        isRequesting: { $set: false },
        callbackMessage: { $set: response },
        callbackBoxType: { $set: BOX_TYPES.SUCCESS },
        wasRequestSuccessful: { $set: true },
      });
    case HANDLE_FORGOT_PASSWORD_ERROR:
      return update(state, {
        callbackMessage: { $set: error },
        callbackBoxType: { $set: BOX_TYPES.FAILURE },
        isRequesting: { $set: false },
      });
    default:
      return state;
  }
};
