/**
 * List of names that refer to common icons css classes
 * Icons classes are in common/styles/icons.scss
 * @returns { iconsNames }
 */
module.exports = {
  HeartOutline: 'icon-heart-streamline',
  CommentOutline: 'icon-comment-outline',
  Plane: 'icon-plane',
};
