/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './Weather.module.scss';

/**
 * Helpers
 */
import getIconUrl from './icons-weather-url';
import { transformCelsiusToFarenheit } from '../../../../utils/temperature';

const METRIC_CELSIUS = '°C';
const METRIC_FARENHEIT = '°F';

/**
 * Component
 */
const WeatherComponent = ({ weatherId, temperature, isCelsius, className }) => (
  <div className={classnames(Styles.Weather, className)}>
    <img
      className={Styles.WeatherImage}
      src={getIconUrl(weatherId)}
      alt="icon_weather"
    />
    <p className={Styles.WeatherTemperature}>
      {isCelsius
        ? `${temperature} ${METRIC_CELSIUS}`
        : `${transformCelsiusToFarenheit(temperature).toFixed(
            2
          )} ${METRIC_FARENHEIT}`}
    </p>
  </div>
);

/**
 * PropTypes
 */
WeatherComponent.propTypes = {
  weatherId: PropTypes.number.isRequired,
  temperature: PropTypes.number.isRequired,
  isCelsius: PropTypes.bool,
  className: PropTypes.string,
};

WeatherComponent.defaultProps = {
  isCelsius: true,
  className: null,
};

/**
 * Expose Component
 */
export default WeatherComponent;
