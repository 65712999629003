import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import connect from 'react-redux/es/connect/connect';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { hideLoadingScreen, showLoadingScreen } from '../../redux/loading';
import { translate } from '../../common/components/Misc/Translate';
import styles from '../../common/components/Navigation/Blog/NavigationBar.module.scss';
import PublicNavigationBarContainer from '../../common/components/Navigation/Blog/PublicNavigationBarContainer';
import NavigationBarContainer from '../../common/components/Navigation/Blog/NavigationBarContainer';
import FooterContainer from '../../common/components/Navigation/Blog/FooterContainer';

/*
    RichRoute is a wrapper for Routes which includes additional properties
    (setLoading method, paths fetched from configuration)
    for the rendered components. Component are not rendered until isLoading is set to true (see explanation below).
 */
const RichRoute = ({
  component: Component,
  displayNavBar,
  isConfigurationReady,
  isAuthenticated,
  setLoading,
  paths,
  props,
  ...rest
}) => {
  function render(props) {
    if (isConfigurationReady) {
      return (
        <React.Fragment>
          {displayNavBar ? (
            isAuthenticated ? (
              <div className={styles.NavigationBarLayout}>
                <NavigationBarContainer />
              </div>
            ) : (
              <div className={styles.NavigationBarLayout}>
                <PublicNavigationBarContainer />
              </div>
            )
          ) : null}
          <Component
            setLoading={setLoading}
            paths={paths}
            translate={translate}
            {...props}
            {...rest}
          />
          {props.showFooter ? <FooterContainer /> : null}
        </React.Fragment>
      );
    }
    return '';
  }

  return <Route {...rest} render={render} />;
};

RichRoute.propTypes = {
  /*
        displayNavBar: when true it renders the navigation bar.
     */
  displayNavBar: PropTypes.bool,
  showFooter: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  paths: state.configuration.paths,
  /*
    isConfigurationReady property just depends on the required web app configuration loading state (fetched configuration values from backend and
    user's profile loading states). It is used to ensure the components will be able to use configuration values.
    Without them we can not ensure that components (such as forms) will be functional.
 */
  isConfigurationReady: state.configuration.isConfigurationReady,
  showFooter: true,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => {
    if (isLoading) dispatch(showLoadingScreen());
    else dispatch(hideLoadingScreen());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RichRoute);
