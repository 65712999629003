import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './BottomNavigationBar.module.scss';
import NavigationDropdownMenu from './NavigationDropdownMenu';

/*
  Bottom navigation bar. Displayed only on for small screens devices (screen width < 768px).
 */
class BottomNavigationBar extends Component {
  renderLinks() {
    const { navigationItems } = this.props;
    return navigationItems.map((navigationItem, index) => {
      const navigationItemClass = [
        styles.IconItem,
        navigationItem.isActive ? styles.ActiveIcon : '',
      ].join(' ');

      if (navigationItem.isURLAbsolute) {
        return (
          <div
            key={index}
            className={navigationItemClass}
            onClick={navigationItem.onClick}
          >
            <a href={navigationItem.to}>
              <span className={navigationItem.icon} />
            </a>
            {navigationItem.hasNewActivity ? (
              <div className={styles.IsNewBadge} />
            ) : null}
          </div>
        );
      }
      return (
        <div
          key={index}
          className={navigationItemClass}
          onClick={navigationItem.onClick}
        >
          <Link to={navigationItem.to}>
            <span className={navigationItem.icon} />
          </Link>
          {navigationItem.hasNewActivity ? (
            <div className={styles.IsNewBadge} />
          ) : null}
        </div>
      );
    });
  }

  render() {
    const {
      userProfilePictureURL,
      userMenuElements,
      isProfileActive,
    } = this.props;

    return (
      <div className={styles.Bar}>
        <div className={styles.Content}>
          {this.renderLinks()}
          <div className={styles.IconItem}>
            <NavigationDropdownMenu
              userProfilePicture={userProfilePictureURL}
              showChevron={false}
              isBottomNavigation
              isSmall
              elements={userMenuElements}
              isProfileActive={isProfileActive}
            />
          </div>
        </div>
      </div>
    );
  }
}

BottomNavigationBar.propTypes = {
  /*
    userProfilePictureURL: URL of the user's profile picture
  */
  userProfilePictureURL: PropTypes.string,
  /*
    navigationItems: array of objects which contain data of links
  */
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      icon: PropTypes.string,
    })
  ),
  /*
    userMenuElements: array of elements that will be displayed in the NavigationDropdownMenu.
  */
  userMenuElements: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      emphasized: PropTypes.bool,
    })
  ),
  /*
    isProfileActive: if true, profile page is the current route
  */
  isProfileActive: PropTypes.bool,
};

BottomNavigationBar.defaultProps = {
  userProfilePictureURL: '',
  navigationItems: [],
  isProfileActive: false,
};

export default BottomNavigationBar;
