import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logPageChange, triggerEventAction } from '../../../redux/analytics';

/**
 * Analytics component to combine all existing tracking services and track the screens' changes.
 */
class Analytics extends Component {
  componentDidUpdate(prevProps) {
    const prevLocation = prevProps.location;
    const {
      location: { pathname, search },
      shouldTrack,
      logPageChange,
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;
    // Tracking components are just initialized in prod. environments
    if (shouldTrack !== undefined) {
      if (prevProps.shouldTrack === undefined) {
        logPageChange(pathname, search);
      } else if (isDifferentPathname || isDifferentSearch) {
        logPageChange(pathname, search);
      }
    }
  }

  render() {
    return null; // The component will never be rendered
  }
}

Analytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  triggerEventAction: (
    eventCategory,
    eventAction,
    eventLabel,
    eventProperties,
    callback
  ) => {
    dispatch(
      triggerEventAction(
        eventCategory,
        eventAction,
        eventLabel,
        eventProperties,
        callback
      )
    );
  },
  logPageChange: (pathname, search) => {
    dispatch(logPageChange(pathname, search));
  },
});

const mapStateToProps = (state) => ({
  shouldTrack: state.analytics.shouldTrack,
});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
