import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserProfilePicture.module.scss';
import WithImageUpload from '../Input/WithImageUpload';
import * as journiAPI from '../../../network/journiAPI';

const UserProfilePicture = ({ userPictureColor, userPictureUrl, children }) => {
  const userPictureStyle = {
    background: `${userPictureColor} url(${userPictureUrl}) center center / cover`,
  };
  return (
    <div className={styles.UserProfilePicture} style={userPictureStyle}>
      {children}
    </div>
  );
};

UserProfilePicture.propTypes = {
  userPictureColor: PropTypes.string.isRequired,
  userPictureUrl: PropTypes.string.isRequired,
};

UserProfilePicture.defaultValues = {
  userPictureColor: 'rgb(97, 98, 121)',
};

export default WithImageUpload(
  UserProfilePicture,
  'userProfilePicture',
  {
    borderRadius: '100%',
    marginLeft: '-4px',
    marginTop: '-4px',
    width: 'calc(100% + 8px)',
    height: 'calc(100% + 8px)',
    zIndex: 4,
  },
  (pictureFormData, onSuccessCallback, onErrorCallback) => {
    journiAPI.uploadPhoto(pictureFormData).then(
      (response) => {
        return journiAPI.postUserPicture(response.guid, null).then(
          () => {
            journiAPI.fetchUserProfile().then(onSuccessCallback);
          },
          (error) => {
            onErrorCallback(error);
          }
        );
      },
      (error) => {
        onErrorCallback(error);
      }
    );
  }
);

export { UserProfilePicture as UserProfilePictureWithoutUpload };
