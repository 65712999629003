/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { getAssetURL } from '../../../../utils/urlHelpers';
import ModalContainer from '../../../../common/components/Modals/ModalContainer';
import Styles from './styles.module.scss';

/**
 * Component
 */
const CommentModal = ({ translatedMessages, onClose }) => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let isAndroid = false;
  let isIOS = false;

  if (/Android/i.test(userAgent)) isAndroid = true;
  if (/iPad|iPhone|iPod/i.test(userAgent)) isIOS = true;

  return (
    <ModalContainer
      header={<h2>{translatedMessages('button.to_comment')}</h2>}
      /** TODO: Add translations */
      content={
        <div>
          <p className={Styles.Modaltext}>
            {translatedMessages('main.only_mobile') ||
              'We are sorry, but at the moment this feature is only available in the mobile app.'}
          </p>

          <div className={Styles.Row}>
            {(!isAndroid && !isIOS) || isAndroid ? (
              <a
                className={Styles.StoreButton}
                href="https://journi.onelink.me/9xjK/WebAppInstall"
              >
                <img
                  alt="Get it on Google Play"
                  src={getAssetURL('/icons/mobileStores/google.svg')}
                />
              </a>
            ) : null}

            {(!isAndroid && !isIOS) || isIOS ? (
              <a
                className={Styles.StoreButton}
                href="https://journi.onelink.me/9xjK/WebAppInstall"
              >
                <img
                  alt="apple_store"
                  src={getAssetURL('/icons/mobileStores/apple.svg')}
                />
              </a>
            ) : null}
          </div>
        </div>
      }
      onClose={() => onClose()}
    />
  );
};

/**
 * PropTypes
 */
CommentModal.propTypes = {
  translatedMessages: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

/**
 * Expose Component
 */
export default CommentModal;
