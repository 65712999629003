import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

/**
 * Form container
 */
const Form = (props) => {
  const { children, onSubmit } = props;
  return (
    <form className={styles.FormBox} onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
};

Form.propTypes = {
  /**
   * handleSubmit: function, invoked when form is submitted
   */
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Form.defaultProps = {
  onSubmit: () => true,
};

export default Form;
