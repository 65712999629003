import update from 'immutability-helper/index';
import createAction from './utils/createAction';

export const CHANGE_LOADING_SCREEN_VISIBILITY =
  'CHANGE_LOADING_SCREEN_VISIBILITY';

/*
    Loading state is used for alerting components about critical loading requests, such as configuration fetching
    or checking user authentication from backend. Is also used for controlling the visibility of the LoadingPage component.
 */

const initialState = {
  isLoadingScreenVisible: true,
};

const setLoadingScreenVisibilityAction = createAction(
  CHANGE_LOADING_SCREEN_VISIBILITY,
  'isLoadingScreenVisible'
);

export function showLoadingScreen() {
  return (dispatch) => {
    dispatch(setLoadingScreenVisibilityAction(true));
  };
}

export function hideLoadingScreen() {
  return (dispatch) => {
    dispatch(setLoadingScreenVisibilityAction(false));
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LOADING_SCREEN_VISIBILITY:
      return update(state, {
        isLoadingScreenVisible: { $set: action.isLoadingScreenVisible },
      });
    default:
      return state;
  }
};
