import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './NotificationDropdown.module.scss';
import WatchClickOutside from '../../Misc/WatchClickOutside';
import NotificationItem from './NotificationItem';
import { hasNewNotifications } from '../../../../utils/checkers';
import NotificationsHelper from '../../../../helpers/Notifications';

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotificationMenuVisible: false,
    };
    this.changeNotificationMenuVisibility = this.changeNotificationMenuVisibility.bind(
      this
    );
  }

  changeNotificationMenuVisibility(isVisible) {
    this.setState({
      isNotificationMenuVisible: isVisible,
    });

    /*
      Notifications are fetched when the user closes the dropdown.
      This behaviour is required to keep the isNew badges since the notifications
      are fetched when the component is mounted.
    */
    if (isVisible) {
      NotificationsHelper.getNotifications();
    }
  }

  renderDropdown() {
    const { notifications, noNotificationsMessage, seeAllMessage } = this.props;
    const isNotificationsEmpty =
      notifications === undefined || notifications.length === 0;

    return (
      <div className={[styles.DropdownMenu].join(' ')}>
        <div className={styles.DropdownMenuBody}>
          {isNotificationsEmpty ? (
            <div className="tLabel">{noNotificationsMessage}</div>
          ) : (
            notifications.map((notification, key) => (
              <NotificationItem
                key={key}
                notification={notification}
                smallItem
              />
            ))
          )}
        </div>
        <div className={styles.DropdownMenuFooter}>
          <Link to="/notifications">{seeAllMessage}</Link>
        </div>
      </div>
    );
  }

  render() {
    const { isNotificationMenuVisible } = this.state;
    const { notifications } = this.props;

    return (
      <WatchClickOutside
        onClickOutside={() => this.changeNotificationMenuVisibility(false)}
      >
        <div
          className={styles.NavigationDropdown}
          onClick={() =>
            this.changeNotificationMenuVisibility(!isNotificationMenuVisible)
          }
        >
          {isNotificationMenuVisible && this.renderDropdown()}
          <span className="icon icon-notifications" />
          {hasNewNotifications(notifications) ? (
            <div className={styles.IsNewBadge} />
          ) : null}
        </div>
      </WatchClickOutside>
    );
  }
}

NotificationDropdown.propTypes = {
  /*
    isBottomNavigation: true indicates that the dropdown will be positioned according the bottom navigation position.
    */
  isBottomNavigation: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.object),
  seeAllMessage: PropTypes.string,
  noNotificationsMessage: PropTypes.string,
};

NotificationDropdown.defaultProps = {
  notifications: [],
  isBottomNavigation: true,
};

export default NotificationDropdown;
