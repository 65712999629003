import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import connect from 'react-redux/es/connect/connect';
import { Route, Redirect } from 'react-router-dom';
import { hideLoadingScreen, showLoadingScreen } from '../../redux/loading';
import { translate } from '../../common/components/Misc/Translate';

/*
    ExclusivePublicRoute is a wrapper for Routes which renders the component only if the user is not Authenticated, otherwise
    it redirects to the Profile page (landing page redirects authenticated users to it).
 */
const ExclusivePublicRoute = ({
  component: Component,
  isAuthenticated,
  setLoading,
  paths,
  translate,
  isConfigurationReady,
  redirectUrl,
  ...rest
}) => {
  function render(props) {
    if (!isConfigurationReady) {
      return '';
    }
    if (isAuthenticated === true) {
      if (redirectUrl) return ''; // If there is a pending redirection then wait until it is used.
      return <Redirect to="/profile" />;
    }
    return (
      <Component
        {...props}
        setLoading={setLoading}
        paths={paths}
        translate={translate}
      />
    );
  }

  return <Route {...rest} render={render} />;
};

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.user.isAuthenticated,
  paths: state.configuration.paths,
  translate: translate(state.configuration.messages),
  isConfigurationReady: state.configuration.isConfigurationReady,
  redirectUrl: state.events.redirectUrl,
});

/*
    We allow components to make the loading screen appear by providing them the turnBusy dispatch method.
 */
const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => {
    if (isLoading) dispatch(showLoadingScreen());
    else dispatch(hideLoadingScreen());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExclusivePublicRoute);
