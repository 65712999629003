import './utils/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppplicationContainer from './routes/App';
import configureStore from './redux/utils/configureStore';
import 'normalize.css'; // For more consistent rendering across browsers
import './common/styles/global.scss'; // Style rules for elements outside the scope of the main component
import initFirebase from './helpers/Firebase';

export const store = configureStore();

const Index = () => (
  <Provider store={store}>
    <AppplicationContainer />
  </Provider>
);

initFirebase();
ReactDOM.render(<Index />, document.getElementById('web-app-container'));
