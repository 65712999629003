import { connect } from 'react-redux';
import React from 'react';

const Translate = ({ id, args, messages, translate, children }) => {
  if (id) {
    const args2 = args || [];
    const translatedMessage = translate(id, ...args2) || children;
    return <React.Fragment>{translatedMessage}</React.Fragment>;
  }
  if (typeof children === 'function') {
    return <React.Fragment>{children(translate, messages)}</React.Fragment>;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

/**
 *  Function for providing a translate function given a messages dictionary
 */

export function translate(messages) {
  const mdStrongRegex = /(\*\*)(.*)(\*\*)/; // Matches emphasized text
  return (key, ...args) => {
    let message = messages[key] || '';

    for (let i = 0; i < args.length; i++) {
      const arg = args[i];
      message = message.replace(`{p${i + 1}}`, arg);
    }

    const mdStrongMatches = message.match(mdStrongRegex);
    if (mdStrongMatches) {
      message = (
        // eslint-disable-next-line react/no-danger
        <span
          dangerouslySetInnerHTML={{
            __html: message.replace(
              mdStrongMatches[0],
              `<strong>${mdStrongMatches[2]}</strong>`
            ),
          }}
        />
      );
    }

    return message;
  };
}

const mapStateToProps = (state) => ({
  translate: translate(state.configuration.messages),
  messages: state.configuration.messages,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Translate);
