import queryString from 'query-string';

import Http from '../utils/Http';
import {
  handleUserProfileErrorAction,
  recieveUserProfileAction,
  requestUserProfileAction,
} from '../redux/user';
import StoreHelper from '../helpers/Store';
import TripTransformer from './transformers/TripTransformer';

const buildUnsubscribeEndpointUrl = ({
  userUrl,
  userSecret,
  notificationKey,
  tripUrl,
  searchParams,
}) => {
  const query = queryString.parse(searchParams);
  const url = queryString.stringifyUrl({
    url: `/api/v8.0/unsubscribe/${userUrl}/${userSecret}/${notificationKey}/${
      tripUrl || '-'
    }`,
    query,
  });
  return url;
};

export const login = (email, password) =>
  Http.post('/api/v8.0/user/login', {
    email,
    password,
  });

export const logout = () => Http.post('/api/v8.0/user/logout', {});

export const signup = (email, password, firstName, lastName) =>
  Http.post('/api/v8.0/user/signup', {
    email,
    password,
    firstName,
    lastName,
  });

export const forgotPassword = (email) =>
  Http.post('/api/v8.0/user/lost-password', { email });

export const recoverPassword = (token, password, password2) =>
  Http.post('/api/v8.0/recover-password', {
    token,
    password,
    password2,
  });

export const postDeleteAccount = () =>
  Http.post('/api/v8.0/user/account/delete', {});

export const updateProfileSettings = (userProfile) =>
  Http.post('/api/v8.0/user/profile', userProfile);

export const postAuths = (authentication) =>
  Http.post('/api/v8.0/user/auth', authentication);

export const fetchUserProfile = (userUrl = '') => {
  if (userUrl) {
    return Http.get(`/api/v8.0/user/url/${userUrl}/profile`);
  }

  StoreHelper.dispatch(requestUserProfileAction());

  return Http.get('/api/v8.0/user/profile')
    .then((response) => {
      StoreHelper.dispatch(recieveUserProfileAction(response));
      return response;
    })
    .catch((error) => {
      StoreHelper.dispatch(handleUserProfileErrorAction(error));
      return error;
    });
};

export function fetchFollowedTrips(userId) {
  return Http.get(`/api/v2.0/user/${userId}/followed-trips`);
}

export function fetchUserFollowers(userId) {
  return Http.get(`/api/v2.0/user/${userId}/followers`);
}

export function fetchUserFollowed(userId) {
  return Http.get(`/api/v2.0/user/${userId}/followed`);
}

export function changeEmail(email) {
  return Http.post('/api/v2.0/user/change-email', { email });
}

export function changePassword(oldpassword, password) {
  return Http.post('/api/v8.0/user/change-password', {
    oldpassword,
    password,
  });
}

export function postUserPicture(pictureGuid, coverGuid) {
  return Http.post('/api/v2.0/user/picture', {
    pictureGuid,
    coverGuid,
  });
}

export function uploadPhoto(photoFormData) {
  return Http.post('/api/v9.1/picture/upload', photoFormData);
}

export function postUnsubscribe(
  userUrl,
  userSecret,
  notificationKey,
  tripUrl,
  searchParams
) {
  return Http.post(
    buildUnsubscribeEndpointUrl({
      userUrl,
      userSecret,
      notificationKey,
      tripUrl,
      searchParams,
    }),
    {}
  );
}

export function putUnsubscribe(
  userUrl,
  userSecret,
  notificationKey,
  tripUrl,
  searchParams,
  notificationSettings
) {
  return Http.put(
    buildUnsubscribeEndpointUrl({
      userUrl,
      userSecret,
      notificationKey,
      tripUrl,
      searchParams,
    }),
    notificationSettings
  );
}

export function getExploreCategories() {
  return Http.get('/api/v2.1/explore/categories');
}

export function getNotifications() {
  return Http.get('/api/v2.0/user/notifications');
}

export function getExploreCategory(key, page) {
  return Http.get(`/api/v7.9/explore/category/${key}/${page}`);
}

export function getExploreAutocomplete(filter) {
  return Http.get(`/api/v7.9/explore/autocomplete/${filter}`);
}

export function postInviteRedeem(token) {
  return Http.post(`/api/v2.0/invite/redeem/${token}`);
}

export function postFollow(userId, tripId, isAuthorInvite, remove) {
  return Http.post('/api/v8.0/follower', {
    userId,
    tripId,
    isAuthorInvite,
    remove,
  });
}

export function getTokenInvitation(token) {
  return Http.get(`/api/v8.0/invite/${token}`);
}

export function getFriendInvitation(friendUrl, tripUrl) {
  return Http.get(`/api/v8.0/friend-invite/${friendUrl}/${tripUrl}`);
}

export function getTripEntries(tripURL, since = 0) {
  return Http.get(`/api/v7.9/trip/url/${tripURL}/entries/${since}`).then(
    (response) => {
      TripTransformer.enrichTrip(response);
      return response;
    }
  );
}

export function getStampDetails(countryCode) {
  return Http.get(`/api/v8.0/stamp/${countryCode}`);
}

export function postLikeEntry(entryId, iconId, liked = true) {
  return Http.post('/api/v7.5/trip/entry/like', {
    id: entryId,
    iconId,
    liked,
  });
}

export function postListTripFollowers(tripId) {
  return Http.post('/api/v8.0/follower/list', { tripId });
}

export function postCreateShareLink(tripId, source, isAuthorInvite = false) {
  return Http.post('/api/v2.0/user/create-link', {
    tripId,
    source,
    isAuthorInvite,
  });
}

export function postCreateTripBook(tripId) {
  return Http.post(`/webapi/trip/${tripId}/book`, {});
}
