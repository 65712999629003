import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Checkbox.module.scss';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      value,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { onChange } = this.props;
    this.setState({ value: event.target.checked });
    onChange(event);
  }

  render() {
    const { id, name, label, detailedLabel, className } = this.props;
    const { value } = this.state;
    const hasDetailedLabel = detailedLabel && detailedLabel;

    return (
      <div className={classnames(styles.Container, className)}>
        <div className={styles.Content}>
          <input
            className={classnames(styles.Checkbox, {
              [styles.Checked]: !!value,
            })}
            type="checkbox"
            id={id}
            name={name}
            checked={value}
            onChange={this.onChange}
          />
          <label className={classnames(styles.Label, 'tLabel')} htmlFor={name}>
            {label}
          </label>
        </div>

        {hasDetailedLabel ? (
          <div className={classnames(styles.DetailedLabel, 'tLabel')}>
            {detailedLabel
              .replace('\n', '.')
              .split('.')
              .filter((text) => text.trim().length > 0)
              .map((item, i) => (
                <span key={i.toString()}>{item}</span>
              ))}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  isChecked: PropTypes.string,
  label: PropTypes.string,
  detailedLabel: PropTypes.string,
  onChange: PropTypes.func,
};

Checkbox.defaultValues = {
  isChecked: false,
  detailedLabel: '',
};

export default Checkbox;
