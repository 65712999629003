import React from 'react';
import styles from './LoadingOverlay.module.scss';
import LoadingSpinner from '../Spinners';

const LoadingOverlay = ({ relativeToContainer }) => (
  <div className={styles.LoadingOverlay}>
    <LoadingSpinner spin relativeToContainer={relativeToContainer} />
  </div>
);

export default LoadingOverlay;
