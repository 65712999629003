import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Form from '../../../common/components/Form';
import Input from '../../../common/components/Input/Input';
import * as Button from '../../../common/components/Buttons';
import Translate from '../../../common/components/Misc/Translate';
import SignPage, { PAGE_TYPE_SIGN_IN } from '../SignPage';
import AuthHelper from '../../../helpers/Auth';

/*
    Form which sends the password recovery requests.
 */
class RecoverPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // holds the content of the password input.
      password: '',
      // holds the content of the password2 input.
      password2: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { recoveryToken, onPageChange } = this.props;
    const { password, password2 } = this.state;
    AuthHelper.recoverPassword(recoveryToken, password, password2, () =>
      onPageChange(PAGE_TYPE_SIGN_IN)
    );
  }

  render() {
    const {
      onPageChange,
      isRequesting,
      callbackBoxType,
      callbackMessage,
      userIsAuthenticated,
    } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Recover Password - Journi</title>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <Translate>
          {(translate) => (
            <Form onSubmit={this.handleSubmit}>
              <h2 className="mb3">{translate('recover_password.title')}</h2>
              <hr className="mb4" />
              <Row>
                <Col xs={12} className="mb4">
                  <Input
                    title={translate('change_password.new')}
                    type="password"
                    name="password"
                    placeholder={translate('change_password.new')}
                    onChange={this.handleInputChange}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb4">
                  <Input
                    title={translate('change_password.repeat')}
                    type="password"
                    name="password2"
                    placeholder={translate('change_password.repeat')}
                    onChange={this.handleInputChange}
                    required
                  />
                </Col>
              </Row>
              {SignPage.renderCallbackMessage(callbackBoxType, callbackMessage)}
              <Row>
                <Col xs={12} className="mb3">
                  <Button.Primary
                    buttonSize="Block"
                    type="submit"
                    display="d-block"
                    required
                    disabled={isRequesting}
                  >
                    {' '}
                    Change password{' '}
                  </Button.Primary>
                </Col>
              </Row>
              {userIsAuthenticated ? null : (
                <Row>
                  <div className="mx-auto mb2 tPlaceholder">
                    <a
                      href="#/"
                      onClick={onPageChange.bind(this, PAGE_TYPE_SIGN_IN)}
                    >
                      {translate('main.back')}
                    </a>
                  </div>
                </Row>
              )}
            </Form>
          )}
        </Translate>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  callbackMessage: state.recoverPassword.callbackMessage,
  callbackBoxType: state.recoverPassword.callbackBoxType,
  userIsAuthenticated: state.user.isAuthenticated,
  isRequesting: state.recoverPassword.isRequesting,
});

const connectedRecoverPasswordForm = connect(
  mapStateToProps,
  null
)(RecoverPasswordForm);

export default withRouter(connectedRecoverPasswordForm);
