import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { isURLExternal, removeBasePath } from '../../../utils/urlHelpers';
import Styles from './Anchor.module.scss';

/**
 *  Anchor component that renders a Link component or an html anchor depending whether the provided
 *  href is an external url or not.
 */
const Anchor = (props) => {
  // staticContext can not be provided as property value, so it is excluded from ...rest.
  const {
    href,
    children,
    staticContext,
    noDecoration,
    className,
    ...rest
  } = props; // eslint-disable-line no-unused-vars

  const classes = classnames(
    { [Styles.NoDecoration]: noDecoration },
    className
  );

  if (href) {
    const hrefWithoutBasePath = removeBasePath(href);
    if (isURLExternal(hrefWithoutBasePath)) {
      return (
        <a href={href} className={classes} {...rest}>
          {children}
        </a>
      );
    }
    return (
      <Link to={hrefWithoutBasePath} className={classes} {...rest}>
        {children}
      </Link>
    );
  }
  return (
    <a role="button" tabIndex="0" {...rest} className={classes}>
      {children}
    </a>
  );
};

Anchor.propTypes = {
  /**
   * href. href attribute of the anchor component.
   */
  href: PropTypes.string.isRequired,
  noDecoration: PropTypes.bool,
};

Anchor.defaultProps = {
  noDecoration: false,
};

export default withRouter(Anchor);
