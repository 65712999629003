import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Helmet } from 'react-helmet';
import Input from '../../../common/components/Input/Input';
import * as Button from '../../../common/components/Buttons';
import Form from '../../../common/components/Form';
import Translate from '../../../common/components/Misc/Translate';
import SignPage, { PAGE_TYPE_SIGN_FORGOT } from '../SignPage';

import AuthHelper from '../../../helpers/Auth';

/*
  Login form component.
 */
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // holds the content of the email input.
      email: '',
      // holds the content of the password input.
      password: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;
    AuthHelper.login(email, password);
  }

  render() {
    const {
      onPageChange,
      paths: { privacyPage, termsPage },
      isRequesting,
      callbackBoxType,
      callbackMessage,
    } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Login - Journi</title>
        </Helmet>
        <Translate>
          {(translate) => (
            <Form onSubmit={this.handleSubmit}>
              <h2 className="mb3 mt0">{translate('main.sign_in')}</h2>
              <Row>
                <Col xs={12} className="mb4">
                  <Input
                    title={translate('main.email')}
                    type="email"
                    name="email"
                    placeholder="email@example.com"
                    onChange={this.handleInputChange}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb4">
                  <Input
                    title={translate('main.password')}
                    type="password"
                    name="password"
                    placeholder={translate('main.password')}
                    onChange={this.handleInputChange}
                    required
                  />
                </Col>
              </Row>
              {SignPage.renderCallbackMessage(callbackBoxType, callbackMessage)}
              <div className="mb2 tPlaceholder">
                <a
                  href="#/"
                  onClick={onPageChange.bind(this, PAGE_TYPE_SIGN_FORGOT)}
                >
                  {translate('login.forgot_password')}
                </a>
              </div>
              <Button.Primary
                type="submit"
                className="mb2"
                buttonSize="Block"
                disabled={isRequesting}
              >
                {translate('main.sign_in')}
              </Button.Primary>
              <div className="tPlaceholder">
                By clicking this button, you agree to journi's{' '}
                <a href={privacyPage}>Privacy Policy </a>
                and <a href={termsPage}>Terms of Service</a>
              </div>
            </Form>
          )}
        </Translate>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  callbackMessage: state.login.callbackMessage,
  callbackBoxType: state.login.callbackBoxType,
  isRequesting: state.login.isRequesting,
  inviteToken: state.invitation.token,
  paths: state.configuration.paths,
});

const connectedSignInForm = connect(mapStateToProps, null)(LoginForm);

export default withRouter(connectedSignInForm);
