import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import ModalContainer, { MODAL_TYPES } from '../ModalContainer';

import * as journiAPI from '../../../../network/journiAPI';
import FollowerModalItem from './FollowerModalItem';
import styles from './FollowersModal.module.scss';

class FollowersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followers: {},
      isRequesting: true,
    };
  }

  componentDidMount() {
    this.getFollowers();
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;
    const isNowModalVisible = !prevProps.isVisible && isVisible;
    if (isNowModalVisible) {
      this.getFollowers();
    }
  }

  setRequestingState(isRequesting) {
    this.setState({
      isRequesting,
    });
  }

  getFollowers() {
    const { userId } = this.props;

    journiAPI
      .fetchUserFollowers(userId)
      .then((response) => {
        this.setState({
          followers: response.users,
          isRequesting: false,
        });
      })
      .catch(() => {
        this.setRequestingState(false);
      });
  }

  render() {
    const { isRequesting, followers } = this.state;
    const { translatedMessages, userId, onClose } = this.props;
    const followersNumber = followers.length;

    const onFollowAction = () => this.getFollowers();

    const header = (
      <h2>
        {translatedMessages('main.followers')} {followersNumber}
      </h2>
    );

    const content = (
      <div className={styles.Followers}>
        {followersNumber > 0 &&
          followers.map((follower) => (
            <FollowerModalItem
              key={follower.userId}
              userId={userId}
              follower={follower}
              onFollowAction={onFollowAction}
            />
          ))}
      </div>
    );

    return (
      <ModalContainer
        modalType={MODAL_TYPES.FOLLOWERS}
        header={header}
        content={content}
        isLoading={isRequesting}
        onClose={onClose}
      />
    );
  }
}

FollowersModal.propTypes = {
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FollowersModal);
