/**
 * Gets the width of the device's screen.
 * @return {number}
 */
const getScreenWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export default { getScreenWidth };
