import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import connect from 'react-redux/es/connect/connect';
import moment from 'moment';
import update from 'immutability-helper';
import { Helmet } from 'react-helmet';
import Input from '../../common/components/Input/Input';
import RadioInput from '../../common/components/Input/RadioInput';
import styles from './SettingsPage.module.scss';
import { getCSSColor, longToFormattedDate } from '../../utils/converters';
import * as Button from '../../common/components/Buttons';
import { BUTTON_SIZES } from '../../common/components/Buttons';
import { toastFail, toastSuccess } from '../../common/components/Toasts';
import DateInput from '../../common/components/Input/DateInput';
import Checkbox from '../../common/components/Input/Checkbox';
import { updateProfileSettings } from '../../network/journiAPI';
import { BasicBadge, PremiumBadge } from '../../common/components/Badges';
import ChangePasswordModal from '../../common/components/Modals/Settings/ChangePasswordModal';
import ChangeEmailModal from '../../common/components/Modals/Settings/ChangeEmailModal';
import UserProfileHeader from '../../common/components/Misc/UserProfileHeader';
import Container from '../../common/components/Containers/Container';
import {
  getImageURL,
  IMAGE_FORMAT_TYPE_MOBILE_FULL,
} from '../../utils/urlHelpers';
import DeleteAccountModal from '../../common/components/Modals/Settings/DeleteAccountModal';
import * as journiAPI from '../../network/journiAPI';

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: props.userProfile,
      isChangePasswordModalVisible: false,
      isChangeEmailModalVisible: false,
      isDeleteAccountModalVisible: false,
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.closeModals = this.closeModals.bind(this);
    this.openChangeEmailModal = this.openChangeEmailModal.bind(this);
    this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
  }

  closeModals = () =>
    this.setState({
      isChangePasswordModalVisible: false,
      isChangeEmailModalVisible: false,
      isDeleteAccountModalVisible: false,
    });

  openChangePasswordModal = () =>
    this.setState({
      isChangePasswordModalVisible: true,
    });

  openChangeEmailModal = () =>
    this.setState({
      isChangeEmailModalVisible: true,
    });

  openDeleteAccountModal = () =>
    this.setState({
      isDeleteAccountModalVisible: true,
    });

  handleCheckboxChange(event) {
    const eventTargetName = event.target.name;
    const eventTargetChecked = event.target.checked;
    this.setState(
      update(this.state, {
        userProfile: {
          notifications: {
            [eventTargetName]: { email: { $set: eventTargetChecked } },
          },
        },
      })
    );
  }

  handleInputChange(event) {
    const eventName = event.target.name;
    const eventValue = event.target.value;

    this.setState((prevState) => ({
      userProfile: {
        ...prevState.userProfile,
        [eventName]: eventValue,
      },
    }));
  }

  handleSubmit(event) {
    event.preventDefault();

    const { translatedMessages } = this.props;
    let {
      firstName,
      lastName,
      location,
      tagline,
      birthdate,
      isMetric,
      gender,
      notifications,
    } = this.state.userProfile;
    // Parse
    gender = parseInt(gender);
    isMetric = isMetric === 'true' || isMetric === true;

    // Avoids uninterrupted successive requests
    const onRequestFinished = () =>
      setTimeout(() => this.setState({ isRequesting: false }), 1000);
    this.setState({
      isRequesting: true,
    });

    updateProfileSettings({
      firstName,
      lastName,
      location,
      tagline,
      birthdate,
      isMetric,
      gender,
      notifications,
    }).then(
      (response) => {
        toastSuccess(translatedMessages('main.changes_saved'));
        journiAPI.fetchUserProfile();
        onRequestFinished(response);
      },
      (error) => {
        toastFail(translatedMessages('main.error.changes_not_saved'));
        onRequestFinished(error);
      }
    );
  }

  render() {
    const { translatedMessages, userProfile, paths } = this.props;
    const {
      isRequesting,
      isChangePasswordModalVisible,
      isChangeEmailModalVisible,
      isDeleteAccountModalVisible,
    } = this.state;
    const {
      firstName,
      lastName,
      location,
      tagline,
      birthdate,
      isMetric,
      gender,
      notifications,
      isPremium,
      email,
      coverColor,
      pictureGuid,
      coverGuid,
    } = userProfile;
    const parsedBirthday = moment(longToFormattedDate(birthdate));

    // Setting profile variables
    const coverPictureUrl = getImageURL(
      paths.picture,
      coverGuid,
      IMAGE_FORMAT_TYPE_MOBILE_FULL
    );
    const userProfilePictureUrl = getImageURL(
      paths.picture,
      pictureGuid,
      IMAGE_FORMAT_TYPE_MOBILE_FULL
    );
    const coverCSSColor = getCSSColor(coverColor);
    this.genderOptions = [
      {
        id: 1,
        name: translatedMessages('main.female'),
        title: translatedMessages('main.female'),
        value: 1,
      },
      {
        id: 2,
        name: translatedMessages('main.male'),
        title: translatedMessages('main.male'),
        value: 2,
      },
      {
        id: 3,
        name: translatedMessages('main.other'),
        title: translatedMessages('main.other'),
        value: 3,
      },
    ];

    this.isMetricOptions = [
      {
        id: 'fahrenheit',
        name: translatedMessages('main.fahrenheit'),
        title: translatedMessages('main.fahrenheit'),
        value: false,
      },
      {
        id: 'celsius',
        name: translatedMessages('main.celsius'),
        title: translatedMessages('main.celsius'),
        value: true,
      },
    ];

    return (
      <React.Fragment>
        <Helmet>
          <title>Settings - Journi</title>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <div>
          <UserProfileHeader
            coverColor={coverCSSColor}
            coverPictureUrl={coverPictureUrl}
            userPictureColor={coverCSSColor}
            userPictureUrl={userProfilePictureUrl}
            isOwnProfile
          />
          <Container withHorizontalPadding>
            <div className={styles.ProfileSettings}>
              {isChangePasswordModalVisible && (
                <ChangePasswordModal onClose={this.closeModals} />
              )}
              {isChangeEmailModalVisible && (
                <ChangeEmailModal onClose={this.closeModals} />
              )}
              {isDeleteAccountModalVisible && (
                <DeleteAccountModal onClose={this.closeModals} />
              )}
              <div className={[styles.Section, styles.Head].join(' ')}>
                <Row>
                  <Col xs={6} md={8}>
                    <h1>{translatedMessages('main.settings')}</h1>
                  </Col>
                  <Col xs={6} md={4}>
                    <div className={styles.SaveContainer}>
                      <Button.Primary
                        type="submit"
                        buttonSize={BUTTON_SIZES.SHORT}
                        disabled={isRequesting}
                        form="settingsForm"
                      >
                        {translatedMessages('button.save')}
                      </Button.Primary>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className={[styles.Section, styles.Membership].join(' ')}>
                <h2>{translatedMessages('main.my_account')}</h2>
                {isPremium ? (
                  <PremiumBadge
                    title={translatedMessages('main.premium')}
                    mainColor={coverCSSColor}
                  />
                ) : (
                  <BasicBadge
                    title={translatedMessages('main.basic')}
                    mainColor={coverCSSColor}
                  />
                )}
              </div>
              <form
                id="settingsForm"
                onSubmit={(event) => this.handleSubmit(event)}
                method="post"
              >
                <Row className={styles.Section}>
                  <Col xs={12} md={4}>
                    <Input
                      className="mb2"
                      name="firstName"
                      value={firstName}
                      title={translatedMessages('main.first_name')}
                      placeholder={translatedMessages(
                        'main.first_name_placeholder'
                      )}
                      onChange={this.handleInputChange}
                      required
                      maxLength={20}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Input
                      className="mb2"
                      name="lastName"
                      value={lastName}
                      title={translatedMessages('main.last_name')}
                      placeholder={translatedMessages(
                        'main.last_name_placeholder'
                      )}
                      onChange={this.handleInputChange}
                      required
                      maxLength={20}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Input
                      className="mb2"
                      name="location"
                      value={location}
                      title={translatedMessages('settings.location')}
                      placeholder={translatedMessages(
                        'settings.location_placeholder'
                      )}
                      onChange={this.handleInputChange}
                      maxLength={40}
                    />
                  </Col>

                  <Col xs={12} md={8}>
                    <Input
                      className="mb2"
                      name="tagline"
                      value={tagline}
                      title={translatedMessages('main.about_me')}
                      placeholder={translatedMessages(
                        'main.about_me_placeholder'
                      )}
                      type="textarea"
                      onChange={this.handleInputChange}
                      maxLength={200}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <DateInput
                      className="mb2"
                      name="birthdate"
                      year={parsedBirthday.year()}
                      month={parsedBirthday.month() + 1}
                      day={parsedBirthday.date()}
                      onChange={this.handleInputChange}
                      title={translatedMessages('settings.birthday')}
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <Input
                      className="mb2"
                      name="model_email"
                      value={email}
                      title={translatedMessages('main.email')}
                      type="text"
                      iconClass="icon icon-pen-streamline"
                      borders={false}
                      readOnly
                      onClick={this.openChangeEmailModal}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Input
                      className="mb2"
                      name="model_password"
                      value={email}
                      title={translatedMessages('main.password')}
                      type="password"
                      iconClass="icon icon-pen-streamline"
                      borders={false}
                      readOnly
                      onClick={this.openChangePasswordModal}
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <RadioInput
                      className="mb2"
                      name="isMetric"
                      value={isMetric}
                      title={translatedMessages('main.temperature')}
                      options={this.isMetricOptions}
                      onChange={this.handleInputChange}
                      required
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <RadioInput
                      className="mb2"
                      name="gender"
                      value={gender}
                      title={translatedMessages('main.gender')}
                      options={this.genderOptions}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>

                <div
                  className={[
                    styles.Section,
                    styles.EmailNotificationSection,
                  ].join(' ')}
                >
                  <div className="tLabel mb4">
                    {translatedMessages('main.email_notifications')}
                  </div>
                  <Row>
                    <Col xs={12} md={6}>
                      <Checkbox
                        className="mb2"
                        name="trip_like"
                        value={notifications.trip_like.email}
                        label={translatedMessages('notifications.like')}
                        onChange={this.handleCheckboxChange}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Checkbox
                        className="mb2"
                        name="trip_invite"
                        value={notifications.trip_invite.email}
                        label={translatedMessages('notifications.request')}
                        detailedLabel={translatedMessages(
                          'notifications.request_detail'
                        )}
                        options={this.isMetricOptions}
                        onChange={this.handleCheckboxChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Checkbox
                        className="mb2"
                        name="trip_update"
                        value={notifications.trip_update.email}
                        label={translatedMessages('notifications.update')}
                        detailedLabel={translatedMessages(
                          'notifications.update_detail'
                        )}
                        onChange={this.handleCheckboxChange}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Checkbox
                        className="mb2"
                        name="recommendation"
                        value={notifications.recommendation.email}
                        label={translatedMessages(
                          'notifications.recommendation'
                        )}
                        detailedLabel={translatedMessages(
                          'notifications.recommendation_detail'
                        )}
                        onChange={this.handleCheckboxChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Checkbox
                        className="mb2"
                        name="offer"
                        value={notifications.offer.email}
                        label={translatedMessages('notifications.offers')}
                        detailedLabel={translatedMessages(
                          'notifications.offers_detail'
                        )}
                        onChange={this.handleCheckboxChange}
                      />
                    </Col>
                  </Row>
                  <div className={styles.SaveContainer}>
                    <Button.Primary
                      type="submit"
                      buttonSize={BUTTON_SIZES.SHORT}
                      disabled={isRequesting}
                      form="settingsForm"
                    >
                      {translatedMessages('button.save')}
                    </Button.Primary>
                  </div>
                </div>

                {/* Delete account option */}
                <div
                  className={[
                    styles.Section,
                    styles.LastSection,
                    styles.EmailNotificationSection,
                  ].join(' ')}
                >
                  <Button.Secondary onClick={this.openDeleteAccountModal}>
                    {translatedMessages('settings.delete_account')}
                  </Button.Secondary>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  paths: state.configuration.paths,
  translatedMessages: state.configuration.translatedMessages,
  userProfile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
