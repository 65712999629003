import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import styles from './styles.module.scss';
import Container from '../../../../common/components/Containers/Container';
import {
  BUTTON_SIZES,
  Follow,
  FollowCancelRequest,
  moreDropdownButton,
  Primary,
  Secondary,
  ShareTrip,
  UnFollow,
} from '../../../../common/components/Buttons';
import UserAvatar, {
  AVATAR_TYPES,
} from '../../../../common/components/Avatars/UserAvatar';
import AdditionalItemsAvatar, {
  ADDITIONAL_ITEMS_TYPES,
} from '../../../../common/components/Avatars/AdditionalItemsAvatar';
import ScrollButton, {
  ORIENTATION_DOWNWARDS,
} from '../../../../common/components/Buttons/ScrollButton';

/**
 * Component that renders differently according to the provided privilege-related properties. The following
 * cases are covered:
 * - Trip is public, user is not following it.
 * - Trip is public, user is following it.
 * - Trip is private, user is not following it.
 * - Trip is private, user is waiting for follow request approval from the trip's owner.
 * - Trip is private, user is the original author.
 * - Trip is private, user is a collaborator.
 */
const TimeLineHeader = ({
  coverImageURL,
  coverColor,
  originalAuthor,
  authors,
  additionalAuthors,
  title,
  translations,
  places,
  onShareButtonClick,
  onFollowerButtonClick,
  onFollowButtonClick,
  onPrintTripClick,
  onChevronClick,
  isAuthor,
  isFollowing,
  isTripPrivate,
  isFollowRequestPending,
  mapComponent,
  isContentDisplayed,
  hasEntries,
}) => {
  const headerStyle = {
    backgroundColor: coverColor,
    backgroundImage: `url("${coverImageURL}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const hasAdditionalCollaborators = additionalAuthors > 0;

  // Add action options
  const actionOptions = [];

  let followOption = {
    text: translations.follow,
    onClick: onFollowButtonClick,
  };

  const shareOption = {
    text: translations.share,
    onClick: onShareButtonClick,
  };

  const followersButton =
    isTripPrivate && !(isAuthor || isFollowing) ? null : (
      <Secondary
        buttonSize={BUTTON_SIZES.SMALL}
        onClick={onFollowerButtonClick}
      >
        {translations.followersPhrase}
      </Secondary>
    );

  // Display the right action depending on the user follower / author property.
  let followButton = (
    <Follow message={translations.follow} onClick={onFollowButtonClick} />
  );

  if (isAuthor) {
    followButton = null;
    followOption = null;
  } else if (isFollowing) {
    followButton = (
      <UnFollow
        message={translations.stopFollowing}
        onClick={onFollowButtonClick}
      />
    );
    followOption = {
      text: translations.stopFollowing,
      onClick: onFollowButtonClick,
    };
  } else if (isTripPrivate) {
    if (isFollowRequestPending) {
      followButton = (
        <FollowCancelRequest
          message={translations.cancelRequest}
          onClick={onFollowButtonClick}
        />
      );
      followOption = {
        text: translations.cancelRequest,
        onClick: onFollowButtonClick,
      };
    } else {
      followOption = {
        text: translations.requestFollow,
        onClick: onFollowButtonClick,
      };
    }
  }

  // Add options to dropdown menu (visible only in small screens)
  if (followOption) actionOptions.push(followOption);
  if (shareOption) actionOptions.push(shareOption);
  const MoreActionsButton = moreDropdownButton(actionOptions);

  return (
    <div className={styles.Container}>
      <div className={classNames(styles.Header)} style={headerStyle}>
        <Container className={classNames(styles.Body)} withHorizontalPadding>
          <div className={styles.Actions}>
            <div className={styles.OnlyVisibleInLarge}>
              {followersButton}
              {followButton}

              {isAuthor && hasEntries ? (
                <Primary
                  buttonSize={BUTTON_SIZES.SMALL}
                  onClick={onPrintTripClick}
                >
                  {translations.print}
                </Primary>
              ) : null}

              <ShareTrip onClick={onShareButtonClick} />
            </div>
            <div className={styles.OnlyVisibleInSmall}>
              <MoreActionsButton />
            </div>
          </div>

          {
            <div className={styles.Authors}>
              <div className={styles.OriginalAuthor}>
                <UserAvatar
                  userProfileURL={originalAuthor.userURL}
                  pictureURL={originalAuthor.pictureURL}
                  backgroundColor="black"
                  type={AVATAR_TYPES.AUTHOR}
                />
              </div>
              <div className={styles.Collaborators}>
                {authors.map((author) => (
                  <UserAvatar
                    key={author.userURL + author.firstName}
                    userProfileURL={author.userURL}
                    pictureURL={author.pictureURL}
                    backgroundColor="black"
                    type={AVATAR_TYPES.AUTHOR}
                  />
                ))}
              </div>
              {hasAdditionalCollaborators ? (
                <div className={styles.AdditionalCollaborators}>
                  <AdditionalItemsAvatar
                    type={ADDITIONAL_ITEMS_TYPES.ADDITIONAL_TRIPS}
                    count={additionalAuthors}
                  />
                </div>
              ) : null}
            </div>
          }
        </Container>
      </div>

      {
        <div className={styles.Footer}>
          <Container withHorizontalPadding>
            <div className={styles.FooterBody}>
              <div className={styles.TripTitle}>
                <h1>{title}</h1>
              </div>
              <div className={[styles.Description, 'pt1'].join(' ')}>
                <div className={styles.Moments}>
                  {translations.momentsPhrase}
                </div>
                {places ? (
                  <React.Fragment>
                    <span className={styles.Separator} />
                    <div className={styles.Places}>{places}</div>
                  </React.Fragment>
                ) : null}
              </div>
              <div className={styles.OnlyVisibleInSmall}>
                <LazyLoad height="100%">
                  <div className={[styles.Map, 'py2'].join(' ')}>
                    {mapComponent}
                  </div>
                </LazyLoad>
                <div className={styles.FooterActions}>
                  <div className={styles.FollowersButtonWrapper}>
                    {followersButton}
                  </div>
                  {isContentDisplayed && (
                    <div className={styles.ChevronWrapper}>
                      <ScrollButton
                        orientation={ORIENTATION_DOWNWARDS}
                        onClick={onChevronClick}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      }
    </div>
  );
};

TimeLineHeader.propTypes = {
  /**
   * URL of the cover image
   */
  coverImageURL: PropTypes.string.isRequired,
  /**
   * CSS color displayed as fallback for the cover image
   */
  coverColor: PropTypes.string.isRequired,
  /**
   * Title of the trip.
   */
  title: PropTypes.string.isRequired,
  /**
   * Translated phrase for the ammount of moments the trip has (e.g., 21 moments, 1 momento, etc.).
   */
  translations: PropTypes.shape({
    // Translated phrase for the amount of moments the trip has (e.g., 21 moments, 1 momento, etc.).
    momentsPhrase: PropTypes.string,
    // Translated phrase for the amount of followers the trip has (e.g., 21 followers, 1 seguidor, etc.).
    followersPhrase: PropTypes.string,
    follow: PropTypes.string,
    share: PropTypes.string,
    stopFollowing: PropTypes.string,
    cancelRequest: PropTypes.string,
    requestFollow: PropTypes.string,
    print: PropTypes.string,
  }),
  /**
   * Text containing the places of the trip .
   */
  places: PropTypes.string.isRequired,
  /**
   * Number that will be displayed next to the authors/collaborators avatar pictures and that indicates
   * the ammount of authors/collaborators that were hidden.
   */
  additionalAuthors: PropTypes.number,
  // Actions
  /**
   * Callback function called when the user clicks the share button.
   */
  onShareButtonClick: PropTypes.func.isRequired,
  /**
   * Callback function called when the user clicks the followers button.
   */
  onFollowerButtonClick: PropTypes.func.isRequired,
  /**
   * Callback function called when the user clicks the follow button.
   */
  onFollowButtonClick: PropTypes.func.isRequired,
  /**
   * Callback function called when the user wants to print a trip.
   */
  onPrintTripClick: PropTypes.func.isRequired,
  /**
   * Callback function called when the user clicks the chevron button (displayed only in small screens).
   */
  onChevronClick: PropTypes.func.isRequired,
  // Privacy
  /**
   * Object containing the data of the original author of the trip.
   */
  originalAuthor: PropTypes.object.isRequired,
  /**
   * Array of objects containing the data of the authors/collaborators of the trip.
   */
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Boolean value that determines the available options in the timeline header. True if the trip is private.
   */
  isTripPrivate: PropTypes.bool,
  /**
   * Boolean value that determines the available options in the timeline header. True if the visiting user is
   * the trip's owner.
   */
  isOwner: PropTypes.bool,
  /**
   * Boolean value that determines the available options in the timeline header. True if the visiting user is
   * the or one of the trip's authors.
   */
  isAuthor: PropTypes.bool,
  /**
   * Boolean value that determines the available options in the timeline header. True if the visiting user is
   * the following the current trip.
   */
  isFollowing: PropTypes.bool,
  /**
   * Boolean value that determines the follow request button type. True if the user already submitted a follow request
   * that has not been accepted or rejected yet.
   */
  isFollowRequestPending: PropTypes.bool,
  /**
   * Map component that will be rendered in the mobile view of the timeline header.
   */
  mapComponent: PropTypes.node.isRequired,
  /**
   * Boolean value that fits the header when no elements are around it.
   */
  isContentDisplayed: PropTypes.bool,
  /**
   * Check if the trip has entries.
   */
  hasEntries: PropTypes.bool,
};

TimeLineHeader.defaultProps = {
  additionalAuthors: 0,
  isOwner: false,
  isAuthor: false,
  isFollowing: false,
  isFollowRequestPending: false,
  isTripPrivate: false,
  translations: {
    /*
     * Translated phrase for the amount of moments the trip has (e.g., 21 moments, 1 momento, etc.).
     */
    momentsPhrase: '21 moments',
    /*
     * Translated phrase for the amount of followers the trip has (e.g., 21 followers, 1 seguidor, etc.).
     */
    followersPhrase: '3 followers',
    follow: 'Follow',
    share: 'Share',
    stopFollowing: 'Unfollow',
    cancelRequest: 'Cancel request',
    requestFollow: 'Request follow',
  },
  isContentDisplayed: false,
  hasEntries: true,
};

export default TimeLineHeader;
