import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  addRatiosHorizontally,
  addRatiosVertically,
  AXIS_TYPES,
  getSaturatedRatio,
  isLandscape,
  isPortrait,
} from '../../../utils/imageHelper';
import { average, sum } from '../../../utils/arrays';
import RatioContainer from '../Containers/RatioContainer';
import ImageGridConatiner from '../../../screens/TripTimelinePage/components/ImageGridConatiner';
import styles from './styles.module.scss';

/**
 * The image grid component displays pictures in different layouts according the ammount of pictures
 * and their aspect ratios.
 *
 */
class ImageGrid extends Component {
  /**
   * Provides the layout configuration parameters necessary to display the pictures in the grid.
   * @param {array} pictures Array of picture objects which will be rendered.
   * @return {{parentRatio: *, mainRatio: AudioParam | number | *, parentAxis: number, imageViewIndexesUsed: (number[]|Array)}}
   */
  static getGridImageConfiguration(pictures) {
    // Aspect ratio constants
    const portraitRatio = 9.0 / 16.0;
    const landscapeRatio = 16.0 / 9.0;
    const totalPictures = pictures.length;

    // Aspect ratio of the grid container
    let parentRatio;
    // Aspect ratio of the flex container which host the first picture
    let mainRatio;
    // Decides whether the parent axis is a column or a row.
    let parentAxis;
    // Each layout case defines different layout indexes from the global layout,
    // so they need to be stored for later rendering.
    // e.g. (for different layouts)
    // [ 1 ] [ 2 ]   or    [ 0 ]
    // [ 3 ] [ 4 ]         [ 2 ]
    let imageViewIndexesUsed;

    // Aspect rations for the different pictures
    let ratio0 = 0;
    let ratio1 = 0;
    let ratio2 = 0;
    let ratio3 = 0;
    let ratio4 = 0;

    // The Grid Image's configuration variables are set according the amount of available pictures.
    switch (true) {
      case totalPictures === 1:
        // [] in range [9:16,16:9]
        ratio0 = pictures[0].ratio;
        parentRatio = getSaturatedRatio(
          pictures[0].ratio,
          portraitRatio,
          landscapeRatio
        );
        mainRatio = pictures[0].ratio;
        parentAxis = AXIS_TYPES.VERTICAL;
        if (isLandscape(parentRatio)) {
          parentAxis = AXIS_TYPES.HORIZONTAL;
        }
        imageViewIndexesUsed = [0];
        break;
      case totalPictures === 2:
        ratio0 = pictures[0].ratio;
        ratio1 = pictures[1].ratio;
        if (isLandscape(ratio0) && isLandscape(ratio1)) {
          // [ ]
          // [ ]
          const combinedVerticalRatio = addRatiosVertically(ratio0, ratio1);
          const saturatedCombinedRatio = Math.max(
            portraitRatio,
            Math.min(combinedVerticalRatio, landscapeRatio)
          );
          if (saturatedCombinedRatio !== combinedVerticalRatio) {
            const scale = saturatedCombinedRatio / combinedVerticalRatio;
            ratio0 *= scale;
          }

          parentRatio = saturatedCombinedRatio;
          mainRatio = ratio0;
          parentAxis = AXIS_TYPES.VERTICAL;
          imageViewIndexesUsed = [0, 2];
        } else {
          // [][]
          ratio0 = getSaturatedRatio(portraitRatio, ratio0, landscapeRatio);
          ratio1 = getSaturatedRatio(portraitRatio, ratio1, landscapeRatio);
          const combinedRatio = getSaturatedRatio(
            portraitRatio,
            addRatiosHorizontally(ratio0, ratio1),
            landscapeRatio
          );

          parentRatio = combinedRatio;
          mainRatio = ratio0;
          parentAxis = AXIS_TYPES.HORIZONTAL;
          imageViewIndexesUsed = [0, 2];
        }
        break;
      case totalPictures === 3:
        ratio0 = pictures[0].ratio;
        ratio1 = pictures[1].ratio;
        ratio2 = pictures[2].ratio;
        ratio0 = getSaturatedRatio(portraitRatio, ratio0, landscapeRatio);
        ratio1 = getSaturatedRatio(portraitRatio, ratio1, landscapeRatio);
        ratio2 = getSaturatedRatio(portraitRatio, ratio2, landscapeRatio);

        if (isLandscape(ratio0)) {
          // [    ]
          // [ ][ ]
          // 1s image takes full width and can keep its aspect ratio. Second row average ratio.

          const secondRowAspectRatio = 2.0 * average(ratio1, ratio2);
          const combinedRatio = addRatiosVertically(
            ratio0,
            secondRowAspectRatio
          );

          parentRatio = combinedRatio;
          mainRatio = ratio0;
          parentAxis = AXIS_TYPES.VERTICAL;
          imageViewIndexesUsed = [0, 2, 3];
        } else {
          // [  ][]
          // [  ][]
          // 1s image takes full height and can keep its aspect ratio. Remaining 3 images take 2nd column which
          // has the average ratio between the three.

          const averageSecondColumnRatio = average(ratio1, ratio2);
          const secondColumnAspectRatio = addRatiosVertically(
            averageSecondColumnRatio,
            averageSecondColumnRatio
          );
          const combinedRatio = addRatiosHorizontally(
            ratio0,
            secondColumnAspectRatio
          );

          parentRatio = combinedRatio;
          mainRatio = ratio0;
          parentAxis = AXIS_TYPES.HORIZONTAL;
          imageViewIndexesUsed = [0, 2, 3];
        }
        break;
      case totalPictures === 4:
        ratio0 = pictures[0].ratio;
        ratio1 = pictures[1].ratio;
        ratio2 = pictures[2].ratio;
        ratio3 = pictures[3].ratio;
        ratio0 = getSaturatedRatio(portraitRatio, ratio0, landscapeRatio);
        ratio1 = getSaturatedRatio(portraitRatio, ratio1, landscapeRatio);
        ratio2 = getSaturatedRatio(portraitRatio, ratio2, landscapeRatio);
        ratio3 = getSaturatedRatio(portraitRatio, ratio3, landscapeRatio);

        if (isLandscape(ratio0)) {
          // [    ]
          // [][][]
          // 1s image takes full width and can keep its aspect ratio. Second row average ratio.

          const secondRowAspectRatio = 3.0 * average(ratio1, ratio2, ratio3);
          const combinedRatio = addRatiosVertically(
            ratio0,
            secondRowAspectRatio
          );

          parentRatio = combinedRatio;
          mainRatio = ratio0;
          parentAxis = AXIS_TYPES.VERTICAL;
          imageViewIndexesUsed = [0, 2, 3, 4];
        } else if (isPortrait(ratio0)) {
          // [    ][]
          // |    |[]
          // [    ][]
          // 1s image takes full height and can keep its aspect ratio. Remaining 3 images take 2nd column which
          // has the average ratio between the three.

          const averageSecondColumnRatio = average(ratio1, ratio2, ratio3);
          const secondColumnAspectRatio = addRatiosVertically(
            addRatiosVertically(
              averageSecondColumnRatio,
              averageSecondColumnRatio
            ),
            averageSecondColumnRatio
          );
          const combinedRatio = addRatiosHorizontally(
            ratio0,
            secondColumnAspectRatio
          );

          parentRatio = combinedRatio;
          mainRatio = ratio0;

          parentAxis = AXIS_TYPES.HORIZONTAL;
          imageViewIndexesUsed = [0, 2, 3, 4];
        } else {
          // [ ][ ]
          // [ ][ ]

          parentRatio = 1.0;
          mainRatio = 2.0;
          parentAxis = AXIS_TYPES.VERTICAL;
          imageViewIndexesUsed = [0, 1, 2, 3];
        }
        break;
      case totalPictures >= 5:
        // [ ][ ]
        // [][][]
        ratio0 = pictures[0].ratio;
        ratio1 = pictures[1].ratio;
        ratio2 = pictures[2].ratio;
        ratio3 = pictures[3].ratio;
        ratio4 = pictures[4].ratio;
        ratio0 = getSaturatedRatio(portraitRatio, ratio0, landscapeRatio);
        ratio1 = getSaturatedRatio(portraitRatio, ratio1, landscapeRatio);
        ratio2 = getSaturatedRatio(portraitRatio, ratio2, landscapeRatio);
        ratio3 = getSaturatedRatio(portraitRatio, ratio3, landscapeRatio);
        ratio4 = getSaturatedRatio(portraitRatio, ratio4, landscapeRatio);

        const fistRowAspectRatio = ratio0 + ratio1;
        const secondRowAspectRatio = sum(ratio2, ratio3, ratio4);
        const combinedRatio = addRatiosVertically(
          fistRowAspectRatio,
          secondRowAspectRatio
        );

        parentRatio = combinedRatio;
        mainRatio = fistRowAspectRatio;
        parentAxis = AXIS_TYPES.VERTICAL;
        imageViewIndexesUsed = [0, 1, 2, 3, 4];

        break;
      default:
        parentRatio = null;
        mainRatio = null;
        parentAxis = AXIS_TYPES.VERTICAL;
        imageViewIndexesUsed = [];
        break;
    }

    const configuration = {
      parentRatio,
      mainRatio,
      parentAxis,
      imageViewIndexesUsed,
    };

    return configuration;
  }

  showRemainingQuantity(index, pictures, imageViewIndexesUsed) {
    if (imageViewIndexesUsed.length >= 5 && index === 4 && pictures.length) {
      return pictures.length + 1; // Since last image is covered by the overlay and will not be entirely visible
    }

    return 0;
  }

  renderCell(index, pictures, imageViewIndexesUsed) {
    const { onClick } = this.props;
    const indexExists = imageViewIndexesUsed.includes(index);
    // First checks if the index was set in the layout.
    if (indexExists) {
      // The picture needs to be shifted from the array to remove it from the available pictures.
      const { ratio, guid, url, previewURL, color } = pictures.shift();
      return (
        <RatioContainer ratio={ratio}>
          <ImageGridConatiner
            id={guid}
            onClick={onClick}
            imageURL={url}
            imagePreviewURL={previewURL}
            showRemainingQuantity={this.showRemainingQuantity(
              index,
              pictures,
              imageViewIndexesUsed
            )}
            placeholderColor={color}
          />
        </RatioContainer>
      );
    }
    return null;
  }

  render() {
    const { pictures } = this.props;
    const queuedPictures = pictures.slice(0);
    const layoutConfiguration = ImageGrid.getGridImageConfiguration(
      queuedPictures
    );
    const {
      parentRatio,
      mainRatio,
      parentAxis,
      imageViewIndexesUsed,
    } = layoutConfiguration;

    // CSS hack to display containers that keep their aspect ratio.
    const width = 100;
    const height = width / parentRatio;
    const containerStyles = {
      width: `${width}%`,
      height: '0',
      position: 'relative',
      paddingBottom: `${height}%`,
    };

    // Wrapper of the aspect ratio based container
    const contentsWrapperStyles = {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: parentAxis === AXIS_TYPES.HORIZONTAL ? 'row' : 'column',
    };

    return (
      <div style={containerStyles}>
        <div
          style={contentsWrapperStyles}
          className={classNames(
            {
              [styles.HorizontalWrapper]: parentAxis === AXIS_TYPES.HORIZONTAL,
            },
            { [styles.VerticalWrapper]: parentAxis === AXIS_TYPES.VERTICAL }
          )}
        >
          <RatioContainer
            ratio={mainRatio}
            primaryAxis={
              isLandscape(mainRatio)
                ? AXIS_TYPES.HORIZONTAL
                : AXIS_TYPES.VERTICAL
            }
            padding={false}
          >
            {this.renderCell(0, queuedPictures, imageViewIndexesUsed)}
            {this.renderCell(1, queuedPictures, imageViewIndexesUsed)}
          </RatioContainer>

          {imageViewIndexesUsed.includes(2) ||
          imageViewIndexesUsed.includes(3) ||
          imageViewIndexesUsed.includes(4) ? (
            <RatioContainer
              ratio={Math.abs(mainRatio / parentRatio)}
              primaryAxis={
                parentAxis === AXIS_TYPES.HORIZONTAL
                  ? AXIS_TYPES.VERTICAL
                  : AXIS_TYPES.HORIZONTAL
              }
              padding={false}
            >
              {this.renderCell(2, queuedPictures, imageViewIndexesUsed)}
              {this.renderCell(3, queuedPictures, imageViewIndexesUsed)}
              {this.renderCell(4, queuedPictures, imageViewIndexesUsed)}
            </RatioContainer>
          ) : null}
        </div>
      </div>
    );
  }
}

// TODO: Improve documentation of properties.
ImageGrid.propTypes = {
  pictures: PropTypes.array,
  onClick: PropTypes.func,
};

ImageGrid.defaultProps = {
  onClick: () => {},
};

export default ImageGrid;
