/*
  Returns true if one of the notifications was not fetched yet by the user (or has not been read yet)
*/
export function hasNewNotifications(notifications) {
  if (!notifications) {
    return false;
  }
  let hasNewNotification = false;
  for (let i = 0; i < notifications.length && !hasNewNotification; i++) {
    if (notifications[i].isNew) hasNewNotification = true;
  }
  return hasNewNotification;
}

/**
 * Checks whether the provided object obj is empty or not.
 * @param obj {object} object that will be used to check the property.
 * @return {boolean} True if the object is empty, false otherwise.
 */
export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
