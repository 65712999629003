import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import styles from './MyTrips.module.scss';
import { JourniCardContainer } from '../../common/components/Cards/Card';
import {
  getImageURL,
  getTripUrl,
  IMAGE_FORMAT_TYPE_MOBILE_RET,
} from '../../utils/urlHelpers';
import { getCSSColor } from '../../utils/converters';
import Container from '../../common/components/Containers/Container';
import * as journiAPI from '../../network/journiAPI';
import LoadingSpinner from '../../common/components/Spinners';

class FollowingTrips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followingTrips: {},
      isFetching: true,
    };
  }

  componentDidMount() {
    const { userId } = this.props;
    this.getFollowedTrips(userId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userId !== this.props.userId) {
      this.getFollowedTrips(this.props.userId);
    }
  }

  getFollowedTrips(userId) {
    this.setState({
      isFetching: true,
    });
    journiAPI.fetchFollowedTrips(userId).then((response) =>
      this.setState({
        followingTrips: response.trips,
        isFetching: false,
      })
    );
  }

  renderCollections() {
    const { followingTrips } = this.state;
    const {
      paths,
      translatedMessages,
      isOwnProfile,
      fullUserName,
    } = this.props;
    const { tripPage, picture } = paths;
    const hasTrips = followingTrips !== undefined && followingTrips.length > 0;

    let noTripsMessage = '';

    if (hasTrips) {
      return followingTrips.map((trip) => (
        <JourniCardContainer
          key={trip.id}
          title={trip.title}
          places={trip.places}
          entryCount={trip.entryCount}
          backgroundImageUrl={getImageURL(
            picture,
            trip.pictureGuid,
            IMAGE_FORMAT_TYPE_MOBILE_RET
          )}
          backgroundColor={getCSSColor(trip.pictureColor)}
          url={getTripUrl(tripPage, trip.url)}
          textColor={getCSSColor(trip.titleColor)}
          useGridColumn
        />
      ));
    }
    if (isOwnProfile) {
      noTripsMessage = translatedMessages('profile.user.empty_journis');
    } else {
      noTripsMessage = translatedMessages(
        'profile.friend.empty_journis'
      ).replace('{p1}', fullUserName);
    }

    return <div className="tLabel">{noTripsMessage}</div>;
  }

  render() {
    const { isFetching } = this.state;
    return (
      <div className={styles.Content}>
        <Container>
          <div className={styles.Collections}>
            {isFetching ? (
              <LoadingSpinner relativeToContainer spin />
            ) : (
              <Row>{this.renderCollections()}</Row>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

FollowingTrips.propTypes = {
  /*
        userId: integer, query parameter for fetching following trips
     */
  userId: PropTypes.number.isRequired,
};

FollowingTrips.defaultProps = {};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FollowingTrips);
