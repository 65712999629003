/**
 * Module dependencies
 */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import EntryHelper from '../../helpers/Entry';
import Styles from './styles.module.scss';
/**
 * UI Components
 */
import Flight from '../Flight';
import Stamp from '../Stamp';
import Sticker from '../Sticker';
import Weather from '../Weather';
import DaySection from '../DaySection';
import DateSeparator from '../DateSeparator';
import ImageGrid from '../../../../common/components/ImageGrid';

/**
 * Component
 */
const EntryWrapper = ({
  entry,
  translatedMessages,
  onLikeEntry,
  userPreferCelsius,
  onClickImage,
  pathPicture,
}) => {
  const {
    id,
    categoryId,
    comment,
    parsedCreateDate,
    parsedCreateTime,
    likeCount,
    top1LikeId,
    top2LikeId,
    commentCount,
    userLikeId,
    pictures,
    showDate,
    timezone,
    showTimezone,
  } = entry;

  const onClickGridImage = (pictureId) => {
    onClickImage(id, pictureId);
  };

  const dateSeparatorProps = {
    date: parsedCreateDate,
    showDate,
    showTimezone,
    timezone,
  };

  if (categoryId === EntryHelper.ENTRY_TYPES.FLIGHT.id) {
    const { toCode, toName, fromCode, fromName, color } = entry;

    return (
      <DateSeparator {...dateSeparatorProps}>
        <Flight
          className={Styles.Entry}
          toCode={toCode}
          toName={toName}
          fromCode={fromCode}
          fromName={fromName}
          color={color}
        />
      </DateSeparator>
    );
  }

  if (categoryId === EntryHelper.ENTRY_TYPES.WEATHER.id) {
    const { weatherId, temperature } = entry;

    return (
      <DateSeparator {...dateSeparatorProps}>
        <Weather
          className={Styles.Entry}
          weatherId={weatherId}
          temperature={temperature}
          isCelsius={userPreferCelsius}
        />
      </DateSeparator>
    );
  }

  if (categoryId === EntryHelper.ENTRY_TYPES.STICKER.id) {
    const { emoji } = entry;
    return (
      <DateSeparator {...dateSeparatorProps}>
        <Sticker
          className={classnames(Styles.Entry, Styles.Sticker)}
          emoji={emoji}
        />
      </DateSeparator>
    );
  }

  if (categoryId === EntryHelper.ENTRY_TYPES.STAMP.id) {
    /**
     * After copying a trip from production to stage,
     * we found out that the pictures field from the stamp entry object was not copied.
     */
    if (!pictures[0] || !pictures[0].guid) return null;

    const { guid } = pictures[0];

    return (
      <Stamp
        className={classnames(Styles.Entry, Styles.Stamp)}
        pathPicture={pathPicture}
        stampGuid={guid}
      />
    );
  }

  return (
    <DateSeparator {...dateSeparatorProps}>
      <DaySection
        entryId={id}
        time={parsedCreateTime}
        comment={comment}
        categoryId={categoryId}
        likeCount={likeCount}
        top1LikeId={top1LikeId}
        top2LikeId={top2LikeId}
        commentCount={commentCount}
        userLikeId={userLikeId}
        onChangeReaction={onLikeEntry}
        translatedMessages={translatedMessages}
      >
        {pictures && pictures.length ? (
          <ImageGrid pictures={pictures} onClick={onClickGridImage} />
        ) : null}
      </DaySection>
    </DateSeparator>
  );
};

/**
 * PropTypes
 */
EntryWrapper.propTypes = {
  /**
   * Function to translate keys
   */
  translatedMessages: PropTypes.func.isRequired,
  /**
   * Function to execute when user makes a reaction to a entry
   */
  onLikeEntry: PropTypes.func.isRequired,
  userPreferCelsius: PropTypes.bool,
  onClickImage: PropTypes.func.isRequired,
};

EntryWrapper.defaultProps = {
  userPreferCelsius: true,
};

/**
 * Expose Component
 */
export default EntryWrapper;
