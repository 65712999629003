import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const LoadingSpinner = ({ spin, relativeToContainer }) => (
  <div className={relativeToContainer ? styles.Relative : styles.Fixed}>
    <div className={[styles.Loader, spin ? styles.IconSpin : ''].join(' ')} />
  </div>
);

LoadingSpinner.propTypes = {
  spin: PropTypes.bool,
  relativeToContainer: PropTypes.bool,
};

LoadingSpinner.defaultProperties = {
  spin: true,
  relativeToContainer: false,
};

export default LoadingSpinner;
