/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce } from 'throttle-debounce';
import Styles from './styles.module.scss';
import getReactions from '../../helpers/ReactionsList';

/**
 * Icon id applied in onclick action
 */
const DEFAULT_REACTION_ID = 4;

/**
 * Icon button Component
 */
const Icon = ({
  urlIcon,
  name,
  keyTranslate,
  id,
  isSelected,
  onClick,
  translatedMessages,
}) => (
  <button
    className={classnames(Styles.Icon, { [Styles.IconSelected]: isSelected })}
    type="button"
    key={id}
    onClick={() =>
      onClick({
        urlIcon,
        keyTranslate,
        name,
        id,
      })
    }
  >
    <span className={Styles.OverImage}>
      <img className={Styles.IconImage} src={urlIcon} alt={name} />
    </span>

    <p className={Styles.IconLabel}>{translatedMessages(keyTranslate)}</p>
  </button>
);

/**
 * Component
 */
class ReactionsComponent extends React.Component {
  constructor(props) {
    super(props);

    const { reactionSelectedId } = props;

    this.state = {
      reactionSelected: reactionSelectedId
        ? getReactions(reactionSelectedId)
        : null,
      isOpen: false,
      reactions: getReactions(),
    };

    this.onToggle = this.onToggle.bind(this);
    this.onClickIcon = this.onClickIcon.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
    this.onHandleOutsideClick = this.onHandleOutsideClick.bind(this);
    this.onHoverOutside = this.onHoverOutside.bind(this);
    this.debouncedOnHoverOutside = debounce(150, this.onHoverOutside);
    this.hasTouchscreen = 'ontouchstart' in window;
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onHandleOutsideClick, false);
    document.removeEventListener(
      'mouseover',
      this.debouncedOnHoverOutside,
      false
    );
  }

  onToggle(handleOpen) {
    const { isOpen } = this.state;
    if (!handleOpen || !isOpen) {
      document.addEventListener('click', this.onHandleOutsideClick, false);
      document.addEventListener(
        'mouseover',
        this.debouncedOnHoverOutside,
        false
      );
    } else {
      document.removeEventListener('click', this.onHandleOutsideClick, false);
      document.removeEventListener(
        'mouseover',
        this.debouncedOnHoverOutside,
        false
      );
    }

    this.setState((prevState) => ({
      isOpen: handleOpen || !prevState.isOpen,
    }));
  }

  onHoverOutside(event) {
    const { isOpen } = this.state;
    if (isOpen && this.popup && this.node) {
      if (
        this.popup.contains(event.target) ||
        this.node.contains(event.target)
      ) {
        return;
      }
      this.setState(
        {
          isOpen: false,
        },
        () =>
          document.removeEventListener(
            'mouseover',
            this.debouncedOnHoverOutside,
            false
          )
      );
    }
  }

  onHandleOutsideClick(event) {
    if (this.node && this.node.contains(event.target)) return;
    this.onToggle();
  }

  onClickIcon(newReactionSelected) {
    const { onChange } = this.props;
    const { reactionSelected } = this.state;
    let liked = true;

    if (
      newReactionSelected &&
      reactionSelected &&
      reactionSelected.id === newReactionSelected.id
    ) {
      liked = false;
      this.setState({
        reactionSelected: null,
      });
    } else {
      this.setState({
        reactionSelected: newReactionSelected,
      });
    }

    this.onToggle();

    /**
     * We inform the parent component that this component
     *   has changed its selection
     */
    onChange(newReactionSelected, liked);
  }

  onClickButton() {
    const { reactionSelected, isOpen } = this.state;

    if (this.hasTouchscreen || isOpen) {
      this.onToggle();
    }

    if (!reactionSelected) {
      this.onClickIcon(getReactions(DEFAULT_REACTION_ID));
    } else {
      this.onClickIcon(reactionSelected);
    }
  }

  renderList() {
    const { reactions, reactionSelected } = this.state;
    const { translatedMessages } = this.props;

    return (
      <div
        className={Styles.List}
        ref={(node) => {
          this.popup = node;
        }}
      >
        {reactions.length &&
          reactions.map(({ id, name, urlIcon, keyTranslate }) => (
            <Icon
              key={id}
              id={id}
              name={name}
              urlIcon={urlIcon}
              keyTranslate={keyTranslate}
              isSelected={reactionSelected && id === reactionSelected.id}
              onClick={this.onClickIcon}
              translatedMessages={translatedMessages}
            />
          ))}
      </div>
    );
  }

  render() {
    const { children, translatedMessages } = this.props;
    const { isOpen, reactionSelected } = this.state;
    return (
      <div
        className={Styles.Reactions}
        ref={(node) => {
          this.node = node;
        }}
      >
        {isOpen && this.renderList()}

        <div
          className={Styles.ButtonTrigger}
          role="button"
          onMouseEnter={!this.hasTouchscreen ? () => this.onToggle(true) : null}
          onClick={() => this.onClickButton()}
        >
          {!reactionSelected ? (
            children
          ) : (
            <div className={Styles.ButtonSelected}>
              <img
                className={Styles.IconImage}
                src={reactionSelected.urlIcon}
                alt={reactionSelected.keyTranslate}
              />
              <p className={Styles.ButtonSelectedLabel}>
                {translatedMessages(reactionSelected.keyTranslate)}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

/**
 * PropTypes
 */
ReactionsComponent.propTypes = {
  reactionSelected: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  translatedMessages: PropTypes.func.isRequired,
};

ReactionsComponent.defaultProps = {
  reactionSelected: null,
};

/**
 * Expose Component
 */
export default ReactionsComponent;
