import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getImageURL,
  getTripUrl,
  IMAGE_FORMAT_TYPE_MOBILE_RET_PROFILE,
} from '../../../utils/urlHelpers';
import styles from './SearchBarResults.module.scss';
import Anchor from '../Misc/Anchor';

const RESULT_TYPE_TRIP = 'RESULT_TYPE_TRIP';
const RESULT_TYPE_CATEGORY = 'RESULT_TYPE_CATEGORY';

/*
  Search result container.
  Only visible after a search is performed and the search bar components has focus.
 */

class SearchBarResults extends Component {
  /*
      returns a span container with custom styling for matched text
      @param {string}    text        text string in which the matching will be performed.
      @param {string}    textToMatch text string to match.
    */
  emphasizeMatch(text, textToMatch) {
    if (textToMatch === undefined || !textToMatch) return text;
    const regex = new RegExp(`(${textToMatch}+)`, 'i');
    const parts = text.split(regex);
    for (let i = 1; i < parts.length; i += 2) {
      parts[i] = (
        <div key={i} className={styles.EmphasizedText}>
          {parts[i]}
        </div>
      );
    }
    return <span className={styles.ItemTitle}>{parts}</span>;
  }

  /**
   * Checks whether the provided item type is valid or not for rendering in the search bar results.
   * @param {string} itemType category of the item, valid values are RESULT_TYPE_TRIP && RESULT_TYPE_CATEGORY
   * @return {boolean} true if the item type is valid, false otherwise.
   */
  isValidItemType(itemType) {
    return RESULT_TYPE_CATEGORY === itemType || RESULT_TYPE_TRIP === itemType;
  }

  /*
    returns a div container with the provided trip item information
    @param {object}    tripItem      object which should at least contain title and key attributes.
    @param {string}    index         index value used by reactjs
    @param {string}    searchString  text string to match in the category title and that will be emphasized.
    @param {string}    itemType      type of trip item (explore or any other trip like my journies or journies I follow)
    @return {object}                 trip item component
   */
  renderTripItem(tripItem, index, searchString, itemType = '') {
    const { paths } = this.props;
    const { tripPage } = paths;
    let hasPicture = false;
    let itemPicture = null;
    let itemUrl = '';

    if (!this.isValidItemType(itemType)) {
      return null;
    }

    switch (itemType) {
      case RESULT_TYPE_CATEGORY:
        itemUrl = `/profile`;
        break;
      case RESULT_TYPE_TRIP:
        itemUrl = getTripUrl(tripPage, tripItem.url);
        hasPicture = true;
        break;
      default:
    }

    if (hasPicture) {
      itemPicture = (
        <div className={styles.ItemPicture}>
          <img
            src={getImageURL(
              paths.picture,
              tripItem.pictureGuid,
              IMAGE_FORMAT_TYPE_MOBILE_RET_PROFILE
            )}
            alt=""
          />
        </div>
      );
    }

    const tripItemComponent = (
      <div className={styles.Item} key={tripItem.title}>
        {itemPicture}
        <div className={styles.Category}>
          {this.emphasizeMatch(tripItem.title, searchString)}
        </div>
      </div>
    );

    return (
      <Anchor href={itemUrl} key={index}>
        {tripItemComponent}
      </Anchor>
    );
  }

  /*
       returns a div container with the provided user information
       @param {object}    user          user object which should at least contain firstName, lastName, url, trips,
                                        userId and pictureGuid attributes.
       @param {string}    index         index value used by reactjs
       @param {string}    searchString  text string to match in the category title and that will be emphasized.
    */
  renderUserItem(user, index, searchString) {
    const { paths } = this.props;
    const journisMessage = this.getJourniesEntryCountMessage(user.trips);
    // TODO: Change anchor for Link tag once the profiles pages will be rendered in react.
    return (
      <Link to={`/profile/${user.url}`} key={index}>
        <div key={user.userId} className={styles.Item}>
          <div className={styles.User}>
            <div className={styles.ItemPicture}>
              <img
                src={getImageURL(
                  paths.picture,
                  user.pictureGuid,
                  IMAGE_FORMAT_TYPE_MOBILE_RET_PROFILE
                )}
                alt=""
              />
            </div>
            <div>
              {this.emphasizeMatch(
                `${user.firstName} ${user.lastName}`,
                searchString
              )}
              <div className={[styles.UserTrips, 'tCaption'].join(' ')}>
                {journisMessage}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  /*
      returns a translated string depending on the amount of trips the provided user has.
      @param {int}    trips      number of trips the user has.
   */
  getJourniesEntryCountMessage(trips) {
    const { translatedMessages } = this.props;
    let journisMessage = '';
    if (trips > 0) {
      if (trips === 1) {
        journisMessage = `${trips} ${translatedMessages('main.trip')}`;
      } else {
        journisMessage = `${trips} ${translatedMessages('main.trips')}`;
      }
    } else {
      journisMessage = translatedMessages('profile.friend.empty_trips').replace(
        '{p1}',
        ''
      );
    }
    return journisMessage;
  }

  renderCategorySection(categories, searchString) {
    const { translatedMessages } = this.props;
    if (!categories || !categories.length) return null;

    return (
      <div>
        <div className="tSearchTitle mb2">
          {translatedMessages('main.explore')}
        </div>
        <div className={styles.CategorySectionItems}>
          {categories.map((category, index) =>
            this.renderTripItem(
              category,
              index,
              searchString,
              RESULT_TYPE_CATEGORY
            )
          )}
        </div>
      </div>
    );
  }

  renderTripsSection(trips, searchString) {
    const { translatedMessages } = this.props;
    if (!trips || !trips.length) return null;
    return (
      <div>
        <div className="tSearchTitle mb2">
          {translatedMessages('main.trips')}
        </div>
        <div className={styles.CategorySectionItems}>
          {trips.map((trip, index) =>
            this.renderTripItem(trip, index, searchString, RESULT_TYPE_TRIP)
          )}
        </div>
      </div>
    );
  }

  renderFollowedTripsSection(followedTrips, searchString) {
    const { translatedMessages } = this.props;
    if (!followedTrips || !followedTrips.length) return null;

    return (
      <div>
        <div className="tSearchTitle mb2">
          {translatedMessages('profile.trips_i_follow')}
        </div>
        <div className={styles.CategorySectionItems}>
          {followedTrips.map((followedTrip, index) =>
            this.renderTripItem(
              followedTrip,
              index,
              searchString,
              RESULT_TYPE_TRIP
            )
          )}
        </div>
      </div>
    );
  }

  renderUserSection(users, searchString) {
    const { translatedMessages } = this.props;
    if (!users || !users.length) return null;

    return (
      <div>
        <div className="tSearchTitle mb2">
          {translatedMessages('press.facts.users')}
        </div>
        <div className={styles.UserSectionItems}>
          {users.map((user, index) =>
            this.renderUserItem(user, index, searchString)
          )}
        </div>
      </div>
    );
  }

  render() {
    const { searchResults, searchString, translatedMessages } = this.props;
    const { users, categories, trips, followedTrips } = searchResults;
    const noResults = !users && !categories && !trips && !followedTrips;
    const isUsersEmpty = !users || !users.length;
    const isCategoriesEmpty = !categories || !categories.length;
    const isTripsEmpty = !trips || !trips.length;
    const isFollowedTripsEmpty = !followedTrips || !followedTrips.length;

    const emptyResults =
      isCategoriesEmpty && isUsersEmpty && isTripsEmpty && isFollowedTripsEmpty;

    if (noResults) {
      return null;
    }
    if (emptyResults) {
      return (
        <div className={styles.Container}>
          {translatedMessages('main.no_results')}
        </div>
      );
    }

    return (
      <div className={styles.Container}>
        {this.renderCategorySection(categories, searchString)}
        {this.renderUserSection(users, searchString)}
        {this.renderTripsSection(trips, searchString)}
        {this.renderFollowedTripsSection(followedTrips, searchString)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  /*
    url paths required for links
  */
  paths: state.configuration.paths,
  translatedMessages: state.configuration.translatedMessages,
});

const mapDispatchToProps = (dispatch) => ({});

SearchBarResults.propTypes = {
  // searchString: string, string that will be matched against results to emphasize.
  searchString: PropTypes.string,
  searchResults: PropTypes.object,
  noResultsFound: PropTypes.bool,
};

SearchBarResults.defaultProps = {
  noResultsFound: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarResults);
