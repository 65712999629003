import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserProfileHeader.module.scss';
import WithImageUpload from '../Input/WithImageUpload';
import * as journiAPI from '../../../network/journiAPI';

const CoverProfilePicture = (props) => {
  const { coverColor, coverPictureUrl, children } = props;
  const coverStyle = {
    background: `${coverColor} url(${coverPictureUrl}) center center / cover`,
  };

  return (
    <div className={styles.Header} style={coverStyle}>
      {children}
    </div>
  );
};

CoverProfilePicture.propTypes = {
  coverColor: PropTypes.string.isRequired,
  coverPictureUrl: PropTypes.string.isRequired,
};

CoverProfilePicture.defaultValues = {
  coverColor: 'rgb(97, 98, 121)',
};

export default WithImageUpload(
  CoverProfilePicture,
  'coverProfilePicture',
  null,
  (pictureFormData, onSuccessCallback, onErrorCallback) => {
    journiAPI.uploadPhoto(pictureFormData).then(
      (response) =>
        journiAPI.postUserPicture(null, response.guid).then(
          () => {
            journiAPI.fetchUserProfile().then(onSuccessCallback);
          },
          (error) => onErrorCallback(error)
        ),
      (error) => onErrorCallback(error)
    );
  }
);

export { CoverProfilePicture as CoverProfilePictureWithoutUpload };
