import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';

const VALIDATION_STATES = {
  ON_HOLD: 'OnHold',
  INVALID: 'Invalid',
  VALID: 'Valid',
};

/*
    Input component provides an input html element.
 */
class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      validationState: this.props.validationState,
    };
    this.onChange = this.onChange.bind(this);
  }

  /*
    The validation state will be reset after the user types the input. This is
    to invalidate a prior validation value given by the backend.
  */
  onChange(event) {
    this.props.onChange(event);
    this.setState({
      value: event.target.value,
      validationState: VALIDATION_STATES.ON_HOLD,
    });
  }

  getValidationIcon() {
    const { validationState } = this.state;
    if (validationState === VALIDATION_STATES.INVALID) {
      return 'icon icon-warning';
    }
    if (validationState === VALIDATION_STATES.VALID) {
      return 'icon icon-rounded-ckeck';
    }

    return '';
  }

  /*
    Renders the validation message only if the Validation State is not ON_HOLD
  */
  renderValidationFeedback() {
    if (this.state.validationState === VALIDATION_STATES.INVALID) {
      return (
        <div
          className={[
            styles.ValidationMessageContainer,
            'tCaption',
            'mt1',
          ].join(' ')}
        >
          <span>{this.props.validationMessage}</span>
        </div>
      );
    }
  }

  render() {
    const isTextArea = this.props.type === 'textarea';
    const isValidated =
      this.state.validationState !== VALIDATION_STATES.ON_HOLD;
    const shouldDisplayIcon = this.props.iconClass !== '' || isValidated;

    let inputComponent = (
      <input
        className={[
          styles.InputField,
          isValidated && styles.BlockInput,
          isValidated && styles.InputFieldWithIcon,
          'tInput',
        ].join(' ')}
        id={this.props.name}
        name={this.props.name}
        type={this.props.type}
        value={this.state.value}
        onChange={this.onChange}
        placeholder={this.props.placeholder}
        required={this.props.required}
        readOnly={this.props.readOnly}
        maxLength={this.props.maxLength}
        minLength={this.props.minLength}
      />
    );

    if (isTextArea) {
      inputComponent = (
        <textarea
          className={[
            this.props.isInline ? styles.InputFieldInline : styles.InputField,
            styles.TextArea,
            'tInput',
          ].join(' ')}
          id={this.props.name}
          name={this.props.name}
          value={this.state.value}
          onChange={this.onChange}
          placeholder={this.props.placeholder}
          required={this.props.required}
          readOnly={this.props.readOnly}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
          onKeyDown={(event) => {
            if (event.keyCode === 13) event.preventDefault();
          }}
        />
      );
    }

    return (
      <div
        className={styles[this.state.validationState]}
        onClick={this.props.onClick}
      >
        <label
          htmlFor={this.props.name}
          className={[
            this.props.isInline ? styles.LabelInline : styles.Label,
            'tLabel',
          ].join(' ')}
        >
          {this.props.title}
        </label>
        <div
          className={[
            styles.Input,
            isTextArea && styles.TextAreaContainer,
            this.props.isInline && styles.Inline,
            this.props.borders && styles.Borders,
            this.props.className,
          ].join(' ')}
        >
          {inputComponent}
          {shouldDisplayIcon && (
            <span
              className={[
                styles.Icon,
                this.getValidationIcon(),
                this.props.iconClass,
              ].join(' ')}
            />
          )}
        </div>
        {this.renderValidationFeedback()}
      </div>
    );
  }
}

Input.propTypes = {
  /*
        validationState: one of VALIDATION_STATES values, indicates the state of the element regarding its validation.
        "OnHold", the value of the input has not being submitted for a validation yet,
        "Invalid", the value of the input was rejected after its validation,
        "Valid", the value of the input was accepted after its validation. DEFAULT: ON_HOLD.
     */
  validationState: PropTypes.oneOf(
    Object.entries(VALIDATION_STATES).map((a) => a[1])
  ),
  /*
        validationMessage: string, provides the feedback validation message that will be displayed below the input. DEFAULT: "".
     */
  validationMessage: PropTypes.string,
  /*
        title: string, text that will be set within the label tag. DEFAULT: "".
        name: string sets id and the name of the input element.
     */
  name: PropTypes.string,
  /*
        margin: string, margin class of the element. DEFAULT: "m-0")
     */
  margin: PropTypes.string,
  /*
        type: string, it can be input or textarea.
     */
  type: PropTypes.string,
  /*
        value: string, content of the element.
     */
  value: PropTypes.string,
  /*
        placeholder: string, text that will be displayed first in the input element.
     */
  placeholder: PropTypes.string,
  /*
        isInline: boolean, true sets the element display inline.
     */
  isInline: PropTypes.bool,
  /*
        onClick: func, function called when a click event is listened on the component.
     */
  onClick: PropTypes.func,
  /*
        readOnly: bool, true avoids the input component from being edited.
     */
  readOnly: PropTypes.bool,
  /*
        iconClass: string, class of the icon that will be displayed next to the input
     */
  iconClass: PropTypes.string,
  /*
        borders: bool, if true it will show borders in input field
     */
  borders: PropTypes.bool,
};

Input.defaultProps = {
  validationState: VALIDATION_STATES.ON_HOLD,
  validationMessage: '',
  name: '',
  margin: 'm-0',
  type: 'text',
  value: '',
  placeholder: '',
  isInline: false,
  onChange: () => true,
  onClick: () => true,
  readOnly: false,
  iconClass: '',
  borders: true,
};

export default Input;
