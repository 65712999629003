import { getAssetURL } from '../../../utils/urlHelpers';

const setIconProperties = (reactionItem) => {
  const URL = getAssetURL('/icons/emojis/');
  const IMG_EXTENSION = '.svg';
  const BASE_KEY = 'like.name.';
  const { name, id } = reactionItem;

  return Object.assign(reactionItem, {
    urlIcon: URL + name + IMG_EXTENSION,
    keyTranslate: BASE_KEY + id,
  });
};

/**
 * Reacions icons list
 */
export default (findById) => {
  const REACTIONS_LIST = [
    {
      id: 2,
      name: 'cool',
    },
    {
      id: 7,
      name: 'gasp',
    },
    {
      id: 1,
      name: 'laugh',
    },
    {
      id: 9,
      name: 'heart',
    },
    {
      id: 6,
      name: 'hipster',
    },
    {
      id: 3,
      name: 'inlove',
    },
    {
      id: 8,
      name: 'cry',
    },
    {
      id: 11,
      name: 'nom',
    },
    {
      id: 4,
      name: 'smile',
    },
    {
      id: 10,
      name: 'unhappy',
    },
  ];

  /**
   * Returns only one icon filtered
   */
  if (findById) {
    let reactionFound = REACTIONS_LIST.find(({ id }) => id === findById);

    if (!reactionFound) {
      const [firstReaction] = REACTIONS_LIST;
      reactionFound = firstReaction;
    }

    return setIconProperties(reactionFound);
  }

  /**
   * returns all list of icons
   */
  return REACTIONS_LIST.map((reactionItem) => setIconProperties(reactionItem));
};
