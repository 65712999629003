import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  getIconURL,
  getImageURL,
  getTripUrl,
  getUserProfileURL,
  IMAGE_FORMAT_TYPE_MOBILE,
} from '../../../../utils/urlHelpers';
import styles from './NotificationItem.module.scss';
import * as Button from '../../Buttons';
import GroupedEntry from './GroupedItems/GroupedEntry';
import GroupedUser from './GroupedItems/GroupedUser';
import MoreIndicator from './GroupedItems/MoreIndicator';
import Anchor from '../../Misc/Anchor';
import FollowerHelper from '../../../../helpers/Follower';
import journiLogo from '../../../../assets/images/logos/journi-logo@2x.png';

export const KEY_FOLLOWER_ADDED = 'FOLLOWER_ADDED';
export const KEY_TRIP_ACCEPT = 'TRIP_ACCEPT';
export const KEY_AUTHOR_ACCEPT = 'AUTHOR_ACCEPT';
export const KEY_TRIP_ENTRY_LIKE = 'TRIP_ENTRY_LIKE';
export const KEY_TRIP_ENTRY = 'TRIP_ENTRY';
export const KEY_TRIP = 'TRIP';
export const KEY_ENTRY_COMMENT = 'ENTRY_COMMENT';

class NotificationItem extends Component {
  getGroupedUsers(users) {
    const visibleUsers = 3;
    const others =
      users.length - visibleUsers > 0 ? users.length - visibleUsers : 0;
    const { smallItem } = this.props;
    let groupedUsers = users.slice(0, visibleUsers);
    groupedUsers = groupedUsers.map((user) => (
      <GroupedUser key={user.userId} user={user} isSmall={smallItem} />
    ));
    if (others) {
      groupedUsers.push(<MoreIndicator number={others} isSmall={smallItem} />);
    }
    return groupedUsers;
  }

  getGroupedEntries(entries, tripURL) {
    const visibleEntries = 2;
    const others =
      entries.length - visibleEntries > 0 ? entries.length - visibleEntries : 0;
    const { smallItem } = this.props;

    let groupedEntries = entries.slice(0, visibleEntries);
    groupedEntries = groupedEntries.map((entry) => (
      <GroupedEntry
        key={entry.entryId}
        entry={entry}
        isSmall={smallItem}
        tripURL={tripURL}
      />
    ));

    if (others) {
      groupedEntries.push(
        <MoreIndicator
          key={Math.random()}
          number={others}
          isSmall={smallItem}
        />
      );
    }

    return groupedEntries;
  }

  renderNotificationIndicator(senderPictureURL, senderURL, isNew) {
    const { smallItem } = this.props;
    const isNewBadge = <div className={styles.IsNewBadge} />;
    const notificationIndicator = (
      <div
        className={[
          styles.NotificationIndicator,
          smallItem ? styles.NotificationIndicatorSmall : '',
        ].join(' ')}
        style={{ backgroundImage: `url('${senderPictureURL}')` }}
      >
        {isNew ? isNewBadge : null}
      </div>
    );

    return <Anchor href={senderURL}>{notificationIndicator}</Anchor>;
  }

  renderNotificationMessage(message, date, notificationLink = '') {
    const { smallItem } = this.props;
    const notificationMessage = (
      <div
        className={classNames(styles.NotificationMessageContent, {
          [styles.NotificationMessageContentSmall]: smallItem,
        })}
      >
        <div>
          {message}
          <Moment className="tLabel ml1" fromNow>
            {date}
          </Moment>
        </div>
      </div>
    );

    if (notificationLink) {
      return <Anchor href={notificationLink}>{notificationMessage}</Anchor>;
    }
    return notificationMessage;
  }

  renderGroupedElements(users, entries, tripURL) {
    const isUsersEmpty = users ? users.length === 0 : true;
    const isEntriesEmpty = entries ? entries.length === 0 : true;
    const { smallItem } = this.props;
    return (
      <div
        className={classNames(styles.GroupedElements, {
          [styles.GroupedElementsSmall]: smallItem,
        })}
      >
        {isUsersEmpty ? (
          ''
        ) : (
          <div className={styles.GroupedUsers}>
            {this.getGroupedUsers(users)}
          </div>
        )}
        {isEntriesEmpty ? (
          ''
        ) : (
          <div className={styles.GroupedEntries}>
            {this.getGroupedEntries(entries, tripURL)}
          </div>
        )}
      </div>
    );
  }

  renderNotificationPicture(
    isNoteContent,
    pictureGuidURL,
    entryIconURL,
    tripUrl
  ) {
    const { smallItem } = this.props;
    const notificationPicture = (
      <div
        className={classNames(styles.NotificationPhotoContainer, {
          [styles.NotificationPhotoContainerSmall]: smallItem,
        })}
      >
        {isNoteContent ? (
          !entryIconURL ? (
            <span className="icon icon-text" />
          ) : (
            <React.Fragment>
              <div
                className={classNames(styles.NotificationPhoto, {
                  [styles.NotificationPhotoSmall]: smallItem,
                })}
                style={{ backgroundImage: `url('${entryIconURL}')` }}
              />
            </React.Fragment>
          )
        ) : pictureGuidURL ? (
          <React.Fragment>
            <div
              className={classNames(styles.NotificationPhoto, {
                [styles.NotificationPhotoSmall]: smallItem,
              })}
              style={{ backgroundImage: `url('${pictureGuidURL}')` }}
            />
            {entryIconURL ? (
              <img
                className={classNames(styles.NotificationIcon, {
                  [styles.NotificationIconSmall]: smallItem,
                })}
                src={entryIconURL}
                alt=""
              />
            ) : (
              ''
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              className={classNames(styles.NotificationPhoto, {
                [styles.NotificationPhotoSmall]: smallItem,
              })}
              style={{ backgroundImage: `url('${entryIconURL}')` }}
            />
          </React.Fragment>
        )}
      </div>
    );

    if (tripUrl) {
      return <Anchor href={tripUrl}>{notificationPicture}</Anchor>;
    }
    return notificationPicture;
  }

  renderNotificationRequest(userId, tripId, isAuthorInvite) {
    const { translatedMessages, smallItem } = this.props;
    const acceptRequest = () =>
      FollowerHelper.editFriend(userId, tripId, isAuthorInvite, false);
    const rejectRequest = () =>
      FollowerHelper.editFriend(userId, tripId, isAuthorInvite, true);
    const requestStyle = [
      styles.NotificationRequest,
      smallItem ? styles.NotificationRequestSmall : '',
    ].join(' ');
    return (
      <div className={requestStyle}>
        <Button.Primary
          buttonSize={Button.BUTTON_SIZES.SHORT}
          onClick={acceptRequest}
        >
          {translatedMessages('main.accept')}
        </Button.Primary>

        <Button.Secondary
          buttonSize={Button.BUTTON_SIZES.SHORT}
          onClick={rejectRequest}
        >
          {translatedMessages('main.ignore')}
        </Button.Secondary>
      </div>
    );
  }

  render() {
    const { paths, notification } = this.props;

    const {
      key,
      friendUserId,
      tripId,
      tripEntryId,
      message,
      isNew,
      create,
      isDeleted,
      friendUser,
      pictureGuid,
      tripEntryLikeIconId,
      content,
      tripUrl,
    } = notification;

    // Notification which might have content's picture
    const isContent =
      KEY_TRIP === key ||
      KEY_TRIP_ACCEPT === key ||
      KEY_AUTHOR_ACCEPT === key ||
      KEY_TRIP_ENTRY_LIKE === key ||
      KEY_TRIP_ENTRY === key ||
      KEY_ENTRY_COMMENT === key;

    const isRequest = key.indexOf('_INVITE') !== -1;
    const isAuthorInvite = key.indexOf('AUTHOR_INVITE') !== -1;
    const isEntryAdded = KEY_TRIP_ENTRY === key;
    const isComment = KEY_ENTRY_COMMENT === key;
    const isPhotoContent = pictureGuid != null;
    let isNoteContent = tripEntryId > 0 && pictureGuid == null;
    const isLike = KEY_TRIP_ENTRY_LIKE === key && tripEntryLikeIconId > 0;
    const isFollowerAdded = KEY_FOLLOWER_ADDED === key;
    const isTripAccept = KEY_TRIP_ACCEPT === key;

    // Sets flag if contains grouped components
    const { users, entries } = content;
    const isUserGroup = users ? users.length > 0 : false;
    const isEntryGroup = entries ? entries.length > 1 : false;
    const isGroup = isUserGroup || isEntryGroup;

    // Flags used for layouting
    let shouldRenderGroup = false;
    const shouldRenderNotificationIndicator = true;
    let shouldRenderNotificationPicture =
      isPhotoContent || isFollowerAdded || tripEntryLikeIconId;
    const shouldRenderNotificationRequest = isRequest;
    const shouldRenderNotificationMessage = true;
    let hasContent = false;

    // Left wing notification picture (if any)
    let senderPictureURL = '';
    let senderURL = '';
    if (friendUser != null) {
      senderPictureURL = getImageURL(
        paths.picture,
        friendUser.pictureGuid,
        IMAGE_FORMAT_TYPE_MOBILE
      );
      senderURL = getUserProfileURL(friendUser.url);
    } else {
      senderPictureURL = journiLogo;
      senderURL = paths.landingPage;
    }

    // Right wing notification picture (if any)
    let pictureGuidURL = '';
    if (isPhotoContent) {
      pictureGuidURL = getImageURL(
        paths.picture,
        pictureGuid,
        IMAGE_FORMAT_TYPE_MOBILE
      );
    }

    // Checks if there is any trips' URL
    let tripURL = '';
    if (tripUrl) {
      tripURL = getTripUrl(paths.tripPage, tripUrl);
    }

    // Check and set, if exists, the entry's icon.
    let entryIconURL = '';
    if (tripEntryLikeIconId) {
      entryIconURL = getIconURL(paths.icons, tripEntryLikeIconId);
    }

    // Notification link
    let notificationLink = '';
    if (tripURL) {
      notificationLink = tripURL;
    } else if (senderURL) {
      notificationLink = senderURL;
    }

    // Layout decisioning
    if (isContent) {
      if (
        (isEntryAdded ||
          isLike ||
          isComment ||
          isFollowerAdded ||
          isTripAccept) &&
        isGroup
      ) {
        shouldRenderGroup = true;
        if (isLike || isComment || isTripAccept) {
          hasContent = true;
        }
      } else {
        hasContent = true;
        if (isPhotoContent) {
          isNoteContent = false;
        } else if (isNoteContent) {
          isNoteContent = true;
        }
      }
    } else if (isFollowerAdded && isUserGroup) {
      shouldRenderGroup = true;
    } else if (isPhotoContent) {
      hasContent = true;
      isNoteContent = false;
    }

    shouldRenderNotificationPicture =
      shouldRenderNotificationPicture && hasContent;

    return !isDeleted ? (
      <div className={styles.Notification}>
        <div className={styles.NotificationInfo}>
          {shouldRenderNotificationIndicator
            ? this.renderNotificationIndicator(
                senderPictureURL,
                senderURL,
                isNew
              )
            : ''}
          <div className={styles.NotificationMessage}>
            {shouldRenderNotificationMessage
              ? this.renderNotificationMessage(
                  message,
                  create,
                  notificationLink
                )
              : ''}
          </div>
          {shouldRenderNotificationPicture
            ? this.renderNotificationPicture(
                isNoteContent,
                pictureGuidURL,
                entryIconURL,
                tripURL
              )
            : ''}
        </div>
        {shouldRenderGroup
          ? this.renderGroupedElements(users, entries, tripURL)
          : ''}
        {shouldRenderNotificationRequest
          ? this.renderNotificationRequest(friendUserId, tripId, isAuthorInvite)
          : ''}
      </div>
    ) : null;
  }
}

NotificationItem.propTypes = {
  /*
        smallItem: boolean. Set true for notification items that should be rendered inside small containers.
     */
  smallItem: PropTypes.bool,
  /*
        notification: object. Object containing the notification attributes.
     */
  notification: PropTypes.object.isRequired,
};

NotificationItem.defaultProps = {
  smallItem: false,
};

const mapStateToProps = (state) => ({
  paths: state.configuration.paths,
  translatedMessages: state.configuration.translatedMessages,
});

export default connect(mapStateToProps, null)(NotificationItem);
