import connect from 'react-redux/es/connect/connect';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import ModalContainer, { MODAL_TYPES } from '../ModalContainer';
import {
  getImageURL,
  IMAGE_FORMAT_TYPE_MOBILE_FULL,
} from '../../../../utils/urlHelpers';

const StampsModal = ({
  translatedMessages,
  isVisible,
  onClose,
  paths,
  stamps,
}) => {
  const stampsNumber = stamps.length;

  const header = (
    <h2>{`${translatedMessages('main.countries')} ${stampsNumber}`}</h2>
  );

  const content = (
    <div className={styles.Stamps}>
      {stampsNumber &&
        stamps.map((stamp) => {
          const stampPictureUrl = getImageURL(
            paths.picture,
            stamp.pictureGuid,
            IMAGE_FORMAT_TYPE_MOBILE_FULL
          );
          const stampPictureStyle = {
            background: `url(${stampPictureUrl}) center center / cover`,
          };

          return (
            <div
              key={stamp.id}
              className={styles.Stamp}
              style={stampPictureStyle}
            />
          );
        })}
    </div>
  );

  return (
    <ModalContainer
      isVisible={isVisible}
      modalType={MODAL_TYPES.STAMPS}
      header={header}
      content={content}
      isLoading={false}
      onClose={onClose}
    />
  );
};

StampsModal.propTypes = {
  stamps: PropTypes.array,
};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StampsModal);
