import TrackSubscriber from './TrackSubscriber';

const FB_PIXEL_ID = '226843308516205';

/**
 * Facebook Pixel Track component
 */

export const FACEBOOK_TRACKER_NAME = 'FACEBOOK_PIXEL';

// Facebook Pixel standard events
export const FB_PIXEL_EVENT_NAME_ADDED_TO_CART = 'CompleteRegistration';
export const FB_PIXEL_EVENT_NAME_ADDED_PAYMENT_INFO = 'CompleteRegistration';
export const FB_PIXEL_EVENT_NAME_ADDED_TO_WISHLIST = 'AddToWishlist';
export const FB_PIXEL_EVENT_NAME_COMPLETED_REGISTRATION =
  'CompleteRegistration';
export const FB_PIXEL_EVENT_NAME_CONTACTED = 'Contact';
export const FB_PIXEL_EVENT_NAME_CUSTOMIZED_PRODUCT = 'CustomizeProduct';
export const FB_PIXEL_EVENT_NAME_DONATED = 'Donate';
export const FB_PIXEL_EVENT_NAME_FOUND_LOCATION = 'FindLocation';
export const FB_PIXEL_EVENT_NAME_INITIATED_CHECKOUT = 'InitiateCheckout';
export const FB_PIXEL_EVENT_NAME_LEAD = 'Lead';
export const FB_PIXEL_EVENT_NAME_PURCHASED = 'Purchase';
export const FB_PIXEL_EVENT_NAME_SCHEDULED = 'Schedule';
export const FB_PIXEL_EVENT_NAME_SEARCHED = 'Search';
export const FB_PIXEL_EVENT_NAME_STARTED_TRIAL = 'StartTrial';
export const FB_PIXEL_EVENT_NAME_SUBMITTED_APPLICATION = 'SubmitApplication';
export const FB_PIXEL_EVENT_NAME_SUBSCRIBED = 'Subscribe';
export const FB_PIXEL_EVENT_NAME_VIEWED_CONTENT = 'ViewContent';
export const FB_PIXEL_EVENT_NAME_VIEWED_PAGE = 'PageView';

class FacebookPixel extends TrackSubscriber {
  init(shouldTrack, shouldLog) {
    super.init(FACEBOOK_TRACKER_NAME, shouldTrack, shouldLog);
    window.fbq('init', FB_PIXEL_ID);
    if (shouldTrack) {
      // Facebook Pixel initialization
      window.fbq('track', 'PageView');
      this.isInitialized = true;
    }
  }

  /**
   * Functions that returns true if the event belongs to the list of Facebook's standard events
   */
  isStandardEvent(event) {
    const standardEvents = [
      FB_PIXEL_EVENT_NAME_ADDED_TO_CART,
      FB_PIXEL_EVENT_NAME_ADDED_PAYMENT_INFO,
      FB_PIXEL_EVENT_NAME_ADDED_TO_WISHLIST,
      FB_PIXEL_EVENT_NAME_COMPLETED_REGISTRATION,
      FB_PIXEL_EVENT_NAME_CONTACTED,
      FB_PIXEL_EVENT_NAME_CUSTOMIZED_PRODUCT,
      FB_PIXEL_EVENT_NAME_DONATED,
      FB_PIXEL_EVENT_NAME_FOUND_LOCATION,
      FB_PIXEL_EVENT_NAME_INITIATED_CHECKOUT,
      FB_PIXEL_EVENT_NAME_LEAD,
      FB_PIXEL_EVENT_NAME_PURCHASED,
      FB_PIXEL_EVENT_NAME_SCHEDULED,
      FB_PIXEL_EVENT_NAME_SEARCHED,
      FB_PIXEL_EVENT_NAME_STARTED_TRIAL,
      FB_PIXEL_EVENT_NAME_SUBMITTED_APPLICATION,
      FB_PIXEL_EVENT_NAME_SUBSCRIBED,
      FB_PIXEL_EVENT_NAME_VIEWED_CONTENT,
      FB_PIXEL_EVENT_NAME_VIEWED_PAGE,
    ];
    return standardEvents.includes(event);
  }

  triggerEventAction(eventCategory, eventAction, eventLabel, eventProperties) {
    if (this.shouldLog) {
      console.log(
        `Analytics - FacebookPixelAnalytics ### triggerEventAction: category=${eventCategory};action=${eventAction};label=${eventLabel};properties=${eventProperties}`
      );
    }
    if (this.isInitialized) {
      const eventName = eventLabel
        ? `${eventCategory}_${eventLabel}`
        : eventCategory;
      const isCustom = !this.isStandardEvent(eventLabel);
      if (isCustom) {
        window.fbq('trackCustom', eventName, eventProperties);
      } else {
        window.fbq('track', eventName);
      }
    }
  }

  logPageChange(pathname, search = '') {
    if (this.shouldLog) {
      console.log(
        `Analytics - FacebookPixelAnalytics ### logPageChange: ${pathname} (search: ${search})`
      );
    }
    if (this.isInitialized) {
      this.triggerEventAction('', 'PageView', null, null, null);
    }
  }
}

export default FacebookPixel;
