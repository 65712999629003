import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdditionalItemsAvatar.module.scss';
import Anchor from '../Misc/Anchor';

export const ADDITIONAL_ITEMS_TYPES = {
  DEFAULT: 'Default',
  ADDITIONAL_TRIPS: 'AdditionalTrips',
};

/**
 * Component that displays a encircled number. Used to denote the existence of additional items that were not displayed.
 * (e.g., additional trips collaborators).
 */
const AdditionalItemsAvatar = ({ href, count, type }) =>
  href ? (
    <Anchor href={href}>
      <div className={classNames(styles.Container, styles[type])}>
        <div className="tLabel">{`+${count}`}</div>
      </div>
    </Anchor>
  ) : (
    <div className={classNames(styles.Container, styles[type])}>
      <div className="tLabel">{`+${count}`}</div>
    </div>
  );

AdditionalItemsAvatar.propTypes = {
  /*
   * Number that will be displayed.
   */
  count: PropTypes.number.isRequired,
  /*
   * Link for the component.
   */
  href: PropTypes.string,
  /*
   * Type of additional item. Check ADDITIONAL_ITEMS_TYPES constant to see the the available ones.
   */
  type: PropTypes.oneOf(
    Object.entries(ADDITIONAL_ITEMS_TYPES).map((a) => a[1])
  ),
};

AdditionalItemsAvatar.defaultProps = {
  href: '',
  type: ADDITIONAL_ITEMS_TYPES.DEFAULT,
};

export default AdditionalItemsAvatar;
