import { getBrowserLanguage } from '../helpers/Locality';

export const IMAGE_FORMAT_TYPE_MOBILE_FULL = 'mobileFullFormat';
export const IMAGE_FORMAT_TYPE_MOBILE_FULL_SUFFIX = 'mob_full.jpg';

export const IMAGE_FORMAT_TYPE_MOBILE = 'mobile';
export const IMAGE_FORMAT_TYPE_MOBILE_SUFFIX = 'mob.jpg';

export const IMAGE_FORMAT_TYPE_MOBILE_RET_PROFILE = 'mobileRetProfile';
export const IMAGE_FORMAT_TYPE_MOBILE_RET_PROFILE_SUFFIX = 'ret_profile.jpg';

export const IMAGE_FORMAT_TYPE_MOBILE_PROFILE = 'mobileProfile';
export const IMAGE_FORMAT_TYPE_MOBILE_PROFILE_SUFFIX = 'mob_profile.jpg';

export const IMAGE_FORMAT_TYPE_MOBILE_RET = 'mobileRetFormat';
export const IMAGE_FORMAT_TYPE_MOBILE_RET_SUFFIX = 'ret.jpg';

export function getExploreURL() {
  return '/profile';
}

export function getTripUrl(baseURL, tripUrl) {
  // Deprecate
  // return baseURL.replace(':tripUrl', tripUrl);
  return `/j/${tripUrl}`;
}

export function getExploreCategoryURL(categoryKey) {
  return `/profile`;
}

export function getMomentURL(tripURL, moment) {
  return `${tripURL}#${moment}`;
}

export function getUserProfileURL(userUrl) {
  return `/profile/${userUrl}`;
}

export function getIconURL(iconsPath, iconId) {
  const matchedIcon = iconsPath.find((icon) => icon.id === iconId);
  return matchedIcon ? matchedIcon.path : '';
}

export function getCreatePhotoBookURL(articleId = null) {
  const lang = getBrowserLanguage();
  const articleParam = articleId ? `/${articleId}` : '';

  if (lang && lang.slice(0, 2) === 'de')
    return `/de/fotobuch/erstellen${articleParam}`;

  return `/photo-book/create${articleParam}`;
}

export function getShoppingBagURL() {
  const lang = getBrowserLanguage();

  if (lang && lang.slice(0, 2) === 'de') return '/de/einkaufstasche';

  return '/shopping-bag';
}

/**
 * Gets the asset's url prefixed with the app's public url.
 * @param {string} assetPath path of the asset
 * @return {string} url prefixed, if any provided, with the PUBLIC_URL environment variable
 */
export function getAssetURL(assetPath) {
  if (assetPath.charAt(0) === '/') {
    return `${process.env.PUBLIC_URL}/static${assetPath.substr(
      0,
      assetPath.length
    )}`;
  }
  return `${process.env.PUBLIC_URL}/static/${assetPath}`;
}

export function getMarkerImgURL(markerName) {
  const availableMarkersNames = [
    'entertainment',
    'relax',
    'architecture',
    'nature',
    'activity',
    'food',
    'coffee-drinks',
    'transport',
    'culture-art',
    'shopping',
    'nightlife',
    'accommodation',
    'worth-seeing',
  ];
  // It should be fetched from paths
  const baseUrl = getAssetURL('/icons/categories/:marker');
  if (availableMarkersNames.includes(markerName)) {
    return baseUrl.replace(':marker', `${markerName}.png`);
  }
  return baseUrl.replace(':marker', 'other.png');
}

/**
 * Checks if the provided url belongs to one of the web app's routes. Useful to differentiate whether to change the
 * browser's url or to use web app's routing.
 * e.g.,
 * url: /app/profile -> is not external
 * url: /jobs -> is external
 * url: /myprofile -> is not external
 *
 * @param {string} url - String containing the url that will be checked
 * @return {boolean} true if the url matches one the web app's routes.
 */
export function isURLExternal(url) {
  const webappPathPrefix = '/app';
  const regex = (route) =>
    new RegExp(`^(/=)?(${webappPathPrefix})?(/(${route})(/(.*)|$))`, 'i');
  const webappRoutes = [
    'profile',
    'myprofile',
    'mytrips',
    'tripsifollow',
    'explore',
    'notifications',
    'settings',
  ];
  return webappRoutes.find((route) => regex(route).test(url)) == null;
}

/**
 * Removes the base path from the url (including the /app)
 * @param {string} url - url which will have its base path removed (if matching the regex expression)
 * @return {string} url with base path removed.
 */
export function removeBasePath(url) {
  const regex = new RegExp(
    '(https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256})?(\\/app)*'
  );
  return url.replace(regex, '');
}

/**
 * Creates a url to signup page which contains the original url as redirection parameter
 * @param {string} redirectURL - String containing the url the user will be redirected to on successful authentication.
 * @return {string} Sign Up page url with redirection if redirect URL is provided.
 */
export function getSignUpURLWithRedirection(redirectURL) {
  const signUpURLwithRedirection = redirectURL.replace('?', '&');
  if (signUpURLwithRedirection) {
    return `/signup?redirect=${signUpURLwithRedirection}`;
  }
  return '/signup';
}

export function getImageURL(
  baseURL,
  imageID,
  formatType,
  shouldRemoveBasePath = true
) {
  let url = shouldRemoveBasePath
    ? removeBasePath(`${baseURL}/${imageID}`)
    : `${baseURL}/${imageID}`;
  let suffix = '';
  switch (formatType) {
    case IMAGE_FORMAT_TYPE_MOBILE_FULL:
      suffix = IMAGE_FORMAT_TYPE_MOBILE_FULL_SUFFIX;
      break;
    case IMAGE_FORMAT_TYPE_MOBILE_RET_PROFILE:
      suffix = IMAGE_FORMAT_TYPE_MOBILE_RET_PROFILE_SUFFIX;
      break;
    case IMAGE_FORMAT_TYPE_MOBILE:
      suffix = IMAGE_FORMAT_TYPE_MOBILE_SUFFIX;
      break;
    case IMAGE_FORMAT_TYPE_MOBILE_PROFILE:
      suffix = IMAGE_FORMAT_TYPE_MOBILE_PROFILE_SUFFIX;
      break;
    case IMAGE_FORMAT_TYPE_MOBILE_RET:
      suffix = IMAGE_FORMAT_TYPE_MOBILE_RET_SUFFIX;
      break;
    default:
      suffix = IMAGE_FORMAT_TYPE_MOBILE_FULL_SUFFIX;
  }
  url += `_${suffix}`;
  return url;
}
