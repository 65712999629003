/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icons from '../../../../helpers/IconsCommon';
import Styles from './styles.module.scss';
import { getCSSColor } from '../../../../utils/converters';

/**
 * Component
 */
const FlightComponent = ({
  fromCode,
  fromName,
  toCode,
  toName,
  color,
  className,
}) => (
  <div
    className={classnames(Styles.Flight, className)}
    style={{
      color: getCSSColor(color),
    }}
  >
    <div className={Styles.FlightRowNames}>
      <p className={Styles.FlightFromName}>{fromName}</p>
      <p className={Styles.FlightToName}>{toName}</p>
    </div>

    <div className={Styles.FlightRow}>
      <p className={Styles.FlightCode}>{fromCode}</p>
      <hr className={Styles.Line} />
      <span className={classnames('icon', Icons.Plane)} />
      <hr className={Styles.Line} />
      <p className={Styles.FlightCode}>{toCode}</p>
    </div>
  </div>
);

/**
 * PropTypes
 */
FlightComponent.propTypes = {
  fromCode: PropTypes.string.isRequired,
  fromName: PropTypes.string.isRequired,
  toCode: PropTypes.string.isRequired,
  toName: PropTypes.string.isRequired,
  color: PropTypes.number.isRequired,
  className: PropTypes.string,
};

FlightComponent.defaultProps = {
  className: null,
};

/**
 * Expose Component
 */
export default FlightComponent;
