import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AXIS_TYPES } from '../../../utils/imageHelper';
import styles from './RatioContainer.module.scss';

/**
 * Container that uses ratio values to define its flexGrow property.
 */
const RatioContainer = ({
  ratio,
  primaryAxis,
  children,
  padding,
  invertedPadding,
}) => {
  const containerStyles = {
    flexGrow: `${ratio}`,
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
    flexDirection: primaryAxis === AXIS_TYPES.HORIZONTAL ? 'row' : 'column',
    overflow: 'hidden',
  };

  return (
    <div
      style={containerStyles}
      className={classNames(
        styles.Container,
        {
          [styles.Horizontal]:
            padding && !invertedPadding
              ? primaryAxis !== AXIS_TYPES.HORIZONTAL
              : primaryAxis === AXIS_TYPES.HORIZONTAL,
        },
        {
          [styles.Vertical]:
            padding && !invertedPadding
              ? primaryAxis !== AXIS_TYPES.VERTICAL
              : primaryAxis === AXIS_TYPES.VERTICAL,
        }
      )}
    >
      {children}
    </div>
  );
};

RatioContainer.propTypes = {
  /**
   *  ratio: number. Ratio of the width (horizontal axis) or the height (vertical axis) of this component's parent
   *                 that the component will use.
   */
  ratio: PropTypes.number.isRequired,
  /**
   *  primaryAxis: number. Sets whether the container will set the height of the width according the ratio value.
   */
  primaryAxis: PropTypes.oneOf([AXIS_TYPES.VERTICAL, AXIS_TYPES.HORIZONTAL]),
  /**
   *  padding: bool. If true, adds padding to the container in the AXIS direction (if HORIZONTAL, adds left and right
   *                 padding; top and bottom otherwise.
   */
  padding: PropTypes.bool,
  /**
   *  invertedPadding: bool. If true, adds padding in the opposite axis (if axis is HORIZONTAL then adds padding in
   *                   the VERTICAL AXIS, otherwise in the HORIZONTAL AXIS).
   */
  invertedPadding: PropTypes.bool,
};

RatioContainer.defaultProps = {
  primaryAxis: AXIS_TYPES.HORIZONTAL,
  padding: true,
  invertedPadding: false,
};

export default RatioContainer;
