/**
 * Returns a map which groups elements based on the keyGetter function.
 * Example:
 * const pets = [
 * {type:"Dog", name:"Spot"},
 * {type:"Cat", name:"Tiger"},
 * {type:"Dog", name:"Rover"},
 * {type:"Cat", name:"Leo"}
 * ];
 *
 * const grouped = groupBy(pets, pet => pet.type);
 * console.log(grouped.get("Dog")); // -> [{type:"Dog", name:"Spot"}, {type:"Dog", name:"Rover"}]
 * console.log(grouped.get("Cat")); // -> [{type:"Cat", name:"Tiger"}, {type:"Cat", name:"Leo"}]
 * Taken from: https://stackoverflow.com/a/38327540/8546185
 * @param list
 * @param keyGetter function
 * @return {Map<any, any>}
 */
export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

/**
 *
 * @param {array} arr array that will be iterated
 * @param {function} func function to which the paired elements will be provided to
 * @param {array} [accumulator] array that will store the provided function's return value.
 * @return {*}
 */
export function pairwiseIterator(arr, func, accumulator = null) {
  for (let i = 0; i < arr.length; i += 2) {
    if (!accumulator) func(arr[i], arr[i + 1], i);
    else accumulator.push(func(arr[i], arr[i + 1]));
  }
  return accumulator;
}

/**
 * Flat array
 * [1, 2, [3, 4]] ==> [1, 2, 3, 4]
 */
export function flat(list) {
  return list.reduce((acc, val) => acc.concat(val), []);
}

/**
 * Calculates the greatest common divisor between a and b
 * @param {number} a
 * @param {number} b
 * @return {number} the greatest common divisor of a and b
 */
export function gcd(a, b) {
  return b === 0 ? a : gcd(b, a % b);
}

/**
 * Calculates the average between all provided argument values
 * @param {number} values numeric elements that will be used to calculate the average
 * @return {number} the average value calculated from all provided values.
 */
export function average(...values) {
  return values.reduce((a, b) => a + b, 0) / values.length;
}

/**
 * Calculates the sum of all the provided argument values
 * @param {number} values numeric elements that will be used to calculate the sum
 * @return {number} the sum value calculated from all provided values.
 */
export function sum(...values) {
  return values.reduce((a, b) => a + b, 0);
}
