import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import ModalContainer, { MODAL_TYPES } from '../ModalContainer';
import FollowerModalItem from './FollowerModalItem';
import styles from './FollowersModal.module.scss';
import TripHelper from '../../../../screens/TripTimelinePage/helpers/Trip';

class TripFollowersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tripFollowers: {},
      isRequesting: true,
    };
    this.getTripFollowers = this.getTripFollowers.bind(this);
  }

  componentDidMount() {
    this.getTripFollowers();
  }

  setRequestingState(isRequesting) {
    this.setState({
      isRequesting,
    });
  }

  async getTripFollowers() {
    const { trip } = this.props;
    const tripFollowers = await TripHelper.getTripFollowers(trip.id);
    this.setState({
      tripFollowers,
      isRequesting: false,
    });
  }

  render() {
    const { isRequesting, tripFollowers } = this.state;
    const { translatedMessages, trip, onClose } = this.props;
    const { userId } = trip;
    const followersNumber = tripFollowers.length;

    const onFollowAction = () => this.getTripFollowers();

    const header = (
      <h2>{`${translatedMessages('main.followers')} (${followersNumber})`}</h2>
    );

    const content = (
      <div className={styles.Followers}>
        {followersNumber
          ? tripFollowers.map((follower) => (
              <FollowerModalItem
                key={follower.userId}
                userId={userId}
                follower={follower}
                onFollowAction={onFollowAction}
              />
            ))
          : null}
      </div>
    );

    return (
      <ModalContainer
        modalType={MODAL_TYPES.FOLLOWERS}
        header={header}
        content={content}
        isLoading={isRequesting}
        onClose={onClose}
      />
    );
  }
}

TripFollowersModal.propTypes = {
  trip: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
  paths: state.configuration.paths,
});

export default connect(mapStateToProps, null)(TripFollowersModal);
