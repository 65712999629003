import PropTypes from 'prop-types';

export const MapEntry = PropTypes.shape({
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  iconImage: PropTypes.object.isRequired,
  coordinates: PropTypes.arrayOf(PropTypes.number),
  categoryId: PropTypes.number.isRequired,
  visibility: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  pictureURL: PropTypes.string,
  comment: PropTypes.string.isRequired,
});

export const ExtendedEntry = PropTypes.shape({
  id: PropTypes.number.isRequired,
  categoryId: PropTypes.number.isRequired,
  visibility: PropTypes.number.isRequired,
  comment: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  deleted: PropTypes.bool,
  date: PropTypes.number,
  timezone: PropTypes.string,
  categoryName: PropTypes.string,
  categoryIcon: PropTypes.string,
});

export const NavigationItem = PropTypes.shape({
  title: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
    }),
  ]),
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onlyTopNavigation: PropTypes.bool,
  onlyBottomNavigation: PropTypes.bool,
  hideOnTopNavigationMobileView: PropTypes.bool,
});

export const DropdownNavigationItem = PropTypes.shape({
  title: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});

export const Frame = {
  guid: PropTypes.string,
  elementType: PropTypes.number,
  elementId: PropTypes.number,
  isPicture: PropTypes.boolean,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  isHighResolution: PropTypes.bool,
  translation: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export const Page = {
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  pageType: PropTypes.number,
  frames: PropTypes.arrayOf(PropTypes.shape(Frame)),
};
