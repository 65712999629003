import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import styles from './WithImageUpload.module.scss';
import { toastFail } from '../Toasts';
import { generateGuid } from '../../../utils/guidGenerator';

/*
  withImageUpload is a HOC which features an image upload input for the wrapped component.
  @param {Component}             WrappedComponent React component that will be wrapped.
  @param {string}                inputName        Name and id of the input field (it must be unique to avoid name collision if other inputs are present).
  @param {overlayStyle}          object           Object containing the styles for the overlay of the wrapped component.
  @param {uploadRequest}         function         Function that will be called upon a file has been selected from the input.
 */
function WithImageUpload(
  WrappedComponent,
  inputName,
  overlayStyle = null,
  uploadRequest
) {
  const withImageUploadClass = class extends Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.uploadImage = this.uploadImage.bind(this);
      this.state = {
        isUploading: false,
        [inputName]: null,
      };
      this.inputOpenFileRef = React.createRef();
    }

    onChange(event) {
      this.setState(
        { [inputName]: event.target.files[0], isUploading: true },
        this.uploadImage
      );
    }

    showOpenFileDlg = () => {
      this.inputOpenFileRef.current.click();
    };

    uploadImage() {
      const { translatedMessages } = this.props;
      const onError = (error) => {
        try {
          const parsedError = JSON.parse(error);
          if (parsedError.status) {
            toastFail(parsedError.status);
          } else {
            toastFail(translatedMessages('main.error.oops'));
          }
        } catch {
          toastFail(translatedMessages('main.error.oops'));
        }
        this.setState({ isUploading: false });
      };
      const onSuccess = () => {
        this.setState({ isUploading: false });
      };
      const formData = new FormData();
      formData.append('guid', generateGuid());
      formData.append(inputName, this.state[inputName]);
      uploadRequest(formData, onSuccess, onError);
    }

    render() {
      const { isUploading, data } = this.state;
      const { hasDeviceTouchSupport, children } = this.props;
      const inputFile = (
        <input
          type="file"
          id={inputName}
          name={inputName}
          ref={this.inputOpenFileRef}
          style={{ display: 'none' }}
          accept="image/jpeg image/png"
          onChange={this.onChange.bind(this)}
        />
      );
      return (
        <WrappedComponent data={data} {...this.props}>
          <div
            className={[
              styles.Overlay,
              hasDeviceTouchSupport ? '' : styles.OverlayHover,
            ].join(' ')}
            style={overlayStyle}
            onClick={this.showOpenFileDlg}
          >
            <span className="icon icon-pen-streamline" />
          </div>

          {/* Icon for touch devices */}
          {hasDeviceTouchSupport && !isUploading && (
            <div
              className={styles.UploadIconTouch}
              onClick={this.showOpenFileDlg}
            >
              <span className={['icon icon-pen-streamline'].join(' ')} />
            </div>
          )}

          {isUploading && (
            <div
              className={[styles.Overlay, styles.VisibleOverlay].join(' ')}
              style={overlayStyle}
            >
              <span
                className={[
                  'icon icon-more makeItShake',
                  styles.LoadingIcon,
                ].join(' ')}
              />
            </div>
          )}

          {children}
          {inputFile}
        </WrappedComponent>
      );
    }
  };

  const mapStateToProps = (state) => ({
    hasDeviceTouchSupport: state.configuration.hasDeviceTouchSupport,
    translatedMessages: state.configuration.translatedMessages,
  });

  const mapDispatchToProps = (dispatch) => ({});

  return connect(mapStateToProps, mapDispatchToProps)(withImageUploadClass);
}

export default WithImageUpload;
