import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Footer.module.scss';
import Anchor from '../Misc/Anchor';

const Footer = ({ links, currentYear }) => (
  <div className={styles.Container}>
    <div className={classNames(styles.Navigation, 'tPlaceholder')}>
      {links
        .filter((link) => link.href != null)
        .map((link) => (
          <Anchor key={`${link.title} ${link.href}`} href={link.href}>
            {link.title}
          </Anchor>
        ))}
    </div>
    <div className={classNames(styles.Brand, 'tPlaceholder')}>
      {`\u00A9 ${currentYear} JOURNI`}
    </div>
  </div>
);

Footer.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string,
    })
  ).isRequired,
  currentYear: PropTypes.number.isRequired,
};

export default Footer;
