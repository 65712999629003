import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Radium from 'radium';
import classNames from 'classnames';
import styles from './styles.module.scss';
import FluidAnchor from '../Misc/FluidAnchor';
import withDropdown from '../Dropdowns';
import { shadeColor } from '../../../utils/converters';
import Anchor from '../Misc/Anchor';

export const BUTTON_TYPES = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
  CIRCLE: 'Circle',
  CHANGE_VISIBILITY: 'ChangeVisibility',
  FOLLOW: 'Follow',
  FOLLOW_CANCEL_REQUEST: 'FollowCancelRequest',
  UNFOLLOW: 'UnFollow',
  FOLLOWED: 'Followed',
  MODAL_FOLLOW: 'ModalFollow',
  MODAL_UNFOLLOW: 'ModalUnfollow',
  CLOSE: 'Close',
  FIXED_CLOSE: 'FixedClose',
  MORE: 'More',
  MAP_BUTTON: 'MapButton',
  SHARE_FACEBOOK: 'ShareFacebook',
  SHARE_EMAIL: 'ShareEmail',
  SHARE_TWITTER: 'ShareTwitter',
  SHARE_TRIP: 'ShareTrip',
};

export const BUTTON_SIZES = {
  FIT: 'Fit',
  SHORT: 'Short',
  BLOCK: 'Block',
  SMALL: 'Small',
  MEDIUM_SMALL: 'MediumSmall',
  MEDIUM: 'Medium',
  LARGE: 'Large',
  AUTO: 'Auto',
};

/*
    Button component.
    Only Primary, Secondary, Facebook and Google buttons types are exported.
 */

class Button extends Component {
  renderAnchor() {
    const { type, href } = this.props;
    if (type === 'submit' || type === 'reset' || href.trim() === '') {
      return null;
    }
    return <FluidAnchor href={href} />;
  }

  render() {
    const {
      mainColor,
      type,
      disabled,
      onClick,
      form,
      buttonType,
      buttonSize,
      display,
      className,
      children,
      href,
    } = this.props;
    const buttonStyle = {
      background: mainColor,
      ':hover': {
        background: shadeColor(0.05, mainColor),
      },
    };

    const buttonComponent = (
      <button
        type={type}
        disabled={disabled}
        onClick={onClick}
        form={form}
        className={classNames(
          'tButton', // Typography class
          styles.Button,
          styles[buttonType],
          styles[buttonSize],
          display,
          className,
          disabled ? styles.Disabled : ''
        )}
        style={buttonStyle}
      >
        {children}
      </button>
    );

    if (href) {
      return <Anchor href={href}>{buttonComponent}</Anchor>;
    }
    return buttonComponent;
  }
}

Button = Radium(Button); // Radium allows to style interactive states

Button.propTypes = {
  /*
        type: string, permitted values are submit, reset and button. DEFAULT: "button".
     */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  /*
        disabled: bool, does not allow the user to interact with the button. DEFAULT: false.
     */
  disabled: PropTypes.bool,
  /*
        buttonType: one of the TYPES values. It changes the style accordingly. DEFAULT: TYPES.PRIMARY.
     */
  buttonType: PropTypes.oneOf(Object.entries(BUTTON_TYPES).map((a) => a[1])),
  /*
        buttonSize: one of the SIZES values. Note that widths and heights are set in variables.scss file.
        DEFAULT: SIZES.MEDIUM.
     */
  buttonSize: PropTypes.oneOf(Object.entries(BUTTON_SIZES).map((a) => a[1])),
  /*
        display: string. See display property possible values. DEFAULT: "inline-block".
    */
  display: PropTypes.string,
  /*
        className: string. Any other additional css class that should be appended to the button's actual class(es).
        DEFAULT: "".
    */
  className: PropTypes.string,
  /*
        onClick: function. Function which will be called once the user press the button. DEFAULT: "inline-block".
    */
  onClick: PropTypes.func,
  /*
        href. URL that will the user will be redirected to after the button is pressed. DEFAULT: "".
    */
  href: PropTypes.string,
  /*
        form. If button is of type submit, then form is used to locate the form which will be submitted. DEFAULT: "".
    */
  form: PropTypes.string,
  /*
        mainColor: string. Predominant button color. Provide a valid CSS color. DEFAULT: "".
    */
  mainColor: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  disabled: false,
  buttonType: BUTTON_TYPES.PRIMARY,
  buttonSize: BUTTON_SIZES.MEDIUM,
  display: 'inline-block',
  className: '',
  onClick: () => true,
  href: '',
  form: null,
  mainColor: '',
};

export const Primary = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.PRIMARY} />
));

export const Secondary = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.SECONDARY} />
));

export const Facebook = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.FACEBOOK} />
));

export const Google = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.GOOGLE} />
));

export const Circle = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.CIRCLE} />
));

export const ChangeVisibility = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.CHANGE_VISIBILITY}>
    {props.isVisible ? (
      <span className="icon icon-eye-hide" />
    ) : (
      <span className="icon icon-eye-show" />
    )}
  </Button>
));

export const ShareFacebook = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.SHARE_FACEBOOK}>
    <span className="icon icon-facebook-icon" />
  </Button>
));

export const ShareTwitter = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.SHARE_TWITTER}>
    <span className="icon icon-twitter-icon" />
  </Button>
));

export const ShareEmail = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.SHARE_EMAIL}>
    <span className="icon icon-mail-streamline" />
  </Button>
));

export const ShareTrip = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.SHARE_TRIP}>
    <span className="icon icon-share-streamline" />
    {props.message}
  </Button>
));

export const ZoomIn = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.MAP_BUTTON}>
    <span className="icon icon-rounded-plus-1" />
  </Button>
));

export const ZoomOut = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.MAP_BUTTON}>
    <span className="icon icon-rounded-minus" />
  </Button>
));

export const FullScreen = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.MAP_BUTTON}>
    <span className="icon icon-scale-up" />
  </Button>
));

export const Follow = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.FOLLOW}>
    <span className="icon icon-rounded-plus" />
    <div className={styles.FollowMessage}>{props.message}</div>
  </Button>
));

export const FollowCancelRequest = withRouter((props) => (
  <Button
    {...props}
    buttonType={BUTTON_TYPES.FOLLOW_CANCEL_REQUEST}
    buttonSize={BUTTON_SIZES.MEDIUM_SMALL}
  >
    <span className="icon icon-rounded-minus" />
    <div className={styles.FollowMessage}>{props.message}</div>
  </Button>
));

export const UnFollow = withRouter((props) => (
  <Button
    {...props}
    buttonType={BUTTON_TYPES.UNFOLLOW}
    buttonSize={BUTTON_SIZES.MEDIUM_SMALL}
  >
    <span className="icon icon-rounded-minus" />
    <div className={styles.FollowMessage}>{props.message}</div>
  </Button>
));

export const ModalFollow = withRouter((props) => {
  let buttonIcon = <span className="icon icon-rounded-plus" />;
  let buttonType = BUTTON_TYPES.MODAL_FOLLOW;
  if (props.isFollowed) {
    buttonIcon = <span className="icon icon-rounded-minus" />;
    buttonType = BUTTON_TYPES.MODAL_UNFOLLOW;
  }
  return (
    <Button {...props} buttonType={buttonType}>
      {buttonIcon}
      <div className={styles.FollowMessage}>{props.message}</div>
    </Button>
  );
});

export const Close = withRouter((props) => (
  <Button
    {...props}
    buttonType={
      props.noStyleChange ? BUTTON_TYPES.FIXED_CLOSE : BUTTON_TYPES.CLOSE
    }
  >
    <span className="icon icon-rounded-close" />
  </Button>
));

export const MoreButton = withRouter((props) => (
  <Button {...props} buttonType={BUTTON_TYPES.MORE}>
    <span className="icon icon-more" />
  </Button>
));

export const moreDropdownButton = (elements) =>
  withDropdown(MoreButton, elements);
