import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import ModalContainer from '../ModalContainer';
import styles from './ChangePasswordModal.module.scss';
import * as journiAPI from '../../../../network/journiAPI';
import * as Button from '../../Buttons';
import Input from '../../Input/Input';
import ValidationBox, { BOX_TYPES } from '../../Boxes/ValidationBox';

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // holds the content of the old password input.
      oldpassword: '',
      // holds the content of the new password input.
      password: '',
      isFetching: false,
      callbackMessage: '',
      failedRequest: false,
      successRequest: false,
    };
    this.doChangePassword = this.doChangePassword.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  resetState() {
    this.setState({
      oldpassword: '',
      password: '',
      isFetching: false,
      callbackMessage: '',
      failedRequest: false,
      successRequest: false,
    });
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  doChangePassword() {
    const { oldpassword, password } = this.state;
    this.setState({
      isFetching: true,
      callbackMessage: '',
      failedRequest: false,
      successRequest: false,
    });

    journiAPI.changePassword(oldpassword, password).then(
      (response) => {
        this.setState({
          isFetching: false,
          callbackMessage: response.status,
          successRequest: true,
        });
      },
      (error) => {
        this.setState({
          isFetching: false,
          callbackMessage: error.status,
          failedRequest: true,
        });
      }
    );
  }

  renderCallbackMessage(callbackBoxType, callbackMessage) {
    if (callbackMessage) {
      return (
        <ValidationBox boxType={callbackBoxType}>
          {callbackMessage}
        </ValidationBox>
      );
    }

    return null;
  }

  render() {
    const { onClose, isVisible, translatedMessages } = this.props;
    const { failedRequest, successRequest, isFetching } = this.state;

    const callbackBoxType = failedRequest
      ? BOX_TYPES.FAILURE
      : BOX_TYPES.SUCCESS;
    const { callbackMessage } = this.state;

    const header = (
      <div className={styles.Header}>
        {translatedMessages('change_password.title')}
      </div>
    );

    const content = (
      <div className={styles.Content}>
        <Input
          title={translatedMessages('change_password.old')}
          type="password"
          name="oldpassword"
          placeholder={translatedMessages('change_password.old')}
          className="mb2"
          onChange={this.handleInputChange}
          required
        />
        <Input
          title={translatedMessages('change_password.new')}
          type="password"
          name="password"
          placeholder={translatedMessages('change_password.new')}
          className="mb2"
          onChange={this.handleInputChange}
          required
        />
        {this.renderCallbackMessage(callbackBoxType, callbackMessage)}
      </div>
    );

    const footer = (
      <Button.Primary
        disabled={isFetching || successRequest}
        onClick={this.doChangePassword}
      >
        {translatedMessages('explore.submit_button')}
      </Button.Primary>
    );

    return (
      <ModalContainer
        isVisible={isVisible}
        header={header}
        content={content}
        footer={footer}
        onClose={() => {
          this.resetState();
          onClose();
        }}
      />
    );
  }
}

ChangePasswordModal.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  translatedMessages: state.configuration.translatedMessages,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModal);
