import React from 'react';
import PropTypes from 'prop-types';
import { Feature, Layer } from 'react-mapbox-gl';
import IconHelper from '../../../../helpers/IconsEntries';

/**
 * Map marker layers, presentational component. It uses react-mapbox-gl library for rendering layers in the MapBox map
 * in which markers are represented as Features. Each layer represent a different category.
 */
const MapMarker = ({ entries, id, smallSize, activeEntry }) => {
  const iconName = IconHelper.getCategoryIcon(id).name;
  let categoryHasActiveEntry = false;
  const hasActiveEntry = activeEntry !== null || activeEntry !== 'undefined';
  if (activeEntry) {
    if (id === activeEntry.categoryId) {
      categoryHasActiveEntry = true;
    }
  }

  // If no coordinates are present then the MapBox marker container is not rendered.
  if (entries) {
    if (!hasActiveEntry) {
      return (
        <React.Fragment>
          <Layer
            type="symbol"
            id={`${id}`}
            layout={{
              'icon-image': iconName,
              'icon-size': smallSize ? 0.3 : 0.5,
              'icon-allow-overlap': true,
            }}
          >
            {entries.map((entry) => (
              <Feature
                key={entry.coordinates}
                coordinates={entry.coordinates}
                onClick={entry.onClick}
              />
            ))}
          </Layer>
        </React.Fragment>
      );
    }

    let activeLayer = null;
    if (categoryHasActiveEntry) {
      activeLayer = (
        <Layer
          type="symbol"
          id="activeLayer"
          layout={{
            'icon-image': iconName,
            'icon-size': 0.6,
            'icon-allow-overlap': true,
          }}
        >
          <Feature
            key={activeEntry.coordinates}
            coordinates={activeEntry.coordinates}
            onClick={activeEntry.onClick}
          />
        </Layer>
      );
    }

    return (
      <React.Fragment>
        {activeLayer}
        <Layer
          type="symbol"
          id={`${id}`}
          layout={{
            'icon-image': iconName,
            'icon-size': 0.5,
            'icon-allow-overlap': true,
          }}
          before={categoryHasActiveEntry ? 'activeLayer' : null}
        >
          {entries.map((entry) => (
            <Feature
              key={entry.coordinates}
              coordinates={entry.coordinates}
              onClick={entry.onClick}
            />
          ))}
        </Layer>
      </React.Fragment>
    );
  }

  return null;
};

MapMarker.propTypes = {
  id: PropTypes.number.isRequired,
  entries: PropTypes.array.isRequired,
  smallSize: PropTypes.bool,
};

MapMarker.defaultProps = {
  smallSize: false,
  onClick: () => true,
};

export default MapMarker;
