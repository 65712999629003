import { store } from '../index';
import * as JourniAPI from '../network/journiAPI';
import {
  handleNotificationsErrorAction,
  receiveNotificationsAction,
  requestNotificationsAction,
} from '../redux/account/notifications';

/**
 * Helper class that provides notifications methods.
 */
export default class NotificationsHelper {
  /**
   * Fetches notifications.
   * @return {function} Promise.
   */
  static async getNotifications() {
    let response = null;
    store.dispatch(requestNotificationsAction());

    try {
      response = await JourniAPI.getNotifications();
    } catch (error) {
      store.dispatch(handleNotificationsErrorAction(error));
      return;
    }

    store.dispatch(receiveNotificationsAction(response));
  }
}
