import React from 'react';
import PropTypes from 'prop-types';
import CoverProfilePicture, {
  CoverProfilePictureWithoutUpload,
} from './CoverProfilePicture';
import UserProfilePicture, {
  UserProfilePictureWithoutUpload,
} from './UserProfilePicture';

const UserProfileHeader = ({
  coverColor,
  coverPictureUrl,
  userPictureColor,
  userPictureUrl,
  isOwnProfile,
}) => {
  if (isOwnProfile) {
    return (
      <CoverProfilePicture
        coverColor={coverColor}
        coverPictureUrl={coverPictureUrl}
      >
        <UserProfilePicture
          userPictureColor={userPictureColor}
          userPictureUrl={userPictureUrl}
        />
      </CoverProfilePicture>
    );
  }
  return (
    <CoverProfilePictureWithoutUpload
      coverColor={coverColor}
      coverPictureUrl={coverPictureUrl}
    >
      <UserProfilePictureWithoutUpload
        userPictureColor={userPictureColor}
        userPictureUrl={userPictureUrl}
      />
    </CoverProfilePictureWithoutUpload>
  );
};

UserProfileHeader.propTypes = {
  coverColor: PropTypes.string.isRequired,
  coverPictureUrl: PropTypes.string.isRequired,
  userPictureColor: PropTypes.string.isRequired,
  userPictureUrl: PropTypes.string.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
};

UserProfileHeader.defaultValues = {
  coverColor: 'rgb(97, 98, 121)',
  userPictureColor: 'rgb(97, 98, 121)',
  isOwnProfile: false,
};

export default UserProfileHeader;
