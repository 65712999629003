import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import classnames from 'classnames';
import styles from './GroupedEntry.module.scss';
import {
  getImageURL,
  getMomentURL,
  IMAGE_FORMAT_TYPE_MOBILE,
} from '../../../../../utils/urlHelpers';
import Anchor from '../../../Misc/Anchor';

const GroupedEntry = ({ entry, isSmall, tripURL, paths }) => {
  const hasPicture = entry.pictureGuid != null;
  let backgroundStyle = '';
  let textIconComponent = null;

  if (hasPicture) {
    // Add background styles if it has picture
    const backgroundImageURL = getImageURL(
      paths.picture,
      entry.pictureGuid,
      IMAGE_FORMAT_TYPE_MOBILE
    );
    backgroundStyle = { backgroundImage: `url('${backgroundImageURL}')` };
  } else {
    // Add text icon if no picture if present
    textIconComponent = <span className="icon icon-text centered-icon " />;
  }

  const componentStyles = classnames(styles.GroupEntryImage, {
    [styles.GroupEntryImageSmall]: isSmall,
  });

  const groupedEntry = (
    <div
      className={componentStyles}
      style={hasPicture ? backgroundStyle : undefined}
    >
      {textIconComponent}
    </div>
  );

  if (entry.entryUrl) {
    return (
      <Anchor href={getMomentURL(tripURL, entry.entryUrl)} key={entry.id}>
        {groupedEntry}
      </Anchor>
    );
  }
  return <React.Fragment key={entry.id}>{groupedEntry}</React.Fragment>;
};

GroupedEntry.propTypes = {
  entry: PropTypes.shape({
    pictureGuid: PropTypes.string,
    entryUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  tripURL: PropTypes.string,
  isSmall: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  paths: state.configuration.paths,
  translatedMessages: state.configuration.translatedMessages,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupedEntry);
