/**
 * Abstract class for the Analytics Tracking services.
 */
class TrackSubscriber {
  constructor() {
    this.name = '';
    this.isInitialized = false;
    this.shouldTrack = false;
    this.shouldLog = false;
  }

  init(name, shouldTrack, shouldLog) {
    this.name = name;
    this.shouldTrack = shouldTrack;
    this.shouldLog = shouldLog;
  }

  logPageChange(pathname, search = '') {
    if (this.shouldLog) console.log('not implemented!');
  }

  triggerEventAction(
    eventCategory,
    eventAction,
    eventLabel,
    eventProperties,
    callback
  ) {
    if (this.shouldLog) console.log('not implemented!');
  }
}

export default TrackSubscriber;
