import * as journiAPI from '../../../network/journiAPI';

/**
 * Helper class that provides trips's useful methods.
 */
export default class TripHelper {
  static TRIP_STATUS = {
    NOT_FOLLOWING: { id: 0 },
    FOLLOWING: { id: 3 },
    REQUESTED: { id: 1 },
  };

  static TRIP_TYPES = {
    PUBLIC: { id: 1 },
    PRIVATE: { id: 0 },
    SECRET: { id: 2 },
    DRAFT: { id: 3 },
  };

  static ENTRY_VISIBILITY = {
    PRIVATE: 0,
    PUBLIC: 1,
  };

  /**
   * Checks whether the user has requested to follow the trip or not
   * @param {object} trip Trip element that will be checked
   * @return {boolean} true if the user has already requested to follow the trip, false otherwise
   */
  static hasRequestedToFollowTrip(trip) {
    const { status } = trip;
    return status === TripHelper.TRIP_STATUS.REQUESTED.id;
  }

  /**
   * Checks whether the trip is private or not
   * @param {object} trip Trip element that will be checked
   * @return {boolean} true if the trip is private, false otherwise
   */
  static isTripPrivate(trip) {
    const { visibility } = trip;
    return visibility === TripHelper.TRIP_TYPES.PRIVATE.id;
  }

  /**
   * Checks whether the trip is public or not
   * @param {object} trip Trip element that will be checked
   * @return {boolean} true if the trip is public, false otherwise
   */
  static isTripPublic(trip) {
    const { visibility } = trip;
    return visibility === TripHelper.TRIP_TYPES.PUBLIC.id;
  }

  /**
   * Checks whether the trip is secret or not
   * @param {object} trip Trip element that will be checked
   * @return {boolean} true if the trip is secret, false otherwise
   */
  static isTripSecret(trip) {
    const { visibility } = trip;
    return visibility === TripHelper.TRIP_TYPES.SECRET.id;
  }

  /**
   * Returns the follow status of the given trip for the requesting user.
   * @param {object} trip Trip element that will be checked
   * @return {object} matched follow status object
   */
  static getFollowStatus(trip) {
    const { status } = trip;
    const matchedStatus = Object.keys(this.TRIP_STATUS).find(
      (key) => this.TRIP_STATUS[key].id === status
    );
    return matchedStatus;
  }

  /**
   * Returns the followers of the trip matching the given trip id.
   * @param {number} tripId
   * @return {Promise<FollowersModal.state.followers|{}>}
   */
  static async getTripFollowers(tripId) {
    const response = await journiAPI.postListTripFollowers(tripId);
    return response.followers;
  }

  /**
   * Checks whether the trip entry is public or not.
   * @param {object} entry Object containing the entry's data.
   * @return {boolean} True if the entry is public, false otherwise.
   */
  static isTripEntryPublic(entry) {
    return entry.visibility === this.ENTRY_VISIBILITY.PUBLIC;
  }

  /**
   * Checks if the provided user id belongs to the trip's authors list.
   * @param {number} userId id of the user to check.
   * @param {object} trip Object containing the trip's data.
   * @return {boolean} True if the user is author, false otherwise.
   */
  static isUserAuthor(userId, trip) {
    const { authors } = trip;
    return authors.filter((author) => author.userId === userId).length > 0;
  }

  /**
   * Checks if the provided user id matches the trip's owner id.
   * @param {number} userId id of the user to check.
   * @param {object} trip Object containing the trip's data.
   * @return {boolean} True if the user is owner, false otherwise.
   */
  static isUserOwner(userId, trip) {
    return userId === trip.userId;
  }

  /**
   * Checks if the user is following the provided trip.
   * @param {object} trip Object containing the trip's data.
   * @return {boolean} True if the user is following the trip, false otherwise.
   */
  static isUserFollowing(trip) {
    return trip.status === TripHelper.TRIP_STATUS.FOLLOWING.id;
  }

  /**
   * Gets the trip's owner data from the provided trip.
   * @param {object} trip Object containing the trip's data.
   * @return {object} Object containing the trip's owner data.
   */
  static getOwner(trip) {
    const { userId } = trip;
    return trip.authors.find((author) => author.userId === userId);
  }

  /**
   * Gets the trip's authors.
   * @param {object} trip Object contaning the trip's data.
   * @param {boolean} includeOwner If true, then includes the trip's owner in the results.
   * @param {number} maxAuthors Number of additional authors that will be returned.
   * @return {array} Array of objects representing the list of trip's authors data.
   */
  static getAdditionalAuthors(trip, includeOwner = false, maxAuthors = 3) {
    const { userId } = trip;
    let additionalAuthors = trip.authors;
    if (!includeOwner) {
      additionalAuthors = additionalAuthors.filter(
        (author) => author.userId !== userId
      );
    }
    return additionalAuthors.slice(0, maxAuthors);
  }
}
